<template>
  <div>
    <div class="grid grid-cols-12 gap-6 mt-5">
      <div class="intro-y col-span-12 lg:col-span-12">
        <!-- BEGIN: Form Layout -->
        <div class="intro-y box dark:bg-dark-8 p-5">
          <loading-spinner
            type="half-circle"
            :duration="1000"
            :size="40"
            color="#4469A8"
            :loading="loading"
          />
          <h2 class="text-xl font-medium mr-auto border-b pb-5">
            {{ i18n('users.editUser.title') }}
          </h2>

          <div class="grid grid-cols-12 mt-5">
            <h4
              class="
                text-lg
                font-medium
                leading-none
                items-center
                mr-2
                lg:col-span-2
                col-span-12
              "
            >
              {{ i18n('users.editUser.name') }}
            </h4>
            <div class="lg:col-span-4 col-span-12 lg:mt-0 mt-2">
              <input
                id="crud-form-1"
                type="text"
                class="form-control w-full dark:placeholder-white"
                v-model="form.name"
              />
            </div>
          </div>
          <div class="grid grid-cols-12 mt-5">
            <h4
              class="
                text-lg
                font-medium
                leading-none
                items-center
                mr-2
                lg:col-span-2
                col-span-12
              "
            >
              {{ i18n('users.editUser.username') }}
            </h4>
            <div class="lg:col-span-4 col-span-12 lg:mt-0 mt-2">
              <input
                id="crud-form-1"
                type="text"
                class="form-control w-full dark:placeholder-white"
                v-model="form.username"
              />
            </div>
          </div>
          <div class="grid grid-cols-12 mt-5">
            <h4
              class="
                text-lg
                font-medium
                leading-none
                items-center
                mr-2
                lg:col-span-2
                col-span-12
              "
            >
              {{ i18n('users.editUser.roles.title') }}
            </h4>
            <div class="lg:col-span-4 col-span-12 lg:mt-0 mt-2">
              <TomSelect v-model="form.roles" class="w-full">
                <option
                  value="Admin"
                  class="text-black"
                  style="color: black !important"
                >
                  {{ i18n('users.editUser.roles.admin') }}
                </option>
                <option value="Data Center" class="text-black">
                  {{ i18n('users.editUser.roles.dataCenter') }}
                </option>
                <option value="Employee">
                  {{ i18n('users.editUser.roles.employee') }}
                </option>
              </TomSelect>
            </div>
          </div>
          <!-- <div class="grid grid-cols-12 mt-5" v-if="form.roles != 'Admin'">
            <h4 class="
                text-lg
                font-medium
                leading-none
                items-center
                mr-2
                lg:col-span-2
                col-span-12
              ">
              {{ i18n('users.editUser.partition') }}
            </h4>
            <div class="lg:col-span-4 col-span-12 lg:mt-0 mt-2">
              <TomSelect v-model="form.partition" class="w-full" @change="doFetchCompaniesOfPartition(form.partition)"
                :options="{maxItems: null}">
                <option v-for="(partition, index) in partitions" :key="index" :value="partition.id" class="text-black"
                  style="color: black !important">
                  {{ partition.name }}
                </option>
              </TomSelect>
            </div>
          </div>
          <div class="grid grid-cols-12 mt-5" v-if="form.roles != 'Admin'">
            <h4 class="
                text-lg
                font-medium
                leading-none
                items-center
                mr-2
                lg:col-span-2
                col-span-12
              ">
              {{ i18n('users.editUser.company') }}
            </h4>
            <div class="lg:col-span-4 col-span-12 lg:mt-0 mt-2">
              <TomSelect v-model="form.company" class="w-full" @change="doFetchDepartmentsOfCompanies(form.company)"
                :options="{maxItems: null}">
                <option v-for="(company, index) in companies" :key="index" :value="JSON.stringify(company)"
                  class="text-black" style="color: black !important">
                  {{ company.name }}
                </option>
              </TomSelect>
            </div>
          </div>
          <div class="grid grid-cols-12 mt-5" v-if="form.roles != 'Admin'">
            <h4 class="
                text-lg
                font-medium
                leading-none
                items-center
                mr-2
                lg:col-span-2
                col-span-12
              ">
              {{ i18n('users.editUser.department') }}
            </h4>
            <div class="lg:col-span-4 col-span-12 lg:mt-0 mt-2">
              <TomSelect v-model="form.department" class="w-full" :options="{maxItems: null}">
                <option v-for="(department, index) in departments" :key="index" :value="JSON.stringify(department)"
                  class="text-black" style="color: black !important">
                  {{ department.name }}
                </option>
              </TomSelect>
            </div>
          </div> -->
          <div class="grid grid-cols-12 mt-5" v-if="form.roles != 'Admin'">
            <h4
              class="text-lg font-medium leading-none items-center mr-2 lg:col-span-2 col-span-12"
            >
              {{ i18n('users.addUser.department') }}
            </h4>
            <div class="lg:col-span-4 col-span-12 lg:mt-0 mt-2">
              <treeselect
                ref="treeselect"
                placeholder="Select department"
                v-model="value"
                :multiple="true"
                :show-count="true"
                :load-options="loadOptions"
                :options="treeOptions"
                valueFormat="object"
              />
            </div>
          </div>
          <div class="grid grid-cols-12 mt-5">
            <h4
              class="
                text-lg
                font-medium
                leading-none
                items-center
                mr-2
                lg:col-span-2
                col-span-12
              "
            >
              {{ i18n('users.editUser.emailAddress') }}
            </h4>
            <div class="lg:col-span-4 col-span-12 lg:mt-0 mt-2">
              <input
                id="crud-form-1"
                type="text"
                class="form-control w-full dark:placeholder-white"
                v-model="form.email"
              />
            </div>
          </div>
          <div class="grid grid-cols-12 mt-5">
            <h4
              class="
                text-lg
                font-medium
                leading-none
                items-center
                mr-2
                lg:col-span-2
                col-span-12
              "
            >
              {{ i18n('users.editUser.phoneNumber') }}
            </h4>
            <div class="lg:col-span-4 col-span-12 lg:mt-0 mt-2">
              <vue-tel-input
                v-model="form.phoneNumber"
                mode="international"
              ></vue-tel-input>
              <!-- <vue-tel-input v-model="form.phoneNumber"></vue-tel-input> -->
            </div>
          </div>
          <!-- END -->
          <div class="mt-5 flex gap-4">
            <button
              type="button"
              class="btn text-white bg-theme-39 w-24 cursor-pointer"
              style="background-color: #d5aa0f"
              @click="doSubmit()"
            >
              {{ i18n('common.save') }}
            </button>
            <div class="btn btn-secondary" @click="goToChangePassword()">
              {{ i18n('profile.changePassword') }}
            </div>
            <button
              type="button"
              class="btn btn-secondary w-24 cursor-pointer"
              @click="doCancel()"
            >
              {{ i18n('common.cancel') }}
            </button>
          </div>
        </div>
        <!-- END: Form Layout -->
      </div>
    </div>
    <ErrorNotification :message="errorMessage" />
    <SuccessNotificationWithButtons
      :message="i18n('users.editUser.userEdited.message')"
    />
    <!-- :button="i18n('users.editUser.userEdited.button')"
      link="users" -->
  </div>
</template>

<script>
import { defineComponent, ref } from 'vue'
import ErrorNotification from '@/components/notifications/error-notification.vue'
import SuccessNotificationWithButtons from '@/components/notifications/success-notification-with-buttons.vue'
import Treeselect, { LOAD_CHILDREN_OPTIONS } from 'vue3-treeselect'
import 'vue3-treeselect/dist/vue3-treeselect.css'
import Toastify from 'toastify-js'
import { mapActions, mapGetters } from 'vuex'

export default defineComponent({
  components: {
    ErrorNotification,
    SuccessNotificationWithButtons,
    Treeselect
  },
  data() {
    return {
      value: [],
      treeOptions: []
    }
  },
  computed: {
    isRTL() {
      return false
    },
    ...mapGetters({
      record: 'usersStore/record',
      partitions: 'partitionStore/rows',
      companies: 'companyStore/rows',
      departments: 'departmentStore/rows',
      loading: 'usersStore/loading'
    }),
    userRoles() {
      var result = ''
      this.form.roles.forEach(element => {
        result += element + '-'
      })
      result = result.slice(0, -1)
      return result
    }
  },

  setup() {
    const form = ref({
      name: '',
      email: '',
      phoneNumber: '',
      username: '',
      partition: [],
      company: [],
      department: [],
      roles: ''
    })
    const phone = ref(null)
    const roles = ref(null)
    const errorMessage = ref('')
    const errorNotificationToggle = () => {
      Toastify({
        node: cash('#error-notification-content')
          .clone()
          .removeClass('hidden')[0],
        duration: 3000,
        newWindow: true,
        close: true,
        gravity: 'top',
        position: 'right',
        stopOnFocus: true
      }).showToast()
    }

    return {
      form,
      errorMessage,
      errorNotificationToggle,
      phone,
      roles
    }
  },
  methods: {
    i18n(key, args) {
      return this.$t(key, args)
    },
    async loadOptions({ action, parentNode, callback }) {
      if (action === LOAD_CHILDREN_OPTIONS) {
        if (parentNode.type === 'partition') {
          await this.doFetchCompanies(parentNode.id)
          parentNode.children =
            this.companies &&
            this.companies.map(comp => ({
              ...comp,
              label: comp.name,
              type: 'company',
              children: null
            }))
        }
        if (parentNode.type === 'company') {
          await this.doFetchDepartments({
            partitionId: parentNode.partition,
            companyId: parentNode.id
          })
          parentNode.children =
            this.departments &&
            this.departments.map(dep => ({
              ...dep,
              label: dep.name,
              type: 'department'
            }))
        }
      }
    },
    ...mapActions({
      doFetchUser: 'usersStore/doFetchUser',
      editUser: 'usersStore/editUser',
      doFetchPartitions: 'partitionStore/doFetch',
      doFetchCompaniesOfPartition: 'companyStore/doFetchMultiple',
      doFetchCompanies: 'companyStore/doFetch',
      doFetchDepartments: 'departmentStore/doFetch',
      doFetchDepartmentsOfCompanies: 'departmentStore/doFetchMultiple',
      doFetch: 'usersStore/doFetch'
    }),
    goToChangePassword() {
      this.$router.push({
        name: 'change-user-password',
        params: { locale: this.language, id: this.record.id }
      })
    },
    successNotificationWithButtonsToggle() {
      Toastify({
        node: cash('#success-notification-with-buttons')
          .clone()
          .removeClass('hidden')[0],
        duration: 3000,
        newWindow: true,
        close: true,
        gravity: 'top',
        position: 'right',
        stopOnFocus: true,
        onClick: () => {
          this.$router.push('/users')
          const element = document.getElementById(
            'success-notification-with-buttons'
          )
          element.remove()
        }
      }).showToast()
    },
    successNotificationToggle() {
      Toastify({
        node: cash('#success-notification-content')
          .clone()
          .removeClass('hidden')[0],
        duration: 3000,
        newWindow: true,
        close: true,
        gravity: 'top',
        position: 'right',
        stopOnFocus: true
      }).showToast()
    },
    phoneNumberInput(data) {
      this.phone = data
    },
    async doSubmit() {
      const getDepartments = data =>
        data.flatMap(node =>
          node.type === 'department'
            ? [node]
            : node.children
            ? getDepartments(node.children)
            : []
        )
      let departments = getDepartments(this.value)
      departments = departments.flat()
      if (this.isFormValid()) {
        await this.editUser({
          id: this.$route.params.id,
          form: { ...this.form, departments },
          roles: this.form.roles
        })
        this.successNotificationWithButtonsToggle()
        this.$router.push({ name: 'users' })
      } else {
        setTimeout(() => {
          this.errorNotificationToggle()
        }, 100)
      }
    },
    generatePassword() {
      var generator = require('generate-password')

      this.form.password = generator.generate({
        length: 10,
        numbers: true
      })
    },
    copyPassword() {
      navigator.clipboard.writeText(this.form.password)
      this.successNotificationToggle()
    },
    doCancel() {
      this.$router.back()
    },
    isFormValid() {
      for (var key in this.form) {
        var value = this.form[key]
        if (key != 'phoneNumber' && value.length == 0) {
          if (
            this.form.roles == 'Admin' &&
            key != 'company' &&
            key != 'department'
          ) {
            this.errorMessage = 'Please fill all the fields'
            return false
          }
        }
      }
      if (!this.validEmail(this.form.email)) {
        this.errorMessage = 'Please enter a valid e-mail'
        return false
      }
      return true
    },
    fetchDepartments(id) {
      this.doFetchDepartments(id)
    },
    validEmail(email) {
      return String(email)
        .toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        )
    }
  },
  async mounted() {
    this.doFetchUser(this.$route.params.id)
    await this.doFetchPartitions()
    this.treeOptions = this.partitions.map(part => ({
      id: part.id,
      label: part.name,
      children: null,
      type: 'partition'
    }))
  },
  watch: {
    record(newval) {
      this.form.name = newval.name
      this.form.email = newval.emailAddress
      this.form.phoneNumber = newval.phoneNumber || ''
      this.form.username = newval.username
      this.form.roles = newval.roles
    }
  }
})
</script>

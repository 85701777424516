<template>
  <!-- BEGIN: Modal Content -->
  <div
    id="create-folder-modal-preview"
    class="modal"
    tabindex="-1"
    aria-hidden="true"
    v-if="modalShow"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <!-- BEGIN: Modal Header -->
        <div class="modal-header">
          <h2 class="font-medium text-base">
            {{i18n('createFolder.title')}}
          </h2>
        </div>
        <!-- END: Modal Header -->
        <!-- BEGIN: Modal Body -->
        <div class="p-5">
          <label for="regular-form-1" class="form-label">{{i18n('createFolder.folderName')}}</label>
          <input
            id="regular-form-1"
            type="text"
            v-model="name"
            class="form-control"
          />
        </div>
        <!-- END: Modal Body -->
        <!-- BEGIN: Modal Footer -->
        <div class="modal-footer">
          <button
            type="button"
            id="submit-btn"
            class="btn text-white bg-theme-39 w-20"
            style="background-color:#d5aa0f"
            :disabled="uploadLoading"
            @click="doSave()"
          >
            {{i18n('common.create')}}
            <LoadingIcon icon="three-dots" class="" v-if="uploadLoading" />
          </button>
          <button
            type="button"
            data-dismiss="modal"
            class="btn btn-outline-secondary w-20 ml-1 mr-1"
          >
            {{i18n('common.cancel')}}
          </button>
        </div>
        <!-- END: Modal Footer -->
      </div>
    </div>
    <div id="error-notification-folder" class="toastify-content hidden flex">
      <AlertCircleIcon class="text-theme-38" />
      <div class="ml-4 mr-4">
        <div class="font-medium">{{i18n('createFolder.errors.title')}}</div>
        <div class="text-gray-600 mt-1">
          {{i18n('createFolder.errors.pleaseEnterName')}}
        </div>
      </div>
    </div>
  </div>
  <!-- END: Modal Content -->
</template>

<script>
import { defineComponent, ref } from 'vue'
import Toastify from 'toastify-js'
import { mapGetters, mapActions } from 'vuex'
// import axios from 'axios'
export default defineComponent({
  props: ['destination', 'showCreateFolderModal', 'serverPath'],
  data() {
    return {
      modalShow: this.showCreateFolderModal
    }
  },
  setup(props) {
    const name = ref('')
    const hideModal = () => {
      cash('#create-folder-modal-preview').modal('hide')
    }
    const errorNotificationToggle = () => {
      Toastify({
        node: cash('#error-notification-folder')
          .clone()
          .removeClass('hidden')[0],
        duration: 3000,
        newWindow: true,
        close: true,
        gravity: 'top',
        position: 'right',
        stopOnFocus: true
      }).showToast()
    }
    return {
      name,
      hideModal,
      errorNotificationToggle
    }
  },
  computed: {
    ...mapGetters({
      uploadLoading: 'dataCenterStore/uploadLoading'
    })
  },
  methods: {
    ...mapActions({
      createFolder: 'dataCenterStore/createFolder',
      doCreateFolder: 'dataCenterStore/doCreateFolder'
    }),
    i18n(key, args) {
      return this.$t(key, args)
    },
    async doSave() {
      if (this.name != '') {
        await this.doCreateFolder({
          folderName: this.name,
          path: this.destination,
          location: 'DC'
        })
        this.hideModal()
        this.$emit('close')
      } else {
        this.errorNotificationToggle()
      }
    },
    doSave2() {
      if (this.name != '') {
        this.createFolder({
          destination: this.destination + '/' + this.name,
          path: this.destination,
          serverPath: this.serverPath
        })
        this.hideModal()
        this.$emit('close')
      } else {
        this.errorNotificationToggle()
      }
    }
  }
})
</script>
<style></style>

<template>
  <div id="barcode" class="tab-pane active" role="tabpanel" aria-labelledby="barcode-tab">
    <div class="intro-y col-span-12 lg:col-span-12">
      <div class="intro-y box p-5 flex">
        <div class="justify-center">
          {{ i18n('settings.barcodeTitle') }}
        </div>
      </div>
    </div>

    <div class="grid grid-cols-12 gap-6 mt-5">
      <div class="intro-y col-span-12 lg:col-span-12">
        <!-- BEGIN: Form Layout -->
        <div class="intro-y box p-5 flex" :class="index > 0 ? 'mt-5' : ''" v-for="(scale, index) in scales"
          :key="index">
          <div class="flex items-center grid grid-cols-12">
            <div class="ml-5 mr-5 col-span-6">
              <div class="font-medium">{{ i18n('settings.scale') + ' ' + (index + 1) }}</div>
            </div>
            <div class="form-check col-span-3">
              <label class="switch">
                <input type="checkbox" :checked="scale.checked" v-model="scale.checked"
                  @click="switchChange(scale, index)" />
                <span class="slider round"></span>
              </label>
            </div>
            <div class="ml-5 mr-5 col-span-3">
              <div class="mb-2">
                <strong>{{ i18n('common.height') }}: </strong>
                <span style="color: green;">{{ scale.height }}px</span>
              </div>
              <div>
                <strong>{{ i18n('common.width') }} : </strong>
                <span style="color: green;">{{ scale.width }}px</span>
              </div>
            </div>
          </div>
        </div>
        <!-- END: Form Layout -->
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { defineComponent } from 'vue'
import Toastify from 'toastify-js'

export default defineComponent({
  setup() {
    const errorNotificationToggle = () => {
      Toastify({
        node: cash('#error-notification-content').clone().removeClass('hidden')[0],
        duration: 3000,
        newWindow: true,
        close: true,
        gravity: 'top',
        position: 'right',
        stopOnFocus: true
      }).showToast()
    }
    return {
      errorNotificationToggle
    }
  },
  data() {
    return {
      scales: []
    }
  },
  computed: {
    ...mapGetters({
      scalesData: 'settings/scales',
      loading: 'settings/loading',
      currentUser: 'authStore/currentUser'
    }),
    isRTL() {
      return this.$i18n.locale == 'ar'
    },
    language() {
      return this.$i18n.locale
    }
  },
  async created() {
    await this.doFindBarcodeScales()
    this.scales = this.scalesData
  },
  methods: {
    ...mapActions({
      doFindBarcodeScales: 'settings/doFindBarcodeScales',
      doUpdateScaling: 'settings/doUpdateScaling'
    }),
    i18n(key, args) {
      return this.$t(key, args)
    },
    async switchChange(scale, index) {
      for (let i = 0; i < this.scales.length; i++) {
        this.scales[i].checked = i === index
      }
      this.doUpdateScaling({
        name: scale.name,
        height: scale.height,
        width: scale.width,
        index: scale.index
      })
    }
  }
})
</script>
<style scoped>
.table-report:not(.table-report--bordered):not(.table-report--tabulator) tr {
  border-radius: 0px !important;
  box-shadow: 15px 0px 15px #0000000b;
}

.table-report:not(.table-report--bordered):not(.table-report--tabulator) td {
  border-radius: 0px !important;
  box-shadow: unset !important;
}

.pagination-ar {
  margin-right: unset !important;
  margin-left: auto !important;
}
</style>

<style>
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 25px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: '';
  height: 20px;
  width: 20px;
  left: 3px;
  bottom: 3px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked+.slider {
  background-color: #FFBC80;
}

input:focus+.slider {
  box-shadow: 0 0 1px #FFBC80;
}

input:checked+.slider:before {
  -webkit-transform: translateX(30px);
  -ms-transform: translateX(30px);
  transform: translateX(30px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
</style>

<template>
  <div>
    <!-- <DarkModeSwitcher /> -->
    <div class="" style="z-index:2 !important;"></div>
    <div class="">
      <div class="container block xl:grid grid-cols-2">
        <!-- BEGIN: Login Info -->
        <div class="hidden xl:flex flex-col min-h-screen">
          <div class="my-auto">
            <div class="flex  justify-center">
              <img
                alt="Icewall Tailwind HTML Admin Template"
                class="-intro-x w-1/2 -mt-16 "
                style="width:400px;"
                src="/logo.png"
              />
            </div>
            <h1 class="text-4xl font-black text-white flex justify-center">
              {{ i18n('title') + ' ' + i18n('subtitle') }}
            </h1>
            <div
              class="runprof-logo"
              :style="isRTL ? 'right: 22.5%;' : 'left: 22.5%;'"
            >
              <a
                href="https://runprof.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src="https://firebasestorage.googleapis.com/v0/b/alghoneimy-175eb.appspot.com/o/public%2Frunprof.svg?alt=media&token=0a25f13c-e1ee-432a-8bf3-5cc58056b9dc"
                  class="block mx-auto"
                  alt=""
                />
              </a>
            </div>
          </div>
        </div>
        <!-- END: Login Info -->
        <!-- BEGIN: Login Form -->
        <div
          class="h-screen xl:h-auto flex sm:py-5 xl:py-0  xl:my-0 bg-white"
        >
          <div
            class="my-auto mx-auto bg-white dark:bg-dark-1 xl:bg-transparent px-5 sm:px-8 py-8 rounded-md shadow-md xl:shadow-none w-full sm:w-3/4 lg:w-2/4"
          >
            <div class=" block -mt-12  justify-center h-auto">
              <div
                class="intro-x text-gray-700 dark:text-gray-600 text-center flex"
              >
                <I18nFlags class="xl:block mb-10 block mx-auto" />
              </div>
              <div class="my-auto xl:hidden">
                <img
                  alt="Icewall Tailwind HTML Admin Template"
                  class="-intro-x w-1/2  block mx-auto"
                  src="/logo-black.png"
                  style="width: 200px;  "
                />
              </div>
              <h1 class="font-black text-center text-2xl mb-4 xl:hidden">
                {{ i18n('title') + ' ' + i18n('subtitle') }}
              </h1>
            </div>
            <div
              class="intro-x text-gray-700 dark:text-gray-600 text-center  xl:flex"
            ></div>
            <h2 class="intro-x font-bold text-2xl xl:text-3xl text-center xl:">
              {{ i18n('login.signIn') }}
            </h2>

            <div class="intro-x mt-8">
              <input
                type="email"
                class="intro-x login__input form-control py-3 px-4 border-gray-300 block !outline-none"
                :placeholder="i18n('login.emailAddress')"
                v-model="form.username"
              />
              <div class="flex items-center" style="position: relative;">
                <input
                  :type="visible ? 'text' : 'password'"
                  class="intro-x login__input form-control py-3 px-4 border-gray-300 mt-4"
                  :placeholder="i18n('login.password')"
                  v-model="form.password"
                  @keyup.enter="login"
                />
                <button
                  @click="visible = !visible"
                  class="far"
                  :style="isRTL ? 'left: 0px;' : 'right: 0px;'"
                >
                  <span class="material-symbols-outlined" v-if="!visible"
                    >visibility_off</span
                  >
                  <span class="material-symbols-outlined" v-else
                    >visibility</span
                  >
                </button>
              </div>
            </div>
            <div
              class="intro-x flex text-gray-700 dark:text-gray-600 text-xs sm:text-sm mt-4"
            >
              <div
                class="flex items-center"
                :class="isRTL ? 'ml-auto' : 'mr-auto'"
              >
                <input
                  id="remember-me"
                  type="checkbox"
                  class="form-check-input  mr-2 ml-2 focus:bg-gray checked"
                  v-model="form.checked"
                />
                <label
                  class="cursor-pointer select-none xl:"
                  for="remember-me"
                  >{{ i18n('login.rememberMe') }}</label
                >
              </div>
            </div>
            <div
              v-if="error"
              class="alert alert-danger alert-dismissible show flex items-center mb-2 mt-2"
              role="alert"
              style="z-index:2 !important"
            >
              <AlertOctagonIcon class="w-6 h-6 mr-2 ml-2" />
              {{ i18n(errorData) }}
            </div>
            <div
              class="intro-x mt-5 xl:mt-8 text-center xl:text-left grid grid-cols-12"
              :class="isRTL ? 'xl:text-right' : 'xl:text-left'"
            >
              <button
                class="btn text-white bg-theme-39 py-3 px-4 mt-3 xl:mt-0 align-top lg:col-span-6 col-span-12"
                style="background-color: #d5aa0f;"
                :class="isRTL ? 'xl:ml-3' : 'xl:mr-3'"
                @click="login()"
                :disabled="loading"
              >
                <span v-if="!loading">
                  {{ i18n('login.login') }}
                </span>
                <LoadingIcon v-else icon="three-dots" class="" />
              </button>

              <button
                v-if="!ownerExist"
                class="btn btn-outline-secondary py-3 px-4 mt-3 xl:mt-0 align-top xl: lg:col-span-6 col-span-12"
                @click="goToSignup()"
              >
                {{ i18n('common.signup') }}
              </button>
            </div>
            <div></div>
          </div>
        </div>
        <div class="xl:hidden p-4">
          <a
            href="https://runprof.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src="https://firebasestorage.googleapis.com/v0/b/alghoneimy-175eb.appspot.com/o/public%2Frunprof.svg?alt=media&token=0a25f13c-e1ee-432a-8bf3-5cc58056b9dc"
              class="block mx-auto"
              alt=""
            />
          </a>
        </div>
        <!-- END: Login Form -->
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref, computed } from 'vue'
// import DarkModeSwitcher from '@/components/dark-mode-switcher/Main.vue'
import { useStore } from '@/store'
import I18nFlags from '../../global-components/i18n/i18n-flags.vue'
import { mapActions, mapGetters } from 'vuex'
// import axios from 'axios'

export default defineComponent({
  // created() {
  //   document.title = this.i18n('routes.app') + ' | ' + this.i18n('routes.login')
  // },
  // watch: {
  //   isRTL() {
  //     document.title =
  //       this.i18n('routes.app') + ' | ' + this.i18n('routes.login')
  //   }
  // },
  computed: {
    isRTL() {
      // return false
      return this.$i18n.locale == 'ar'
    },
    ...mapGetters({
      user: 'authStore/user',
      isUserAuth: 'authStore/isUserAuth',
      authError: 'authStore/getError',
      loading: 'authStore/loading',
      ownerExist: 'authStore/ownerExist'
    })
  },
  components: {
    I18nFlags
    // DarkModeSwitcher
  },
  // async created() {
  //   await this.verifyOwner()
  // },
  mounted() {
    if (this.isRTL) {
      cash('body')
        .removeClass('main')
        .removeClass('error-page')
      // .addClass('login-rtl')
    } else {
      cash('body')
        .removeClass('main')
        .removeClass('error-page')
      // .addClass('login')
    }
    if (localStorage.checked && localStorage.checked != false) {
      this.form.checked = true
      this.form.username = localStorage.username
      this.form.password = localStorage.password
    } else {
      this.form.checked = false
      this.form.username = ''
      this.form.password = ''
    }
  },
  watch: {
    isRTL() {
      if (this.isRTL) {
        cash('body')
          .removeClass('main')
          .removeClass('error-page')
        // .addClass('login-rtl')
      } else {
        cash('body')
          .removeClass('main')
          .removeClass('error-page')
        // .addClass('login')
      }
    }
  },
  setup() {
    const store = useStore()
    const darkMode = computed(() => store.state.main.darkMode)
    const error = ref(false)
    const errorData = ref('')
    const form = ref({
      username: ref(''),
      password: ref(''),
      checked: ref(false)
    })
    const visible = ref(false)
    return {
      error,
      errorData,
      form,
      darkMode,
      visible
    }
  },
  methods: {
    ...mapActions({
      // verifyOwner: 'authStore/verifyOwner',
      signInAction: 'authStore/signInAction'
    }),
    validateEmail(email) {
      return email.match(
        // eslint-disable-next-line
        /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      )
    },
    // showPassword() {
    //   this.
    // },
    goToSignup() {
      this.$router.push('/auth/register')
    },
    goToForgotPassword() {
      this.$router.push('/auth/forgot-password')
    },
    changeLoginClass() {
      if (this.isRTL) {
        cash('body')
          .removeClass('login')
          .addClass('login-rtl')
      } else {
        cash('body')
          .removeClass('login-rtl')
          .addClass('login')
      }
    },
    i18n(key, args) {
      return this.$t(key, args)
    },
    async login() {
      if (this.form.username.length == 0 || this.form.password.length == 0) {
        this.error = true
        this.errorData = 'login.errors.emptyField'
      } else {
        await this.signInAction({
          email: this.form.username,
          password: this.form.password
        })
        if (this.authError) {
          this.error = true
          if (this.authError.includes('invalid-email')) {
            this.errorData = 'login.errors.invalidEmail'
          } else if (this.authError.includes('user-not-found')) {
            this.errorData = 'login.errors.userNotFound'
          } else if (this.authError.includes('wrong-password')) {
            this.errorData = 'login.errors.wrongPassword'
          } else if (this.authError.includes('user-disabled')) {
            this.errorData = 'login.errors.userDisabled'
          } else {
            this.errorData = this.authError
          }
        }
      }
      if (
        this.form.checked &&
        this.form.username != '' &&
        this.form.password != ''
      ) {
        localStorage.username = this.form.username
        localStorage.password = this.form.password
        localStorage.checked = this.form.checked
      } else {
        localStorage.username = ''
        localStorage.checked = ''
        localStorage.password = ''
      }
    },
    setCurrentUser(data) {
      const CryptoJS = require('crypto-js')

      // Encrypt
      var ciphertext = CryptoJS.AES.encrypt(JSON.stringify(data), 'secret-c-u')
      localStorage.setItem('currentUser', ciphertext)
    }
  }
})
</script>

<style lang="scss">
.container {
  @media (min-width: 1280px) {
    max-width: 100vw !important;
  }
}
.dark .btn-outline-secondary:hover {
  --tw-bg-opacity: 0.2;
  background-color: rgba(226, 232, 240, var(--tw-bg-opacity));
}

@keyframes drawDownLine {
  0% {
    transform: scaleY(0);
  }
  100% {
    transform: scaleyY(1);
  }
}

.logo-web {
  margin-left: 50px;
}
[dir='rtl'] .logo-web {
  margin-right: 50px;
}

.loader {
  width: 300px;
  height: 300px;
  // line-height: 150px;

  margin-left: 100px !important;
  margin-bottom: 20px !important;
  margin-right: 100px !important;
  position: relative;
  box-sizing: border-box;
  text-align: center;
  z-index: 0;
  text-transform: uppercase;
}

.loader:before,
.loader:after {
  opacity: 0;
  box-sizing: border-box;
  content: '\0020';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border: 5px solid #bd212e;
  box-shadow: 0 0 50px #bd212e, inset 0 0 50px #bd212e;
}

.loader:after {
  z-index: 1;
  -webkit-animation: gogoloader 2s infinite 1s;
}

.far {
  position: absolute !important;
  display: flex !important;
  align-items: center !important;
  text-align: center !important;
  z-index: 100 !important;
  margin-top: 16px !important;
  padding: 10px !important;
}
.far:focus {
  box-shadow: none !important;
}

.loader:before {
  z-index: 2;
  -webkit-animation: gogoloader 2s infinite;
}

@-webkit-keyframes gogoloader {
  0% {
    -webkit-transform: scale(0);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(1);
    opacity: 0;
  }
}

.runprof-logo {
  bottom: 0;
  position: absolute;
  margin-bottom: 20px;
}
</style>

<template>
  <div
    id="disable-modal-preview"
    class="modal"
    tabindex="-1"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-body p-0">
          <div class="p-5 text-center">
            <CheckCircleIcon class="w-16 h-16 text-theme-37 mx-auto mt-3" />
            <div class="text-3xl mt-5">Success</div>
            <div class="text-gray-600 mt-2 dark:text-gray-400">
              {{ item }} is disabled successfully
            </div>
          </div>
          <div class="px-5 pb-8 text-center">
            <button
              type="button"
              data-dismiss="modal"
              class="btn text-white bg-theme-39 w-24"
              style="background-color:#d5aa0f"
            >
              Ok
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    item: {
      type: String
    }
  },
  computed: {
    isRTL() {
      return false
      // return this.$i18n.locale == 'ar'
    }
  },
  methods: {
    // i18n(key, args) {
    //   return this.$t(key, args)
    // }
  }
}
</script>

<style></style>

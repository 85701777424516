<template>
  <div>
    <div class="grid grid-cols-12 gap-6 mt-5">
      <div class="intro-y col-span-12 lg:col-span-12">
        <!-- BEGIN: Form Layout -->
        <div class="intro-y box dark:bg-dark-8 p-5">
          <loading-spinner type="half-circle" :duration="1000" :size="40" color="#4469A8" :loading="loading" />
          <h2 class="text-xl font-medium mr-auto border-b pb-5">
            {{ i18n('companies.addOrEdit.edit') }}
          </h2>

          <div class="grid grid-cols-12 mt-5">
            <h4 class="text-lg font-medium leading-none items-center mr-2 lg:col-span-2 col-span-12">
              {{ i18n('companies.addOrEdit.name') }}
            </h4>
            <div class="lg:col-span-4 col-span-12 lg:mt-0 mt-2">
              <input id="crud-form-1" type="text" class="form-control w-full dark:placeholder-white"
                v-model="form.name" />
            </div>
          </div>
          <!-- <div class="grid grid-cols-12 mt-5">
            <h4 class="text-lg font-medium leading-none items-center mr-2 lg:col-span-2 col-span-12">
              {{ i18n('companies.addOrEdit.address') }}
            </h4>
            <div class="lg:col-span-4 col-span-12 lg:mt-0 mt-2">
              <input id="crud-form-1" type="text" class="form-control w-full dark:placeholder-white"
                v-model="form.address" />
            </div>
          </div> -->
          <div class="grid grid-cols-12 mt-5">
            <h4 class="text-lg font-medium leading-none items-center mr-2 lg:col-span-2 col-span-12">
              {{ i18n('companies.addOrEdit.emailAddress') }}
            </h4>
            <div class="lg:col-span-4 col-span-12 lg:mt-0 mt-2">
              <input id="crud-form-1" type="text" class="form-control w-full dark:placeholder-white"
                v-model="form.emailAddress" />
            </div>
          </div>
          <div class="grid grid-cols-12 mt-5">
            <h4 class="text-lg font-medium leading-none items-center mr-2 lg:col-span-2 col-span-12">
              {{ i18n('companies.addOrEdit.phoneNumber') }}
            </h4>
            <div class="lg:col-span-4 col-span-12 lg:mt-0 mt-2">
              <vue-tel-input v-model="this.form.phoneNumber" mode="international"></vue-tel-input>
              <!-- <vue-tel-input v-model="form.phoneNumber"></vue-tel-input> -->
            </div>
          </div>
          <!-- END -->
          <div class="mt-5">
            <button type="button" class="btn text-white bg-theme-39 w-24 cursor-pointer"
              style="background-color: #d5aa0f;" @click="doSubmit()">
              {{ i18n('common.save') }}
            </button>
            <button type="button" class="btn btn-secondary w-24 ml-3 mr-3 cursor-pointer" @click="doCancel()">
              {{ i18n('common.cancel') }}
            </button>
          </div>
        </div>
        <!-- END: Form Layout -->
      </div>
    </div>
    <ErrorNotification :message="errorMessage" />
    <SuccessNotification :message="i18n('companies.addOrEdit.companyEdited')" />
    <!-- <SuccessNotificationWithButtons
      :message="i18n('companies.addOrEdit.companyEdited')"
      :button="i18n('companies.addOrEdit.goBack')"
      link="company"
    /> -->
  </div>
</template>

<script>
import { defineComponent, ref } from 'vue'
import ErrorNotification from '@/components/notifications/error-notification.vue'
// import SuccessNotificationWithButtons from '@/components/notifications/success-notification-with-buttons.vue'
import SuccessNotification from '@/components/notifications/success-notification.vue'

import Toastify from 'toastify-js'
import { mapActions, mapGetters } from 'vuex'
export default defineComponent({
  components: {
    ErrorNotification,
    // SuccessNotificationWithButtons,
    SuccessNotification
  },
  computed: {
    isRTL() {
      return false
      //   return this.$i18n.locale == 'ar'
    },
    ...mapGetters({
      record: 'companyStore/record',
      currentUser: 'authStore/currentUser',
      loading: 'companyStore/loading'
    })
  },

  setup() {
    const form = ref({
      name: '',
      emailAddress: '',
      phoneNumber: ''
      // address: ''
    })
    const phone = ref(null)
    const errorMessage = ref('')
    const errorNotificationToggle = () => {
      Toastify({
        node: cash('#error-notification-content')
          .clone()
          .removeClass('hidden')[0],
        duration: 3000,
        newWindow: true,
        close: true,
        gravity: 'top',
        position: 'right',
        stopOnFocus: true
      }).showToast()
    }

    return {
      form,
      errorMessage,
      errorNotificationToggle,
      phone
    }
  },
  methods: {
    ...mapActions({
      doFetchCompany: 'companyStore/doFetchCompany',
      editCompany: 'companyStore/editCompany'
    }),
    i18n(key, args) {
      return this.$t(key, args)
    },
    successNotificationWithButtonsToggle() {
      Toastify({
        node: cash('#success-notification-with-buttons')
          .clone()
          .removeClass('hidden')[0],
        duration: 3000,
        newWindow: true,
        close: true,
        gravity: 'top',
        position: 'right',
        stopOnFocus: true,
        onClick: () => {
          this.$router.push('/users')
          const element = document.getElementById(
            'success-notification-with-buttons'
          )
          element.remove()
        }
      }).showToast()
    },
    successNotificationToggle() {
      Toastify({
        node: cash('#success-notification-content')
          .clone()
          .removeClass('hidden')[0],
        duration: 3000,
        newWindow: true,
        close: true,
        gravity: 'top',
        position: 'right',
        stopOnFocus: true
      }).showToast()
    },
    async doSubmit() {
      if (this.isFormValid()) {
        const { partitionId, id } = this.$route.params

        await this.editCompany({
          id,
          partitionId,
          form: {
            ...this.form,
            folderId: this.record.folderId
          }
        })
        // this.successNotificationWithButtonsToggle()
        this.successNotificationToggle()
        this.$router.push({ name: 'company' })
      } else {
        setTimeout(() => {
          this.errorNotificationToggle()
        }, 100)
      }
    },
    doCancel() {
      this.$router.back()
    },
    isFormValid() {
      for (var key in this.form) {
        var value = this.form[key]
        if (value.length == 0 && key != 'phoneNumber' && key != 'emailAddress') {
          this.errorMessage = this.i18n('companies.addOrEdit.errors.emptyField')
          return false
        }
      }
      if (this.form.emailAddress && !this.validEmail(this.form.emailAddress)) {
        this.errorMessage = this.i18n('companies.addOrEdit.errors.invalidEmail')
        return false
      }
      return true
    },
    validEmail(email) {
      return String(email)
        .toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        )
    }
    // i18n(key, args) {
    //   return this.$t(key, args)
    // }
  },
  mounted() {
    const { partitionId, id } = this.$route.params
    this.doFetchCompany({ partitionId, id })
  },
  watch: {
    record(newval) {
      this.form.name = newval.name || ''
      this.form.emailAddress = newval.emailAddress || ''
      this.form.phoneNumber = newval.phoneNumber || ''
      // this.form.address = newval.address
    }
  }
})
</script>
<style lang="scss">

</style>

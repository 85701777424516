<template>
  <div>
    <div class="grid grid-cols-12 gap-6">
      <div class="col-span-12 lg:col-span-12 2xl:col-span-12">
        <!-- BEGIN: Change Password -->
        <div class="intro-y box lg:mt-5">
          <div class="flex items-center p-5 border-b border-gray-200 dark:border-dark-5">
            <h2 class="font-medium text-base">{{i18n('changePassword.title')}}</h2>
          </div>
          <div class="p-5">
            <div>
              <label for="change-password-form-1" class="form-label">{{i18n('changePassword.oldPassword')}}</label>
              <input id="change-password-form-1" type="password" class="form-control dark:bg-dark-9"
                v-model="form.oldPassword" />
            </div>
            <div class="mt-3">
              <label for="change-password-form-2" class="form-label">{{i18n('changePassword.newPassword')}}</label>
              <input id="change-password-form-2" type="password" class="form-control dark:bg-dark-9"
                v-model="form.newPassword" />
            </div>
            <div class="mt-3">
              <label for="change-password-form-3" class="form-label">{{i18n('changePassword.confirmPassword')}}</label>
              <input id="change-password-form-3" type="password" class="form-control dark:bg-dark-9"
                v-model="form.confirmPassword" />
            </div>
            <button type="button" class="btn text-white bg-theme-39 mt-4" style="background: #d5aa0f"
              @click="doSubmit()">
              {{i18n('common.save')}}
            </button>
            <button type="button" class="btn btn-secondary mt-4 ml-4 mr-4 dark:text-black" @click="doCancel()">
              {{i18n('common.cancel')}}
            </button>
          </div>
        </div>
        <!-- END: Change Password -->
      </div>
    </div>
    <ErrorNotification :message="errorMessage" />
    <SuccessNotification :message="i18n('changePassword.success')" />
  </div>
</template>

<script>
import { defineComponent, ref } from 'vue'
import ErrorNotification from '@/components/notifications/error-notification.vue'
import SuccessNotification from '@/components/notifications/success-notification.vue'
import Toastify from 'toastify-js'
import { mapActions, mapGetters } from 'vuex'
// import axios from 'axios'

export default defineComponent({
  // created() {
  //   document.title =
  //     this.i18n('routes.app') + ' | ' + this.i18n('routes.changePassword')
  // },
  // watch: {
  //   isRTL() {
  //     document.title =
  //       this.i18n('routes.app') + ' | ' + this.i18n('routes.changePassword')
  //   }
  // },
  computed: {
    ...mapGetters({
      currentUser: 'authStore/currentUser'
    }),
    isRTL() {
      return this.$i18n.locale == 'ar'
    },
    language() {
      return this.$i18n.locale
    }
  },
  components: {
    ErrorNotification,
    SuccessNotification
  },
  setup() {
    const form = ref({
      oldPassword: '',
      newPassword: '',
      confirmPassword: ''
    })
    const errorMessage = ref('')
    const errorNotificationToggle = () => {
      Toastify({
        node: cash('#error-notification-content').clone().removeClass('hidden')[0],
        duration: 3000,
        newWindow: true,
        close: true,
        gravity: 'top',
        position: 'right',
        stopOnFocus: true
      }).showToast()
    }

    const successNotificationToggle = () => {
      Toastify({
        node: cash('#success-notification-content').clone().removeClass('hidden')[0],
        duration: 3000,
        newWindow: true,
        close: true,
        gravity: 'top',
        position: 'right',
        stopOnFocus: true
      }).showToast()
    }

    return {
      form,
      errorMessage,
      errorNotificationToggle,
      successNotificationToggle
    }
  },
  methods: {
    ...mapActions({
      getCurrentUser: 'authStore/getCurrentUser',
      saveCurrentUser: 'authStore/saveCurrentUser',
      changePassword: 'usersStore/changePassword'
    }),
    async doSubmit() {
      if (this.isFormValid()) {
        await this.changePassword({
          email: this.currentUser.emailAddress,
          oldPassword: this.form.oldPassword,
          newPassword: this.form.newPassword
        })
        this.successNotificationToggle()
        this.$router.push({ name: 'profile', params: { locale: this.language } })
      } else {
        setTimeout(() => {
          this.errorNotificationToggle()
        }, 100)
      }
    },
    doCancel() {
      this.$router.back()
    },
    i18n(key, args) {
      return this.$t(key, args)
    },
    isFormValid() {
      for (var key in this.form) {
        var value = this.form[key]
        if (value.length == 0) {
          this.errorMessage = 'changePassword.errors.emptyField'
          return false
        }
      }
      if (this.form.newPassword != this.form.confirmPassword) {
        this.errorMessage = 'changePassword.errors.passwordsDontMatch'
        return false
      }
      return true
    }
  },
  mounted() {
    this.getCurrentUser()
  }
})
</script>

<template>
  <!-- BEGIN: Top Bar -->
  <div class="top-bar -mx-4 px-4 md:mx-0 md:px-0">
    <!-- BEGIN: Breadcrumb -->
    <div class="-intro-x breadcrumb mr-auto sm:flex">
      <!-- <a href="">Application</a>
      <ChevronRightIcon class="breadcrumb__icon" />
      <a href="" class="breadcrumb--active">Dashboard</a> -->
    </div>
    <!-- END: Breadcrumb -->
    <!-- BEGIN: Search -->
    <!-- <div class="intro-x relative mr-3 sm:mr-6">
      <div class="search hidden sm:block">
        <input
          type="text"
          class="search__input form-control border-transparent placeholder-theme-13"
          placeholder="Search..."
          @focus="showSearchDropdown"
          @blur="hideSearchDropdown"
        />
        <SearchIcon class="search__icon dark:text-gray-300" />
      </div>
      <a class="notification sm:hidden" href="">
        <SearchIcon class="notification__icon dark:text-gray-300" />
      </a>
      <div class="search-result" :class="{ show: searchDropdown }">
        <div class="search-result__content">
          <div class="search-result__content__title">Pages</div>
          <div class="mb-5">
            <a href="" class="flex items-center">
              <div
                class="w-8 h-8 bg-theme-17 text-theme-20 flex items-center justify-center rounded-full"
              >
                <InboxIcon class="w-4 h-4" />
              </div>
              <div class="ml-3">Mail Settings</div>
            </a>
            <a href="" class="flex items-center mt-2">
              <div
                class="w-8 h-8 bg-theme-18 text-theme-21 flex items-center justify-center rounded-full"
              >
                <UsersIcon class="w-4 h-4" />
              </div>
              <div class="ml-3">Users & Permissions</div>
            </a>
            <a href="" class="flex items-center mt-2">
              <div
                class="w-8 h-8 bg-theme-19 text-theme-22 flex items-center justify-center rounded-full"
              >
                <CreditCardIcon class="w-4 h-4" />
              </div>
              <div class="ml-3">Transactions Report</div>
            </a>
          </div>
          <div class="search-result__content__title">Users</div>
          <div class="mb-5">
            <a
              v-for="(faker, fakerKey) in $_.take($f(), 4)"
              :key="fakerKey"
              href
              class="flex items-center mt-2"
            >
              <div class="w-8 h-8 image-fit">
                <img
                  alt="Tinker Tailwind HTML Admin Template"
                  class="rounded-full"
                  :src="require(`@/assets/images/${faker.photos[0]}`)"
                />
              </div>
              <div class="ml-3">{{ faker.users[0].name }}</div>
              <div
                class="ml-auto w-48 truncate text-gray-600 text-xs text-right"
              >
                {{ faker.users[0].email }}
              </div>
            </a>
          </div>
          <div class="search-result__content__title">Products</div>
          <a
            v-for="(faker, fakerKey) in $_.take($f(), 4)"
            :key="fakerKey"
            href
            class="flex items-center mt-2"
          >
            <div class="w-8 h-8 image-fit">
              <img
                alt="Tinker Tailwind HTML Admin Template"
                class="rounded-full"
                :src="require(`@/assets/images/${faker.images[0]}`)"
              />
            </div>
            <div class="ml-3">{{ faker.products[0].name }}</div>
            <div class="ml-auto w-48 truncate text-gray-600 text-xs text-right">
              {{ faker.products[0].category }}
            </div>
          </a>
        </div>
      </div>
    </div> -->
    <!-- END: Search -->

    <!-- BEGIN: Account Menu -->
    <I18nFlags />
    <!-- BEGIN: Notifications -->
    <div
      class="intro-x dropdown"
      :class="isRTL ? 'ml-auto sm:ml-6' : 'mr-auto sm:mr-6'"
    >
      <div
        :class="notificationUnreadCount > 0 ? 'bullet-red' : ''"
        class="dropdown-toggle notification notification--bullet cursor-pointer"
        role="button"
        aria-expanded="false"
      >
        <BellIcon class="notification__icon dark:text-gray-300" />
      </div>
      <div class="notification-content pt-2 dropdown-menu">
        <div
          class="
            notification-content__box
            dropdown-menu__content
            box
            dark:bg-dark-6
          "
          style="padding-left: 0; padding-right: 0"
        >
          <div
            class="notification-content__title"
            style="padding-right: 20px; padding-left: 20px"
          >
            {{ i18n('notifications.title') }}
          </div>
          <div
            style="
              max-height: 362px !important;
              overflow-y: auto;
              padding-right: 10px;
              padding-left: 20px;
            "
          >
            <div
              v-for="(notification, index) in rows"
              :key="index"
              class="cursor-pointer relative flex items-center"
              :class="{ 'mt-5': index }"
            >
              <div class="w-12 h-12 flex-none image-fit mr-1">
                <img
                  :src="require(`@/assets/images/notify.png`)"
                  alt="Tinker Tailwind HTML Admin Template"
                  class="rounded-full"
                />
                <div
                  class="
                    w-3
                    h-3
                    bg-theme-20
                    absolute
                    right-0
                    bottom-0
                    rounded-full
                    border-2 border-white
                  "
                  :style="notification.isRead ? '' : 'background: green;'"
                ></div>
              </div>
              <div
                class="ml-2 overflow-hidden"
                @click="readNotification(notification)"
              >
                <div class="flex items-center">
                  <a href="javascript:;" class="font-medium truncate mr-5">{{
                    notification.username
                  }}</a>
                  <div class="text-xs text-gray-500 ml-auto whitespace-nowrap">
                    {{ presenterDate(notification, 'createdAt') }}
                  </div>
                </div>
                <div class="w-full truncate text-gray-600 mt-0.5">
                  {{ isRTL ? notification.ar : notification.en }}
                </div>
              </div>
            </div>
            <div
              v-if="rows.length == 0"
              class="col-span-12 items-center flex justify-center"
              style="
                height: 350px;
                margin-right: 10px;
                border: 1px solid rgb(235 235 235 / 30%);
                border-radius: 6px;
              "
            >
              <div>
                <div class="flex justify-center">
                  <!-- <FileIcon style="height: 60px; width: 60px;" /> -->
                  <span class="material-icons-outlined" style="font-size: 60px"
                    >notifications_off</span
                  >
                </div>
                <h3 class="font-medium text-lg mt-5">
                  {{ i18n('notifications.noNotification') }}
                </h3>
              </div>
            </div>
          </div>
          <div class="mx-auto pt-4">
            <button
              @click="routeNotification"
              class="py-2 px-4 bg-black block text-white mx-auto rounded-lg"
            >
              {{ i18n('notifications.viewAllNotifications') }}
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- END: Notifications -->
    <div class="intro-x dropdown w-8 h-8">
      <div
        class="
          dropdown-toggle
          w-8
          h-8
          rounded-full
          overflow-hidden
          image-fit
          zoom-in
          flex
          justify-center
        "
        role="button"
        id="dropdown-toggle"
        aria-expanded="false"
      >
        <div class="w-8 h-8 image-fit">
          <img
            alt="Tinker Tailwind HTML Admin Template"
            class="rounded-full"
            :src="
              currentUser && currentUser.profilePicture
                ? currentUser.profilePicture
                : require(`@/assets/images/profile-1.jpg`)
            "
          />
        </div>
        <!-- <SettingsIcon /> -->
      </div>

      <div class="dropdown-menu w-56">
        <div class="dropdown-menu__content box dark:bg-dark-6">
          <div
            class="
              p-4
              border-b border-black border-opacity-5
              dark:border-dark-3
            "
          >
            <div class="font-medium">
              {{ currentUser ? currentUser.name : '' }}
            </div>
          </div>
          <div class="p-2">
            <router-link
              :to="{ name: 'profile', params: { locale: language } }"
              class="
                flex
                items-center
                block
                p-2
                transition
                duration-300
                ease-in-out
                hover:bg-gray-200
                dark:hover:bg-dark-3
                rounded-md
                cursor-pointer
              "
              @click="goToProfile"
            >
              <UserIcon class="w-4 h-4 mr-2 ml-2" />
              {{ i18n('topbar.profile') }}
            </router-link>
            <!-- <a
              href=""
              class="flex items-center block p-2 transition duration-300 ease-in-out hover:bg-gray-200 dark:hover:bg-dark-3 rounded-md"
            >
              <EditIcon class="w-4 h-4 mr-2" /> Add Account
            </a>
            <a
              href=""
              class="flex items-center block p-2 transition duration-300 ease-in-out hover:bg-gray-200 dark:hover:bg-dark-3 rounded-md"
            >
              <LockIcon class="w-4 h-4 mr-2" /> Reset Password
            </a>
            <a
              href=""
              class="flex items-center block p-2 transition duration-300 ease-in-out hover:bg-gray-200 dark:hover:bg-dark-3 rounded-md"
            >
              <HelpCircleIcon class="w-4 h-4 mr-2" /> Help
            </a> -->
          </div>

          <div
            class="
              p-2
              border-t border-black border-opacity-5
              dark:border-dark-3
            "
          >
            <a
              class="
                flex
                items-center
                block
                p-2
                transition
                duration-300
                ease-in-out
                hover:bg-gray-200
                dark:hover:bg-dark-3
                rounded-md
                cursor-pointer
              "
              @click="logout()"
            >
              <ToggleRightIcon class="w-4 h-4 mr-2 ml-2" />
              {{ i18n('topbar.logout') }}
            </a>
          </div>
        </div>
      </div>
    </div>
    <!-- END: Account Menu -->
  </div>
  <!-- END: Top Bar -->
</template>

<script>
import I18nFlags from '../../global-components/i18n/i18n-flags.vue'
import { defineComponent, ref } from 'vue'
import { mapActions, mapGetters } from 'vuex'
import moment from 'moment'
import firebase from 'firebase/compat/app'
import 'firebase/compat/firestore'
// import AuthService from '../../auth/auth-service'
// import config from '@/config'
// const editorUrl = config.editorUrl
// import { doc, onSnapshot } from 'firebase/firestore'
// import { collection, query, onSnapshot } from 'firebase/firestore'

export default defineComponent({
  components: {
    I18nFlags
  },
  setup() {
    const searchDropdown = ref(false)

    const showSearchDropdown = () => {
      searchDropdown.value = true
    }

    const hideSearchDropdown = () => {
      searchDropdown.value = false
    }
    // const notificationUnread = ref([])
    return {
      searchDropdown,
      showSearchDropdown,
      hideSearchDropdown
      // notificationUnread
    }
  },
  computed: {
    ...mapGetters({
      currentUser: 'authStore/currentUser',
      rows: 'notification/rows'
    }),
    isRTL() {
      return this.$i18n.locale == 'ar'
    },
    language() {
      return this.$i18n.locale
    },
    notificationUnread() {
      if (this.rows) {
        // this.notificationUnread = this.rows.filter(row => !row.isRead)
        return this.rows.filter((row) => !row.isRead)
      }
      return []
    },
    notificationUnreadCount() {
      return this.notificationUnread.length
    }
  },
  methods: {
    ...mapActions({
      getCurrentUser: 'authStore/getCurrentUser',
      saveCurrentUser: 'authStore/saveCurrentUser',
      doSignout: 'authStore/doSignout',
      doFetchNotifications: 'notification/doFetchByVuexfire',
      doFetchEmployee: 'dataCenterEmployeeStore/doFetch',
      doFetchAdmin: 'dataCenterStore/doFetch',
      doRunNotificationListener: 'notification/doRunNotificationListener'
    }),
    async logout() {
      document.getElementById('dropdown-toggle').click()
      await this.doSignout()
    },
    goToProfile() {
      document.getElementById('dropdown-toggle').click()
      // this.$router.push('/profile')
    },
    routeNotification() {
      this.notificationUnread.forEach((notification) => {
        firebase
          .firestore()
          .doc(`users/${this.currentUser.id}/notifications/${notification.id}`)
          .update({
            isRead: true
          })
      })
      this.$router.push({
        name: 'notifications',
        params: { locale: this.$i18n.locale }
      })
    },
    i18n(key, args) {
      return this.$t(key, args)
    },
    formatDate(date) {
      return moment.unix(date.seconds).format('DD/MM/YYYY')
    },
    presenterDateTime(row, fieldName) {
      const val = row[fieldName]
      return val
        ? moment(val).locale(this.language).format('DD-MM-YYYY h:mm A')
        : 'ـــ'
    },
    presenterDate(row, fieldName) {
      const val = row[fieldName]
      return val
        ? moment(val).locale(this.language).format('D MMM, YYYY')
        : 'ـــ'
    },
    presenterDay(row, fieldName) {
      const val = row[fieldName]
      return val ? moment(val).locale(this.language).format('dddd') : 'ـــ'
    },
    presenterTime(row, fieldName) {
      const date = row[fieldName]
      return moment(date, "YYYY-MM-DD'T'hh:mm:ss.SSS'Z'")
        .locale(this.language)
        .format('hh:mm a')
    },
    readNotification(notification) {
      if (!notification.isRead) {
        firebase
          .firestore()
          .doc(`users/${this.currentUser.id}/notifications/${notification.id}`)
          .update({
            isRead: true
          })
      }
      const path = notification.fullPath.split('/').slice(0, -1).join('/')
      this.currentUser.roles === 'Employee'
        ? this.doFetchEmployee({
          path,
          currentUser: this.currentUser
        })
        : this.doFetchAdmin(path)
    }
  },
  async created() {
    await this.getCurrentUser()
    this.doRunNotificationListener()
  }
})
</script>

<style>
.bullet-red.notification.notification--bullet:before {
  content: '';
  width: 8px;
  height: 8px;
  position: absolute;
  right: 0px;
  border-radius: 9999px;
  --tw-bg-opacity: 1;
  background-color: rgba(190, 190, 190, var(--tw-bg-opacity));
  color: red;
  background: red !important;
}
</style>

<template>
  <div class="pb-0 pt-3 flex justify-center items-center my-4 w-full lg:w-1/4  mx-auto">
    <button
      class="mr-12 py-2 px-4 rounded-lg text-black block "
      :disabled="page === 0"
      @click="$emit('prevPage')"
      style="background-color:#edf2f7;"
    >
      {{ i18n('table.previous') }}
    </button>
    <!-- <span class="mr-12 block mx-auto whitespace-nowrap">  {{ i18n('table.page') }} {{ page + 1 }} {{ i18n('table.of') }} {{ pageCount }}</span> -->
    <span class="mr-12 block mx-auto whitespace-nowrap">  {{ i18n('table.page') }} {{ page + 1 }}</span>
    <button
      class=" py-2 mr-8 px-8 rounded-lg text-white block mx-auto"
      @click="$emit('nextPage')"
      style="background-color:#d5aa0f"
    >
      {{ i18n('table.next') }}
    </button>
  </div>
</template>
<script>
import { defineComponent } from 'vue'
export default defineComponent({
  props: {
    page: Number,
    pageCount: Number
  },
  methods: {
    i18n(key, args) {
      return this.$t(key, args)
    }
  }
})
</script>

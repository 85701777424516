import service from './auth-service'
import store from '../store/index'
import 'firebase/compat/auth'
import 'firebase/compat/firestore'
import firebase from 'firebase/compat/app'
import FirebaseRepository from '../shared/firebase/firebase-repository'
// import { vuexfireMutations, firestoreAction } from 'vuexfire'

// import { getAuth } from 'firebase/compat/auth'
import router from '../router/index'

export default {
  namespaced: true,

  state: {
    authenticationUser: null,
    currentUser: null,
    loadingInit: true,
    loadingEmailConfirmation: false,
    loadingPasswordResetEmail: false,
    loadingUpdateProfile: false,
    loading: false,
    error: null,
    user: null,
    ownerExist: false
  },

  getters: {
    ownerExist: state => state.ownerExist,

    user(state) {
      return state.user
    },
    isUserAuth(state) {
      return !!state.user
    },
    getError(state) {
      return state.error
    },
    authenticationUser: state => state.authenticationUser,
    currentUser: state => state.currentUser,
    currentUserEmail: (state, getters) =>
      getters.currentUser ? getters.currentUser.email : null,
    currentUserFullName: (state, getters) =>
      getters.currentUser ? getters.currentUser.fullName : '',
    signedIn: (state, getters) =>
      !!getters.currentUser && !!getters.currentUser.id,
    roles: (state, getters) =>
      getters.currentUser ? getters.currentUser.roles || [] : [],
    emptyPermissions: (state, getters) =>
      !getters.roles || !getters.roles.length,
    loading: state => state.loading,
    loadingInit: state => !!state.loadingInit,
    loadingEmailConfirmation: state => !!state.loadingEmailConfirmation,
    loadingPasswordResetEmail: state => !!state.loadingPasswordResetEmail,
    loadingUpdateProfile: state => !!state.loadingUpdateProfile,
    currentUserNameOrEmailPrefix: (state, getters) => {
      if (!getters.currentUser) {
        return null
      }

      if (
        getters.currentUserFullName &&
        getters.currentUserFullName.length < 25
      ) {
        return getters.currentUserFullName
      }

      if (getters.currentUser.firstName) {
        return getters.currentUser.firstName
      }

      return getters.currentUser.email.split('@')[0]
    },
    currentUserAvatar: (state, getters) => {
      if (
        !getters.currentUser ||
        !getters.currentUser.avatars ||
        !getters.currentUser.avatars.length ||
        !getters.currentUser.avatars[0].publicUrl
      ) {
        return null
      }

      return getters.currentUser.avatars[0].publicUrl
    }
  },

  mutations: {
    // ...vuexfireMutations,
    CURRENT_USER_REFRESH_SUCCESS(state, payload) {
      state.currentUser = payload.currentUser || null
    },
    AUTH_INIT_SUCCESS(state, payload) {
      state.authenticationUser = payload.authenticationUser || null
      state.currentUser = payload.currentUser || null
      state.loadingInit = false
    },
    AUTH_INIT_ERROR(state) {
      state.authenticationUser = null
      state.currentUser = null
      state.loadingInit = false
    },
    setUser(state, payload) {
      state.user = payload
      state.currentUser = payload
    },
    setError(state, payload) {
      state.error = payload
    },
    LOADING_START(state) {
      state.loading = true
    },
    LOADING_FINISHED(state) {
      state.loading = false
    },
    AUTH_START(state) {
      state.loading = true
    },
    AUTH_SUCCESS(state, payload) {
      state.authenticationUser = payload.authenticationUser || null
      state.currentUser = payload.currentUser || null
      state.loading = false
    },
    AUTH_ERROR(state) {
      state.authenticationUser = null
      state.currentUser = null
      state.loading = false
    },
    VERIFY_OWNER(state, payload) {
      state.ownerExist = !!payload
    }
  },

  actions: {
    async doInitFirebase() {
      service.init()
    },
    async doInit({ commit, dispatch }) {
      service.init()
      await dispatch('verifyOwner')
      const currentUser = localStorage.getItem('currentUser')
      if (!currentUser) {
        commit('AUTH_INIT_SUCCESS', {
          currentUser: null,
          authenticationUser: null
        })
        return
      } else {
        const user = service.decryption(currentUser, 'secret-c-u')
        if (!user.isActive) {
          commit('AUTH_INIT_SUCCESS', {
            currentUser: null,
            authenticationUser: null
          })
          return
        }
      }

      const unsubscribe = service.onAuthStateChanged(
        authenticationUser => {
          dispatch('doSigninFromAuthChange', authenticationUser)
          unsubscribe()
        },
        error => {
          console.error(error)
          commit('AUTH_INIT_ERROR')
        }
      )
    },
    async doSigninFromAuthChange({ commit }, authenticationUser) {
      try {
        let currentUser = localStorage.getItem('currentUser')
        if (authenticationUser) {
          if (!currentUser) {
            currentUser = await service.fetchMe()
            localStorage.setItem(
              'currentUser',
              service.encryption(currentUser, 'secret-c-u')
            )
          } else {
            currentUser = service.decryption(currentUser, 'secret-c-u')
          }
          currentUser.emailVerified = authenticationUser.emailVerified
        }
        commit('AUTH_INIT_SUCCESS', {
          currentUser,
          authenticationUser: authenticationUser
        })
      } catch (error) {
        commit('AUTH_INIT_ERROR', error)
      }
    },
    async doRegisterOwner({ commit }, payload) {
      try {
        // debugger
        const email = payload.email
        const password = payload.password
        const fullName = `${payload.firstName} ${payload.lastName}`
        const username = payload.firstName + payload.lastName
        const response = await firebase.auth().createUserWithEmailAndPassword(email, password)
        const id = firebase.firestore().collection('ids').doc().id
        const owner = {
          id: id,
          uid: response.user.uid,
          emailAddress: email,
          tempPassword: password,
          name: fullName || null,
          username: username || null,
          phoneNumber: payload.phoneNumber || null,
          isActive: true,
          isOwner: true,
          roles: 'Admin',
          company: null,
          department: null,
          createdAt: firebase.firestore.Timestamp.now()
        }
        await firebase.firestore().collection('users').doc(id).set(owner)
        await firebase.firestore().collection('--sharedInfo--').doc('--owner--').set({
          isExist: true
        })
        router.push('/')
      } catch (error) {
        commit('AUTH_INIT_ERROR', error)
      }
    },
    async signUpAction({ commit }, payload) {
      try {
        commit('LOADING_START')
        const { departments, email, password, name, username, phoneNumber } = payload.form
        const getUniqueListBy = (arr, key) => {
          return [...new Map(arr.map(item => [item[key], item])).values()]
        }
        const partitions = [...new Set(departments.map(dep => {
          if (dep.type === 'partition') {
            return dep.id
          } else {
            return dep.partition
          }
        }))]
        // const companies = company && company.map(comp => JSON.parse(comp))
        const companies = getUniqueListBy(departments.map(dep => {
          if (dep.type === 'company') {
            return { id: dep.id, partition: dep.partition }
          } else if (dep.type === 'department') {
            return { id: dep.company, partition: dep.partition }
          }
        }).filter(dep => dep), 'id')
        const filteredDepartments = departments.filter(dep => dep.type === 'department')
        const response = await firebase.auth().createUserWithEmailAndPassword(email, password)
        const id = firebase.firestore().collection('ids').doc().id
        const user = {
          id: id,
          uid: response.user.uid,
          emailAddress: email,
          tempPassword: password,
          name: name || null,
          username: username || null,
          phoneNumber: phoneNumber || null,
          isActive: true,
          roles: payload.roles || null,
          partition: partitions && partitions.length ? partitions.map(part => (firebase.firestore().doc(`partition/${part}`))) : null,
          company: companies && companies.length ? companies.map(comp => (firebase.firestore().doc(`partition/${comp.partition}/company/${comp.id}`))) : null,
          department: filteredDepartments && filteredDepartments.length ? filteredDepartments.map(dep => (firebase.firestore().doc(`partition/${dep.partition}/company/${dep.company}/department/${dep.id}`))) : null,
          createdAt: firebase.firestore.Timestamp.now()
        }
        await firebase.firestore().collection('users').doc(id).set(user)
        store.dispatch('usersStore/doAppendUser', user)
        store.dispatch('auditLogStore/log', {
          ref: `users/${user.id}`,
          entityName: 'users',
          entityId: user.id,
          action: 'create',
          values: user
        })
        commit('LOADING_FINISHED')
      } catch (error) {
        commit('LOADING_FINISHED')
      }
    },
    async signInAction({ commit, dispatch, getters }, payload) {
      commit('LOADING_START')
      const language = localStorage.getItem('language') ? localStorage.getItem('language') : 'ar'
      return await firebase
        .auth()
        .signInWithEmailAndPassword(payload.email, payload.password)
        .then(async response => {
          await dispatch('fetchMe', payload.email)
          commit('LOADING_FINISHED')
          router.push({ name: 'data-center', params: { locale: language } })
        })
        .catch(error => {
          commit('LOADING_FINISHED')
          commit('setError', error.message)
        })
    },
    getCurrentUser({ commit }) {
      const currentUser = localStorage.getItem('currentUser')
      if (currentUser) {
        const user = service.decryption(currentUser, 'secret-c-u')
        commit('setUser', user)
      }
    },
    async fetchMe({ commit, dispatch }, email) {
      const snapshot = await firebase
        .firestore()
        .collection('users')
        .where('emailAddress', '==', email)
        .get()
      if (snapshot.empty) {
        return
      }
      for (let index = 0; index < snapshot.docs.length; index++) {
        const user = snapshot.docs[index].data()
        user.partition = user.partition ? await Promise.all(user.partition.map(async part => await part.get())) : null
        user.company = user.company ? await Promise.all(user.company.map(async comp => await comp.get())) : null
        user.department = user.department ? await Promise.all(user.department.map(async dep => await dep.get())) : null
        user.partition = user.partition ? user.partition.map(part => part.data()) : null
        user.company = user.company ? user.company.map(comp => comp.data()) : null
        user.department = user.department ? user.department.map(dep => dep.data()) : null
        await dispatch('saveCurrentUser', user)
      }
    },
    saveCurrentUser({ commit, dispatch }, user) {
      const ciphertext = service.encryption(user, 'secret-c-u')
      localStorage.setItem('currentUser', ciphertext)
      dispatch('getCurrentUser')
    },
    async doSignout({ commit }) {
      try {
        commit('AUTH_START')
        await service.signout()
        localStorage.removeItem('currentUser')
        commit('AUTH_SUCCESS', {
          authenticationUser: null,
          currentUser: null
        })
        router.push({ name: 'login' })
      } catch (error) {
        // Errors.handle(error)
        commit('AUTH_ERROR')
      }
    },
    async verifyOwner({ commit }) {
      try {
        // debugger
        const response = await FirebaseRepository.findDocument(
          '--sharedInfo--',
          '--owner--'
        )
        const isExist = response ? response.isExist : false
        commit('VERIFY_OWNER', isExist)
      } catch (error) {
        console.log(error)
      }
    }
  }
}

import { FileService } from '../../src/services/fileService'
import HelperClass from '@/shared/helperClass'
import { vuexfireMutations } from 'vuexfire'
import firebase from 'firebase/compat/app'
import 'firebase/compat/firestore'
// import 'firebase/compat/database'
import 'firebase/compat/storage'
import { doc, onSnapshot } from 'firebase/firestore'
// import { collection, query, orderBy, onSnapshot } from 'firebase/firestore''

import store from './index'
import FirebaseRepository from '../../src/shared/firebase/firebase-repository'
import axios from 'axios'
import config from '../config'
const backendUrl = config.backendUrl

const state = () => {
  return {
    rows: [],
    filesInFirestore: [],
    loading: false,
    record: null,
    destroyLoading: false,
    uploadLoading: false,
    progressUploaderCount: 0,
    totalProgressCount: 0
  }
}
const getters = {
  rows: state => state.rows,
  loading: state => state.loading,
  record: state => state.record,
  filesInFirestore: state => state.filesInFirestore,
  destroyLoading: state => state.destroyLoading,
  uploadLoading: state => state.uploadLoading,
  progressUploaderCount: state => state.progressUploaderCount,
  totalProgressCount: state => state.totalProgressCount
}
const mutations = {
  ...vuexfireMutations,
  FETCH_START(state) {
    state.loading = true
  },
  FETCH_SUCCESS(state) {
    state.loading = false
  },
  FETCH_ERROR(state) {
    state.loading = false
  },
  ADD_TO_ROWS(state, payload) {
    state.rows.push(payload)
  },
  DELETE_FROM_ROWS(state, payload) {
    const index = state.rows.findIndex(row => row.id == payload)
    state.rows.splice(index, 1)
    // const index2 = state.filesInFirestore.findIndex(row => row.id == payload)
    // state.filesInFirestore.splice(index2, 1)
  },
  REMOVE_FIRST_ROW(state) {
    state.rows.shift()
    state.filesInFirestore.shift()
  },
  ADD_TO_FIRESTORE(state, payload) {
    state.filesInFirestore.push(payload)
  },
  RESET_ROWS(state) {
    state.rows = []
  },
  RESET_FIRESTORE(state) {
    state.filesInFirestore = []
  },
  ADD_RECORD(state, payload) {
    state.record = payload
  },
  DESTROY_STARTED(state) {
    state.destroyLoading = true
  },
  DESTROY_SUCCESS(state) {
    state.destroyLoading = false
  },
  DESTROY_ERROR(state) {
    state.destroyLoading = false
  },
  UPLOAD_STARTED(state) {
    state.uploadLoading = true
  },
  UPLOAD_SUCCESS(state) {
    state.uploadLoading = false
  },
  UPLOAD_ERROR(state) {
    state.uploadLoading = false
  },
  UPDATE_PROGRESS_UPLOADER_COUNT(state, payload) {
    state.progressUploaderCount = payload.count
    state.totalProgressCount = payload.total
  }
}
const actions = {
  async doFetch({ commit, dispatch, getters }, path) {
    commit('RESET_ROWS')
    commit('FETCH_START')
    var storageRef = firebase.storage().ref(path)
    const result = await storageRef.listAll()
    for (let index = 0; index < result.items.length; index++) {
      if (result.items[index].name != 'starterFile') {
        await dispatch('getDownloadUrl', result.items[index])
      }
    }
    for (let index = 0; index < result.prefixes.length; index++) {
      const folder = result.prefixes[index]
      if (folder.name != 'trash') {
        const ID = folder.name.substr(folder.name.indexOf('?:') + 2)

        const folderInFirestore = getters?.filesInFirestore?.find(
          file => file.id == ID
        )

        let folderName =
          folderInFirestore && folderInFirestore.name
            ? folderInFirestore.name
            : folder.name.substr(0, folder.name.indexOf('?:'))

        if (
          folderName.startsWith('Shared with me') &&
          folderName.includes('?:')
        ) {
          folderName = folderName.substr(0, folder.name.indexOf('?:'))
        }
        commit('ADD_TO_ROWS', {
          id: ID,
          name: folderName,
          url: folder.fullPath,
          type: 'folder'
        })
      }
    }
    commit('FETCH_SUCCESS')
  },
  async doFetchFirestore({ commit }) {
    commit('RESET_FIRESTORE')
    commit('FETCH_START')
    const usersRef = firebase
      .firestore()
      .collection('files')
      .orderBy('createdAt')
    const snapshot = await usersRef.get()
    if (snapshot.empty) {
      commit('FETCH_SUCCESS')
      return
    }

    snapshot.forEach(doc => {
      commit('ADD_TO_FIRESTORE', doc.data())
    })
    commit('FETCH_SUCCESS')
  },
  async doAppendNewFileToRows({ commit }, file) {
    const FileType = file.type
    if (FileType == 'Folder') {
      commit('ADD_TO_ROWS', {
        url: `${file.parentID}/${file.name}`,
        name: file.name.substr(0, file.name.indexOf('?:')),
        type: FileType.toLowerCase(),
        id: file.id
      })
    } else {
      const type = getFileType(file.name)
      commit('ADD_TO_ROWS', {
        name: file.name,
        url: file.publicUrl,
        type: type,
        id: file.id
      })
    }
  },
  async getDownloadUrl({ commit, dispatch }, imageRef) {
    const type = getFileType(
      imageRef.name.substr(0, imageRef.name.indexOf('?:'))
    )
    const url = await imageRef.getDownloadURL()
    const ID = imageRef.name.substr(imageRef.name.indexOf('?:') + 2)
    const fileInFirestore = await FirebaseRepository.findDocument('files', ID)
    const fileName =
      fileInFirestore && fileInFirestore.name
        ? fileInFirestore.name
        : imageRef.name.substr(0, imageRef.name.indexOf('?:'))
    commit('ADD_TO_ROWS', {
      id: ID,
      name: fileName,
      url,
      type: type
    })
  },
  async createFolder(context, { path, serverPath, destination }) {
    // debugger
    // const companyNumber = getCompanyNumber(path)
    // const departmentNumber = getDepartmentNumber(path)
    var id = firebase
      .firestore()
      .collection('ids')
      .doc().id
    const storage = firebase.storage().ref(`${destination}?:${id}/starterFile`)
    const file = 'starterFile'
    await storage.put(file)

    context.dispatch('createFile', {
      id: `${id}`,
      name: `${destination.substr(destination.lastIndexOf('/') + 1)}?:${id}`,
      createdAt: firebase.firestore.Timestamp.now(),
      createdBy: store.getters['authStore/currentUser'].id,
      location: 'Share',
      type: 'Folder',
      parentID: path,
      readAccess: [store.getters['authStore/currentUser'].id],
      downloadAccess: [store.getters['authStore/currentUser'].id],
      printAccess: [store.getters['authStore/currentUser'].id],
      shareAccess: [store.getters['authStore/currentUser'].id],
      deleteAccess: [store.getters['authStore/currentUser'].id],
      // departmentNumber,
      state: 'published'
    })
  },
  async createFolderWithId(context, { path, serverPath, destination, id }) {
    const companyNumber = getCompanyNumber(path)
    const departmentNumber = getDepartmentNumber(path)
    // const companyId = getCompanyID(path)
    // const departmentId = getDepartmentID(path)
    const storage = firebase
      .storage()
      .ref(
        `${destination}?:${companyNumber}${departmentNumber}${id}/starterFile`
      )
    const file = 'starterFile'
    await storage.put(file)
    var parent = ''
    if (path != serverPath) {
      parent = path.substr(path.lastIndexOf('/'))
    }
    context.dispatch('createFile', {
      id: `${companyNumber}${departmentNumber}${id}`,
      name: `${destination.substr(
        destination.lastIndexOf('/') + 1
      )}?:${companyNumber}${departmentNumber}${id}`,
      createdAt: firebase.firestore.Timestamp.now(),
      createdBy: store.getters['authStore/currentUser'].id,
      location: 'Share',
      type: 'Folder',
      parentID: parent,
      readAccess: [store.getters['authStore/currentUser'].id],
      downloadAccess: [store.getters['authStore/currentUser'].id],
      printAccess: [store.getters['authStore/currentUser'].id],
      shareAccess: [store.getters['authStore/currentUser'].id],
      deleteAccess: [store.getters['authStore/currentUser'].id],
      state: 'published'
    })
  },
  async doDeleteFile({ commit }, { file, path }) {
    try {
      // commit('FETCH_START')
      commit('DELETE_FROM_ROWS', file.id)
      await firebase
        .storage()
        .ref(`${path}/trash/${file.name}?:${file.id}`)
        .delete()
      await firebase
        .firestore()
        .collection('files')
        .doc(file.id)
        .delete()
      await store.dispatch('auditLogStore/log', {
        ref: `files/${file.id}`,
        entityName: 'files',
        entityId: file.id,
        action: 'delete',
        values: file
      })
      // commit('FETCH_SUCCESS')
    } catch (error) {
      console.error(error)
    }
  },
  async doMoveFile({ commit }, { file, path }) {
    const extension = HelperClass.getExtension(file.name)
    commit('DELETE_FROM_ROWS', file.id)
    const data = await fetch(file.url)
    const blob = await data.blob()
    const fileRef = firebase
      .storage()
      .ref(`${path}/trash/${file.name}?:${file.id}`)
    await fileRef.put(blob)
    const fileToDelete = firebase
      .storage()
      .ref(`${path}/filename.${extension}?:${file.id}`)
    await fileToDelete.delete()
    await firebase
      .firestore()
      .collection('files')
      .doc(file.id)
      .update({
        state: 'deleted',
        updatedAt: firebase.firestore.Timestamp.now()
      })
    await store.dispatch('auditLogStore/log', {
      ref: `files/${file.id}`,
      entityName: 'files',
      entityId: file.id,
      action: 'move-to-trash',
      values: file
    })
  },
  async doFetchSharedFiles({ commit }, currentUser) {
    // debugger
    commit('RESET_ROWS')
    commit('FETCH_START')
    let ref = firebase
      .firestore()
      .collection('files')
      .orderBy('createdAt')
      .where('readAccess', 'array-contains', currentUser.id)
    const snapshot = await ref.get()
    if (snapshot.empty) {
      commit('FETCH_SUCCESS')
      return
    }
    snapshot.forEach(doc => {
      commit('ADD_TO_ROWS', doc.data())
    })
    commit('FETCH_SUCCESS')
  },
  createFile(context, payload) {
    context.dispatch('doAppendNewFileToRows', payload)
    firebase
      .firestore()
      .collection('files')
      .doc(payload.id)
      .set(payload)
    store.dispatch('auditLogStore/log', {
      ref: `files/${payload.id}`,
      entityName: 'files',
      entityId: payload.id,
      action: `create-${payload.type}`.toLowerCase(),
      values: payload
    })
  },
  async shareFile({ dispatch }, payload) {
    if (payload.usersIDs) {
      await firebase
        .firestore()
        .collection('files')
        .doc(payload.fileId)
        .update({
          readAccess: firebase.firestore.FieldValue.arrayUnion(
            ...payload.usersIDs
          )
        })
      store.dispatch('auditLogStore/log', {
        ref: `files/${payload.fileId}`,
        entityName: 'files',
        entityId: payload.fileId,
        action: 'internal-share-file',
        values: payload
      })
    }
    await dispatch('shareInEmail', payload)
  },
  async shareInEmail(context, payload) {
    await firebase
      .firestore()
      .collection('email')
      .add({
        to: payload.users,
        template: {
          name: 'shareFile',
          data: {
            username: store.getters['authStore/currentUser'].name,
            emailAddress: store.getters['authStore/currentUser'].emailAddress,
            fileName: payload.name
          }
        },
        message: {
          attachments: [{ path: payload.fileUrl, filename: payload.name }]
        }
      })
    store.dispatch('auditLogStore/log', {
      ref: `files/${payload.fileId}`,
      entityName: 'files',
      entityId: payload.fileId,
      action: 'external-share-file',
      values: payload
    })
  },
  async removeAccess(context, payload) {
    await firebase
      .firestore()
      .collection('files')
      .doc(payload.fileId)
      .update({
        readAccess: firebase.firestore.FieldValue.arrayRemove(
          ...payload.readAccess
        ),
        downloadAccess: firebase.firestore.FieldValue.arrayRemove(
          ...payload.downloadAccess
        ),
        printAccess: firebase.firestore.FieldValue.arrayRemove(
          ...payload.printAccess
        )
      })
  },
  async doDestroyFolder({ commit }, { id, url }) {
    try {
      commit('DESTROY_STARTED')
      commit('DELETE_FROM_ROWS', id)
      const data = {
        folderId: id,
        folderUrl: url
      }
      const response = await axios.post(`${backendUrl}/delete-folder`, {
        data: JSON.stringify(data)
      })
      commit('DESTROY_SUCCESS')
    } catch (error) {
      console.log(error)
      commit('DESTROY_ERROR')
    }
  },
  async doDestroyAll({ commit }, { files, baseUrl }) {
    try {
      commit('DESTROY_STARTED')
      const data = {
        baseUrl: baseUrl,
        files: files
      }

      const response = await axios.post(`${backendUrl}/delete-all`, {
        data: JSON.stringify(data)
      })
      files.forEach(file => {
        commit('DELETE_FROM_ROWS', file.id)
        store.dispatch('auditLogStore/log', {
          ref: `files/${file.id}`,
          entityName: 'files',
          entityId: file.id,
          action: 'delete',
          values: file
        })
      })
      commit('DESTROY_SUCCESS')
    } catch (error) {
      console.log(error)
      commit('DESTROY_ERROR')
    }
  },
  async doRenameFile({ commit }, { id, newName }) {
    firebase
      .firestore()
      .collection('files')
      .doc(id)
      .update({
        name: newName
      })
  },
  async doUploadFiles({ commit, dispatch }, { files, path, location }) {
    try {
      commit('UPLOAD_STARTED')
      const LISTENER_ID = FirebaseRepository.newId()
      await firebase
        .firestore()
        .collection('--sharedInfo--')
        .doc(LISTENER_ID)
        .set({
          id: LISTENER_ID,
          uploadFilesCount: files.length,
          total: files.length
        })

      dispatch('doTurnOnProgressUploader', {
        listenerId: LISTENER_ID,
        path: path
      })
      files.forEach(file => {
        FileService.uploadFile(file, path, location, LISTENER_ID)
      })
      commit('UPLOAD_SUCCESS')
    } catch (error) {
      console.log(error)
      commit('UPLOAD_ERROR')
    }
  },
  async doCreateFolder({ commit, dispatch }, { folderName, path, location }) {
    try {
      commit('UPLOAD_STARTED')
      await FileService.createFolder(folderName, path, location)
      await dispatch('doFetchFirestore')
      await dispatch('doFetch', { path })
      commit('UPLOAD_SUCCESS')
    } catch (error) {
      console.log(error)
      commit('UPLOAD_ERROR')
    }
  },
  doTurnOnProgressUploader({ commit, dispatch }, { listenerId, path }) {
    const db = firebase.firestore()
    const unsubscribe = onSnapshot(
      doc(db, '--sharedInfo--', listenerId),
      doc => {
        const progress = doc.data()
        const total = progress && progress.total ? progress.total : 0
        const count =
          progress && progress.uploadFilesCount ? progress.uploadFilesCount : 0
        commit('UPDATE_PROGRESS_UPLOADER_COUNT', { count, total })
        if (count === 0 && total > 0) {
          firebase
            .firestore()
            .collection('--sharedInfo--')
            .doc(listenerId)
            .update({
              total: 0
            })
        }
        if (count == 0 && total == 0) {
          setTimeout(async () => {
            unsubscribe() // Stop listening to changes
            firebase
              .firestore()
              .collection('--sharedInfo--')
              .doc(listenerId)
              .delete()
            await dispatch('doFetchFirestore')
            await dispatch('doFetch', { path })
          }, 500)
        }
      }
    )
  }
}

function getFileType(name) {
  const images = ['png', 'jpg', 'jpeg', 'webp', 'psd']
  const extension = name.split('.').pop()
  if (images.includes(extension)) {
    return 'image'
  } else {
    return extension
  }
}
function getCompanyNumber(path) {
  const company = path.split('/')[1]
  return company.substr(company.indexOf('?:') + 2, 2)
}
function getDepartmentNumber(path) {
  const pathArray = path.split('/')
  if (pathArray.length > 2) {
    const department = pathArray[2]
    return department.substr(department.indexOf('?:') + 2, 2)
  } else {
    return '00'
  }
}
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}

<template>
  <div>
    <div class="grid grid-cols-12 gap-6 mt-8">
      <div class="col-span-12 lg:col-span-3 2xl:col-span-2">
        <h2 class="intro-y text-lg font-medium mr-auto mt-2">
          {{ i18n('fileManager.fileManager') }}
        </h2>
        <!-- {{ files }}
        <div>-----------------------------</div>
        {{ filesComputed }} -->
        <!-- <div id="printData" style="visibility:">
          <vue-barcode value="Hello, World!" :options="{ displayValue: true }"></vue-barcode>
        </div> -->
        <!-- BEGIN: File Manager Menu -->
        <div class="intro-y box p-5 mt-6">
          <div class="mt-1">
            <a
              class="flex items-center px-3 py-2 rounded-md cursor-pointer"
              :class="
                this.tab == 'All' ? 'bg-theme-1 text-white font-medium' : ''
              "
              @click="filterFiles('All')"
            >
              <ListIcon class="w-4 h-4 mx-2" />
              {{ i18n('fileManager.filter.all') }}
            </a>
            <a
              class="flex items-center px-3 py-2 mt-2 rounded-md cursor-pointer"
              :class="
                this.tab == 'folder' ? 'bg-theme-1 text-white font-medium' : ''
              "
              @click="filterFiles('folder')"
            >
              <FolderIcon class="w-4 h-4 mx-2" />
              {{ i18n('fileManager.filter.folders') }}
            </a>
            <a
              class="flex items-center px-3 py-2 mt-2 rounded-md cursor-pointer"
              :class="
                this.tab == 'image' ? 'bg-theme-1 text-white font-medium' : ''
              "
              @click="filterFiles('image')"
            >
              <ImageIcon class="w-4 h-4 mx-2" />
              {{ i18n('fileManager.filter.images') }}
            </a>
            <a
              class="flex items-center px-3 py-2 mt-2 rounded-md cursor-pointer"
              :class="
                this.tab == 'docx' ? 'bg-theme-1 text-white font-medium' : ''
              "
              @click="filterFiles('docx')"
            >
              <FileIcon class="w-4 h-4 mx-2" />
              {{ i18n('fileManager.filter.textFiles') }}
            </a>
            <a
              class="flex items-center px-3 py-2 mt-2 rounded-md cursor-pointer"
              :class="
                this.tab == 'pdf' ? 'bg-theme-1 text-white font-medium' : ''
              "
              @click="filterFiles('pdf')"
            >
              <FileIcon class="w-4 h-4 mx-2" />
              {{ i18n('fileManager.filter.pdfFiles') }}
            </a>
            <a
              class="flex items-center px-3 py-2 mt-2 rounded-md cursor-pointer"
              :class="
                this.tab == 'xlsx' ? 'bg-theme-1 text-white font-medium' : ''
              "
              @click="filterFiles('xlsx')"
            >
              <FileIcon class="w-4 h-4 mx-2" />
              {{ i18n('fileManager.filter.excelSheets') }}
            </a>
            <a
              class="flex items-center px-3 py-2 mt-2 rounded-md cursor-pointer"
              :class="
                this.tab == 'Trash' ? 'bg-theme-1 text-white font-medium' : ''
              "
              @click="filterFiles('Trash')"
            >
              <TrashIcon class="w-4 h-4 mx-2" />
              {{ i18n('fileManager.filter.trash') }}
            </a>
          </div>
        </div>
        <!-- END: File Manager Menu -->

        <canvas id="mycanvas" style="position:absolute; z-index: -1"> </canvas>
        <canvas
          id="printCanvas"
          height="250"
          width="700"
          style="position:absolute; z-index: -1"
        >
        </canvas>
      </div>
      <div class="col-span-12 lg:col-span-9 2xl:col-span-10 ">
        <!-- BEGIN: File Manager Filter -->
        <div class="intro-y flex flex-col-reverse xl:flex-row items-center ">
          <div
            class="w-full sm:w-auto relative mt-3  "
            :class="
              isRTL
                ? 'mx-0 lg:mx-0 sm:mx-auto xl:ml-auto'
                : 'mx-0 sm:mx-auto lg:mx-0 xl:mr-auto'
            "
          >
            <SearchIcon
              class="w-4 absolute mt-2 inset-y-0 z-10 text-gray-700 dark:text-gray-300"
              :class="isRTL ? 'mr-3 right-0' : 'ml-3 left-0'"
            />
            <input
              type="text"
              class="form-control w-full sm:w-64 box px-10 text-gray-700 dark:text-gray-300 placeholder-theme-13"
              :placeholder="i18n('fileManager.search.placeholder')"
              v-model="search"
            />
            <button
              id="tabulator-html-filter-go"
              type="button"
              style="background-color:#d5aa0f"
              class="btn text-white bg-theme-39 w-full sm:w-16 sm:mr-3 sm:ml-3 mt-2 sm:mt-0"
              @click="onSearch()"
            >
              {{ i18n('fileManager.search.go') }}
            </button>
            <button
              id="tabulator-html-filter-reset"
              type="button"
              class="btn btn-secondary dark:text-black w-full sm:w-24 mt-2 sm:mt-0 sm:ml-1 sm:mr-1 "
              style="background-color:#edf2f7"
              @click="onResetFilter()"
            >
              {{ i18n('fileManager.search.reset') }}
            </button>
          </div>
          <div class="w-full sm:w-auto flex items-center">
            <a :class="isRTL ? 'ml-4' : 'mr-4'">
              <RefreshCcwIcon
                class="cursor-pointer"
                @click="navigateChevron(chevronPath[chevronPath.length - 1])"
              />
            </a>
            <a
              href="javascript:;"
              data-toggle="modal"
              data-target="#upload-modal-preview"
              @click="uploadFilesModal = true"
              class="btn bg-theme-1 mx-1 mb-2 text-white xl:truncate"
              style="background-color:#000"
              type="button"
              v-if="tab != 'Trash' && !chevronPath.includes('Shared with me')"
            >
              <PlusIcon />
              {{ i18n('fileManager.uploadButton') }}
            </a>
            <a
              href="javascript:;"
              data-toggle="modal"
              data-target="#create-folder-modal-preview"
              class="btn bg-theme-1 mx-1 mb-2 text-white truncate "
              style="background-color:#000"
              type="button"
              v-if="tab != 'Trash' && !chevronPath.includes('Shared with me')"
            >
              <PlusIcon />
              {{ i18n('fileManager.createFolderButton') }}
            </a>
          </div>
        </div>
        <!-- END: File Manager Filter -->
        <!-- BEGIN: Directory & Files -->
        <div
          style="width:100%; height:80vh;"
          class="flex justify-center"
          v-if="loading"
        >
          <LoadingIcon icon="grid" class="" style="width:100px" />
        </div>
        <div class="flex mt-5" v-if="!loading">
          <div v-for="path in chevronPath" :key="path" class="col">
            <ChevronRightIcon class="breadcrumb__icon" v-if="!isRTL" />
            <ChevronLeftIcon class="breadcrumb__icon" v-else />
            <a @click="navigateChevron(path)" class="cursor-pointer">{{
              path
            }}</a>
          </div>
        </div>
        <ArrowLeftIcon
          @click="navigateBack()"
          v-if="!homePath && !loading && tab != 'Trash' && !isRTL"
          class="mt-10 cursor-pointer"
        />
        <ArrowRightIcon
          @click="navigateBack()"
          class="mt-10 cursor-pointer"
          v-if="!homePath && !loading && tab != 'Trash' && isRTL"
        />
        <div
          v-if="tab !== 'Trash' && !loading"
          class="flex justify-center sm:justify-end"
        >
          <div class="flex items-center mx-2 mt-2 sm:mt-0">
            <input
              id="checkbox-select-all2"
              name="checkbox-select-all2"
              class="form-check-input border border-gray mx-3 cursor-pointer"
              style="border-color: #d5aa0f"
              type="checkbox"
              v-model="selectAllCheckbox"
              :disabled="destroyLoading || files.length == 0"
              @change="onSelectAllCheckboxChange()"
            />
            <label for="checkbox-select-all2" class="cursor-pointer">
              {{
                selectAllCheckbox
                  ? i18n('common.unselectAll')
                  : i18n('common.selectAll')
              }}
            </label>
          </div>
          <button
            id="delete-all"
            type="button"
            class="btn text-white btn-danger sm:w-32 sm:mr-3 sm:ml-3 mt-2 sm:mt-0 cursor-pointer"
            :disabled="
              files.filter(file => file.checkbox).length === 0 || destroyLoading
            "
            @click="
              doDeleteAll(files.filter(file => file.checkbox)),
                (selectAllCheckbox = false)
            "
            style="background-color:red"
          >
            <span class="material-icons-outlined mx-2">delete_forever</span>
            {{ i18n('common.delete') }}
          </button>
        </div>
        <div
          class="flex justify-center"
          v-if="tab == 'Trash' && filesComputed.length > 0 && !loading"
        >
          <h1 class="text-2xl">
            {{ i18n('fileManager.filesDeletedAfterThirtyDays') }}
          </h1>
        </div>
        <div
          class="py-5"
          v-if="tab !== 'Trash' && !loading && progressUploaderCount > 0"
        >
          <div class="w-full bg-gray-200 rounded-full">
            <div
              class="ease-in duration-500 bg-theme-39 text-xs font-medium text-blue-100 text-center p-0.5 leading-none rounded-l-full"
              :style="`width: ${progress}%`"
            >
              {{ progress }}%
            </div>
          </div>
        </div>
        <div
          class="intro-y grid grid-cols-12 gap-3 sm:gap-6 mt-5"
          style="position: relative;"
          v-if="!loading"
        >
          <loading-spinner
            type="half-circle"
            :duration="1000"
            :size="40"
            color="#4469A8"
            :loading="destroyLoading"
          />
          <div
            v-for="(file, key) in filesComputed"
            :key="key"
            class="intro-y col-span-6 sm:col-span-4 md:col-span-3 2xl:col-span-2"
          >
            <div
              class="file box rounded-md px-5 pt-8 pb-5 px-3 sm:px-5 relative zoom-in"
              @click="openeFile(file)"
            >
              <!-- <div class="absolute top-0 mt-2" :class="isRTL ? 'left-50 mr-10' : 'left-50 ml-6'">
                <input class="form-check-input border border-gray-500" type="checkbox" v-model="file.checkbox" />
              </div> -->
              <a
                v-if="file.type == 'folder'"
                class="w-3/5 file__icon file__icon--directory mx-auto"
              ></a>
              <a
                v-else-if="file.type == 'image'"
                class="w-3/5 file__icon file__icon--image mx-auto"
              >
                <div class="file__icon--image__preview image-fit">
                  <img :src="file.url" :alt="file.name" />
                </div>
              </a>
              <a v-else class="w-3/5 file__icon file__icon--file mx-auto">
                <div class="file__icon__file-name">{{ file.type }}</div>
              </a>
              <Tippy :content="file.name">
                <a class="block font-medium mt-4 text-center truncate">{{
                  file.name
                }}</a>
              </Tippy>
              <div class="text-gray-600 text-xs text-center mt-0.5">
                {{ file.size }}
              </div>
            </div>
            <div
              class="absolute top-0 left-0 mt-2 ml-3"
              v-if="file.name !== 'Shared with me'"
            >
              <input
                class="form-check-input border border-gray-500"
                type="checkbox"
                v-model="file.checkbox"
              />
            </div>
            <div class="absolute top-0 right-0 mx-2 mt-2 dropdown ml-auto">
              <a
                class="dropdown-toggle w-5 h-5 block"
                href="javascript:;"
                aria-expanded="false"
                id="item-dropdown-toggle"
                v-if="
                  (hasDeleteAccess(file) ||
                    hasDownloadAccess(file) ||
                    hasPrintAccess(file) ||
                    hasShareAccess(file) ||
                    currentUser.roles.includes('Admin')) &&
                    file.id != 'zAU0TIZ9xDp3Zra4iH67' &&
                    file.type != 'folder'
                "
              >
                <MoreVerticalIcon class="w-5 h-5 text-gray-600" />
              </a>
              <div class="dropdown-menu w-40">
                <div class="dropdown-menu__content box dark:bg-dark-1 p-2">
                  <a
                    class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2  rounded-md"
                    @click="downloadFile(file)"
                    v-if="
                      file.type != 'folder' &&
                        tab != 'Trash' &&
                        (hasDownloadAccess(file) ||
                          currentUser.roles.includes('Admin'))
                    "
                  >
                    <DownloadIcon class="w-4 h-4 mx-2" />
                    {{ i18n('fileManager.download') }}
                  </a>
                  <a
                    class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2  rounded-md"
                    @click="print(file)"
                    v-if="
                      file.type != 'folder' &&
                        tab != 'Trash' &&
                        (hasPrintAccess(file) ||
                          currentUser.roles.includes('Admin'))
                    "
                  >
                    <PrinterIcon class="w-4 h-4 mx-2" />
                    {{ i18n('fileManager.print') }}
                  </a>
                  <a
                    @click="sharedFile = file"
                    href="javascript:;"
                    data-toggle="modal"
                    data-target="#rename-file-modal"
                    type="button"
                    class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                    v-if="file.type != 'folder' && tab != 'Trash'"
                  >
                    <!-- <span class="material-icons w-4 h-4 mx-2">drive_file_rename_outline</span> -->
                    <span class="material-icons-outlined w-4 h-4 mx-2"
                      >drive_file_rename_outline</span
                    >
                    {{ i18n('common.rename') }}
                  </a>
                  <a
                    class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2  rounded-md"
                    href="javascript:;"
                    data-toggle="modal"
                    data-target="#share-file-modal-preview"
                    type="button"
                    v-if="
                      tab != 'Trash' &&
                        file.type != 'folder' &&
                        (hasShareAccess(file) ||
                          currentUser.roles.includes('Admin'))
                    "
                    @click="sharedFile = file"
                  >
                    <Share2Icon class="w-4 h-4 mx-2" />
                    {{ i18n('fileManager.share') }}
                  </a>
                  <a
                    class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2  rounded-md"
                    href="javascript:;"
                    data-toggle="modal"
                    data-target="#share-download-modal-preview"
                    type="button"
                    v-if="
                      tab != 'Trash' &&
                        file.type != 'folder' &&
                        hasShareAccess(file) &&
                        (currentUser.roles.includes('Share') ||
                          currentUser.roles.includes('Admin'))
                    "
                    @click="sharedFile = file"
                  >
                    <Share2Icon class="w-4 h-4 mx-2" />
                    {{ i18n('fileManager.shareDownloadAccess') }}
                  </a>
                  <a
                    class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2  rounded-md"
                    href="javascript:;"
                    data-toggle="modal"
                    data-target="#share-print-modal-preview"
                    type="button"
                    v-if="
                      tab != 'Trash' &&
                        file.type != 'folder' &&
                        hasShareAccess(file) &&
                        (currentUser.roles.includes('Share') ||
                          currentUser.roles.includes('Admin'))
                    "
                    @click="sharedFile = file"
                  >
                    <Share2Icon class="w-4 h-4 mx-2" />
                    {{ i18n('fileManager.sharePrintAccess') }}
                  </a>
                  <a
                    class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2  rounded-md"
                    href="javascript:;"
                    data-toggle="modal"
                    data-target="#remove-access-modal"
                    type="button"
                    v-if="
                      tab != 'Trash' &&
                        file.type != 'folder' &&
                        hasShareAccess(file) &&
                        (currentUser.roles.includes('Share') ||
                          currentUser.roles.includes('Admin'))
                    "
                    @click="sharedFile = file"
                  >
                    <TrashIcon class="w-4 h-4 mx-2" />
                    {{ i18n('common.removeAccess') }}
                  </a>
                  <a
                    @click="sharedFile = file"
                    data-toggle="modal"
                    data-target="#delete-modal-preview"
                    id="disableModal"
                    class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2  rounded-md"
                    v-if="
                      hasDeleteAccess(file) &&
                        currentUser.roles.includes('Admin')
                    "
                  >
                    <TrashIcon class="w-4 h-4 mx-2" />
                    {{ i18n('fileManager.delete') }}
                  </a>
                  <a
                    class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2  rounded-md"
                    @click="restoreFile(file)"
                    v-if="
                      (hasDeleteAccess(file) ||
                        currentUser.roles.includes('Admin')) &&
                        tab == 'Trash'
                    "
                  >
                    <CornerLeftUpIcon class="w-4 h-4 mx-2" />
                    {{ i18n('fileManager.restore') }}
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div
            v-if="filesComputed.length == 0"
            class="col-span-12 items-center flex justify-center"
            style="height: 300px;"
          >
            <div>
              <div class="flex justify-center">
                <FileTextIcon style="height:125px; width: 125px;" />
              </div>
              <h3 class="font-medium text-3xl mt-5">
                {{ i18n('fileManager.noFiles') }}
              </h3>
            </div>
          </div>
        </div>
        <!-- END: Directory & Files -->
        <!-- BEGIN: Pagination -->
        <!-- END: Pagination -->
      </div>
      <FsLightbox
        :toggler="toggler"
        :sources="[source]"
        :key="source"
        type="image"
        :types="['image']"
      />
      <!-- <DeleteModal item="file" :showModal="true" /> -->
      <DeleteModal
        :showModal="sharedFile"
        :item="sharedFile && sharedFile.type === 'folder' ? 'folder' : 'file'"
        @delete="deleteOrMove(sharedFile)"
      />
      <UploadFilesModal
        :showModal="uploadFilesModal"
        :destination="path"
        :serverPath="serverPath"
        :currentUser="currentUser"
        @close="uploadFilesModal = false"
      />
      <CreateFolderModal
        :destination="path"
        :serverPath="serverPath"
        :showCreateFolderModal="true"
        @close="() => ''"
      />
      <RemoveAccessModal :showModal="sharedFile" :file="sharedFile" />
      <ShareFileModal
        :showModal="sharedFile"
        :file="sharedFile"
        :selectedValues="selectedValues"
      />
      <ShareDownloadAccess :showModal="sharedFile" :file="sharedFile" />
      <SharePrintAccess :showModal="sharedFile" :file="sharedFile" />
      <PDFViewer :showPDFModal="true" :path="path" :url="openedPDF" />
      <RenameFileModal
        :showModal="!!sharedFile"
        :file="sharedFile"
        @doSubmit="doRename"
      />
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import { mapActions, mapGetters } from 'vuex'
import UploadFilesModal from './upload-files.vue'
import DeleteModal from '@/components/modals/delete-modal.vue'
import CreateFolderModal from './create-folder.vue'
import RenameFileModal from '../data-center/rename-file.vue'
import ShareFileModal from './share-file.vue'
import ShareDownloadAccess from './share-download-access.vue'
import SharePrintAccess from './share-print-access.vue'
import RemoveAccessModal from './remove-access.vue'
import PDFViewer from './pdf-viewer.vue'
import FsLightbox from 'fslightbox-vue/v3'
import * as Printjs from 'print-js'
import AuthService from '../../auth/auth-service'
import config from '@/config'
const editorUrl = config.editorUrl

export default defineComponent({
  components: {
    FsLightbox,
    UploadFilesModal,
    DeleteModal,
    RenameFileModal,
    CreateFolderModal,
    PDFViewer,
    ShareFileModal,
    ShareDownloadAccess,
    SharePrintAccess,
    RemoveAccessModal
  },

  data() {
    return {
      uploadFilesModal: false,
      checkbox: false,
      search: '',
      type: '',
      toggler: false,
      source: '',
      tab: 'All',
      files: [],
      path: 'Share',
      serverPath: 'Share',
      openedPDF: '',
      sharedFile: null,
      chevronPath: ['Share'],
      filesInFirestoreLength: 0,
      selectedValues: []
    }
  },
  computed: {
    ...mapGetters({
      currentUser: 'authStore/currentUser',
      rows: 'shareStore/rows',
      loading: 'shareStore/loading',
      filesInFirestore: 'shareStore/filesInFirestore',
      users: 'usersStore/rows',
      destroyLoading: 'shareStore/destroyLoading',
      progressUploaderCount: 'shareStore/progressUploaderCount',
      totalProgressCount: 'shareStore/totalProgressCount'
    }),
    isRTL() {
      return this.$i18n.locale == 'ar'
    },
    language() {
      return this.$i18n.lcoale
    },
    filesComputed() {
      return this.files.filter(element => {
        return this.hasReadAccess(element)
      })
    },
    filesToDelete() {
      return this.files.filter(file => {
        return file.checkbox == true
      })
    },
    homePath() {
      return this.path == this.serverPath
    },
    progress() {
      return this.totalProgressCount > 0
        ? Math.round(
            100 - (100 / this.totalProgressCount) * this.progressUploaderCount
          )
        : 0
    }
  },
  async created() {
    await this.doFetchFirestore()
    await this.doFetch(this.path)
    await this.fetchUsers()
  },
  methods: {
    ...mapActions({
      getCurrentUser: 'authStore/getCurrentUser',
      fetchUsers: 'usersStore/doFetch',
      saveCurrentUser: 'authStore/saveCurrentUser',
      doFetch: 'shareStore/doFetch',
      doDeleteFile: 'shareStore/doDeleteFile',
      doMoveFile: 'shareStore/doMoveFile',
      doFetchFirestore: 'shareStore/doFetchFirestore',
      doFetchSharedFiles: 'shareStore/doFetchSharedFiles',
      doRestore: 'shareStore/doRestore',
      doEmptyTrash: 'shareStore/doEmptyTrash',
      doDestroyFolder: 'shareStore/doDestroyFolder',
      doDestroyAll: 'shareStore/doDestroyAll',
      doRenameFile: 'shareStore/doRenameFile'
    }),
    i18n(key, args) {
      return this.$t(key, args)
    },
    print(file) {
      Printjs({
        printable: file.url,
        type: file.type,
        showModal: true,
        modalMessage: 'Loading...'
      })
    },
    // Searching
    onSearch() {
      if (this.search != '') {
        const file = this.files.filter(file => {
          return file.name.toLowerCase().includes(this.search.toLowerCase())
        })
        this.files = JSON.parse(JSON.stringify(file))
      } else {
        this.doFetch(this.path)
      }
    },
    async fetchFiles() {
      setTimeout(async () => {
        await this.doFetchFirestore()
        await this.doFetch(this.path)
      }, 1000)
    },
    onResetFilter() {
      this.search = ''
      this.onSearch()
    },
    // Delete or Move
    async restoreFile(file) {
      document.getElementById('item-dropdown-toggle').click()
      const fileInFirestore = this.filesInFirestore.filter(el => {
        return el.id == file.id
      })[0]
      await this.doRestore({ file: file, fileInFirestore: fileInFirestore })
      await this.doFetch(this.path + '/trash')
    },
    async deleteOrMove(file) {
      document.getElementById('item-dropdown-toggle').click()
      if (file.type === 'folder') {
        this.doDestroyFolder({
          id: file.id,
          url: file.url
        })
      } else {
        this.tab == 'Trash'
          ? await this.deleteFile(file)
          : await this.moveFile(file)
      }
    },
    async deleteFile(file) {
      await this.doDeleteFile({ file: file, path: this.path })
    },
    async moveFile(file) {
      await this.doMoveFile({ file: file, path: this.path })
    },
    deleteOrMoveManyFiles() {
      if (this.tab == 'Trash') this.deleteManyFiles()
      else this.moveManyFiles()
    },
    moveManyFiles() {
      this.filesToDelete.forEach(file => {
        this.moveFile(file)
      })
    },
    deleteManyFiles() {
      this.filesToDelete.forEach(file => {
        this.deleteFile(file)
      })
    },
    // Download
    async downloadFile(file) {
      const data = await fetch(file.url)
      const blob = await data.blob()
      const url = URL.createObjectURL(blob)
      const anchor = document.createElement('a')
      anchor.href = url
      anchor.download = file.name
      document.body.appendChild(anchor)
      anchor.click()
      document.body.removeChild(anchor)
    },
    async filterFiles(tab) {
      this.tab = tab
      if (!this.chevronPath.includes('Shared with me')) {
        if (tab == 'Trash') {
          await this.doFetch(this.path + '/trash')
        } else if (tab == 'All') {
          await this.doFetch(this.path)
        } else {
          await this.doFetch(this.path)
          this.files = this.files.filter(el => {
            return el.type == tab
          })
        }
      } else {
        if (tab == 'Trash') {
        } else if (tab == 'All') {
          await this.doFetchSharedFiles(this.currentUser)
          this.files.forEach(element => {
            element.type = this.getFileType(element.name)
            element.url = element.publicUrl
          })
        } else {
          await this.doFetchSharedFiles(this.currentUser)
          this.files.forEach(element => {
            element.type = this.getFileType(element.name)
            element.url = element.publicUrl
          })
          this.files = this.files.filter(el => {
            return el.type == tab
          })
        }
      }
    },
    // Navigate
    async navigate(file) {
      if (this.tab == 'Trash') {
        this.path += '/' + file.name
        this.doFetch(this.path + '/trash')
      } else {
        this.path = file.url || file?.fullPath
        this.chevronPath.push(file.name)
        if (file.name == 'Shared with me') {
          await this.doFetchSharedFiles(this.currentUser)
          this.files.forEach(element => {
            element.url = element.publicUrl
            if (element.type != 'Folder') {
              element.type = this.getFileType(element.name)
            } else {
              element.type = 'folder'
              element.url = `${this.path}/${element.name}`
              element.name = element.name.substr(element.name.indexOf('?:') + 2)
            }
          })
        } else {
          this.doFetch(this.path)
        }
      }
    },
    async navigateChevron(path) {
      for (let index = this.chevronPath.length - 1; index >= 0; index--) {
        const element = this.chevronPath[index]
        if (element != path) {
          this.path = this.path.substring(0, this.path.lastIndexOf('/'))
          this.chevronPath.pop()
        } else {
          await this.doFetch(this.path)
          return
        }
      }
    },
    navigateBack() {
      if (this.tab == 'Trash') {
        this.trashPath = this.trashPath.substring(
          0,
          this.trashPath.lastIndexOf('/')
        )
        this.doFetch(this.trashPath)
      } else {
        this.path = this.path.substring(0, this.path.lastIndexOf('/'))
        this.doFetch(this.path)
        this.chevronPath.pop()
      }
    },
    // Verify Permessions
    hasReadAccess(file) {
      if (
        file.id == 'zAU0TIZ9xDp3Zra4iH67' &&
        !this.chevronPath.includes('Shared with me')
      ) {
        return true
      }
      let fileInFirestore = this.filesInFirestore.filter(el => {
        return el.id == file.id
      })
      if (fileInFirestore.length > 0) fileInFirestore = fileInFirestore[0]
      if (this.chevronPath.includes('Shared with me')) {
        if (
          fileInFirestore.readAccess.includes(this.currentUser.id) &&
          fileInFirestore.createdBy != this.currentUser.id
        ) {
          return true
        } else {
          return false
        }
      } else {
        if (fileInFirestore.createdBy == this.currentUser.id) {
          return true
        } else {
          return false
        }
      }
    },
    hasDownloadAccess(file) {
      if (file.id == 'zAU0TIZ9xDp3Zra4iH67') return false
      const fileInFirestore = this.filesInFirestore.filter(el => {
        return el.id == file.id
      })[0]
      if (fileInFirestore.downloadAccess.includes(this.currentUser.id)) {
        return true
      } else {
        return false
      }
    },
    hasPrintAccess(file) {
      if (file.id == 'zAU0TIZ9xDp3Zra4iH67') return false
      const fileInFirestore = this.filesInFirestore.filter(el => {
        return el.id == file.id
      })[0]
      if (fileInFirestore.printAccess.includes(this.currentUser.id)) {
        return true
      } else {
        return false
      }
    },
    hasDeleteAccess(file) {
      if (file.id == 'zAU0TIZ9xDp3Zra4iH67') return false
      const fileInFirestore = this.filesInFirestore.filter(el => {
        return el.id == file.id
      })[0]
      if (fileInFirestore.deleteAccess.includes(this.currentUser.id)) {
        return true
      } else {
        return false
      }
    },
    hasShareAccess(file) {
      if (file.id == 'zAU0TIZ9xDp3Zra4iH67') return false
      const fileInFirestore = this.filesInFirestore.filter(el => {
        return el.id == file.id
      })[0]
      if (fileInFirestore.shareAccess.includes(this.currentUser.id)) {
        return true
      } else {
        return false
      }
    },
    async doDeleteAll(files) {
      await this.doDestroyAll({
        baseUrl: this.path,
        files: files
      })
    },
    doRename({ fileId, newName }) {
      this.doRenameFile({ id: fileId, newName: newName })
      const index = this.files.findIndex(file => file.id == fileId)
      this.files[index].name = newName
    },
    incompleteUploadFiles() {
      if (this.progressUploaderCount > 0) {
        return true
      } else {
        setTimeout(() => {
          return false
        }, 1000)
      }
    },
    onSelectAllCheckboxChange() {
      for (let index = 0; index < this.files.length; index++) {
        if (this.files[index].name !== 'Shared with me') {
          this.files[index].checkbox = this.selectAllCheckbox
        }
      }
    },
    getFileType(name) {
      const images = ['png', 'jpg', 'jpeg', 'webp', 'psd', 'svg']
      const extension = name.split('.').pop()
      if (images.includes(extension)) {
        return 'image'
      } else {
        return extension
      }
    },
    openeFile(file) {
      switch (file.type) {
        case 'docx':
        case 'doc':
          this.openWord(file)
          break
        case 'xlsx':
          this.openExcel(file)
          break
        case 'pdf':
          this.openPDF(file)
          break
        case 'image':
          this.openFsLightBox(file.url)
          break
        case 'folder':
          this.navigate(file)
          break
        case 'Folder':
          this.navigate(file)
          break
      }
    },
    openPDF(file) {
      this.openedPDF = file.url
      cash('#view-pdf-modal-preview').modal('show')
    },
    async openExcel(file) {
      // const link = `/excel/${file.id}`
      // const token = await AuthToken.get()
      const token = await AuthService.createCustomToken()
      const link = `${editorUrl}/${this.language}/excel/${file.id}?tokenId=${token}`
      const anchor = document.createElement('a')
      anchor.href = link
      anchor.target = '_blank'
      document.body.appendChild(anchor)
      anchor.click()
    },
    async openWord(file) {
      const link = `/${this.language}/word/${file.id}`
      const anchor = document.createElement('a')
      anchor.href = link
      anchor.target = '_blank'
      document.body.appendChild(anchor)
      anchor.click()
    },
    openFsLightBox(source) {
      this.source = source
      setTimeout(() => {
        this.toggler = !this.toggler
      }, 1)
    }
  },
  watch: {
    rows: {
      handler: function(newval) {
        this.files = newval.sort((a, b) =>
          a.name.toLowerCase().localeCompare(b.name.toLowerCase(), undefined, {
            numeric: true,
            sensitivity: 'base'
          })
        )
      },
      deep: true
    }
  }
})
</script>

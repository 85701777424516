<template>
  <div>
    <div class="grid grid-cols-12 gap-6 mt-8">
      <div class="col-span-12 xl:col-span-3 2xl:col-span-2">
        <h2 class="intro-y text-lg font-medium mr-auto mt-2">
          {{ i18n('fileManager.fileManager') }}
        </h2>
        <!-- BEGIN: File Manager Menu -->
        <div class="intro-y box p-5 mt-6">
          <div class="mt-1">
            <a
              class="flex items-center px-3 py-2 rounded-md cursor-pointer"
              :class="
                this.tab == 'All' ? 'bg-theme-1 text-white font-medium' : ''
              "
              @click="filterFiles('All')"
            >
              <ListIcon class="w-4 h-4 mx-2" />
              {{ i18n('fileManager.filter.all') }}
            </a>
            <a
              class="flex items-center px-3 py-2 mt-2 rounded-md cursor-pointer"
              :class="
                this.tab == 'folder' ? 'bg-theme-1 text-white font-medium' : ''
              "
              @click="filterFiles('folder')"
            >
              <FolderIcon class="w-4 h-4 mx-2" />
              {{ i18n('fileManager.filter.folders') }}
            </a>
            <a
              class="flex items-center px-3 py-2 mt-2 rounded-md cursor-pointer"
              :class="
                this.tab == 'image' ? 'bg-theme-1 text-white font-medium' : ''
              "
              @click="filterFiles('image')"
            >
              <ImageIcon class="w-4 h-4 mx-2" />
              {{ i18n('fileManager.filter.images') }}
            </a>
            <a
              class="flex items-center px-3 py-2 mt-2 rounded-md cursor-pointer"
              :class="
                this.tab == 'docx' ? 'bg-theme-1 text-white font-medium' : ''
              "
              @click="filterFiles('docx')"
            >
              <FileIcon class="w-4 h-4 mx-2" />
              {{ i18n('fileManager.filter.textFiles') }}
            </a>
            <a
              class="flex items-center px-3 py-2 mt-2 rounded-md cursor-pointer"
              :class="
                this.tab == 'pdf' ? 'bg-theme-1 text-white font-medium' : ''
              "
              @click="filterFiles('pdf')"
            >
              <FileIcon class="w-4 h-4 mx-2" />
              {{ i18n('fileManager.filter.pdfFiles') }}
            </a>
            <a
              class="flex items-center px-3 py-2 mt-2 rounded-md cursor-pointer"
              :class="
                this.tab == 'xlsx' ? 'bg-theme-1 text-white font-medium' : ''
              "
              @click="filterFiles('xlsx')"
            >
              <FileIcon class="w-4 h-4 mx-2" />
              {{ i18n('fileManager.filter.excelSheets') }}
            </a>
            <a
              class="flex items-center px-3 py-2 mt-2 rounded-md cursor-pointer"
              :class="
                this.tab == 'Trash' ? 'bg-theme-1 text-white font-medium' : ''
              "
              @click="filterFiles('Trash')"
            >
              <TrashIcon class="w-4 h-4 mx-2" />
              {{ i18n('fileManager.filter.trash') }}
            </a>
          </div>
        </div>
        <!-- END: File Manager Menu -->
        <canvas
          id="mycanvas"
          style="direction: ltr; position:absolute; z-index: -1 ;"
        ></canvas>
        <canvas
          id="printCanvas"
          style="direction: ltr; position:absolute; z-index: -1;"
          :style="`height: ${canvasHeight}px; width: ${canvasWidth}px;`"
        ></canvas>
      </div>
      <div class="col-span-12 2xl:col-span-9 2xl:col-span-10">
        <!-- BEGIN: File Manager Filter -->
        <div class="intro-y flex flex-col-reverse sm:flex-row items-center">
          <div
            class="w-full sm:w-auto relative mt-3"
            :class="isRTL ? 'ml-auto' : 'mr-auto'"
          >
            <SearchIcon
              class="w-4 absolute mt-2 inset-y-0 z-10 text-gray-700 dark:text-gray-300"
              :class="isRTL ? 'mr-3 right-0' : 'ml-3 left-0'"
            />
            <input
              type="text"
              class="form-control w-full sm:w-64 box px-10 text-gray-700 dark:text-gray-300 placeholder-theme-13"
              :placeholder="i18n('fileManager.search.placeholder')"
              v-model="search"
              @keyup.enter="onSearch()"
            />
            <button
              id="tabulator-html-filter-go"
              type="button"
              class="btn text-white bg-theme-39 w-full sm:w-16 sm:mr-3 sm:ml-3 mt-2 sm:mt-0"
              style="background-color:#d5aa0f"
              @click="onSearch()"
            >
              {{ i18n('fileManager.search.go') }}
            </button>
            <button
              id="tabulator-html-filter-reset"
              type="button"
              class="btn btn-secondary dark:text-black w-full sm:w-24 mt-2 sm:mt-0 sm:ml-1 sm:mr-1"
              style="background-color:#edf2f7"
              @click="onResetFilter()"
            >
              {{ i18n('fileManager.search.reset') }}
            </button>
          </div>
          <div class="w-full sm:w-auto flex items-center">
            <a :class="isRTL ? 'ml-4' : 'mr-4'">
              <RefreshCcwIcon
                class="cursor-pointer"
                @click="navigateChevron(chevronPath[chevronPath.length - 1])"
              />
            </a>
            <a
              href="javascript:;"
              data-toggle="modal"
              data-target="#upload-modal-preview"
              @click="uploadFilesModal = true"
              class="btn bg-theme-1 mr-1 mb-2 text-white whitespace-nowrap"
              style="background-color:#000"
              type="button"
              v-if="
                ((tab != 'Trash' && currentUser.roles?.includes('Upload')) ||
                  !currentUser.roles?.includes('Employee')) &&
                  chevronPath.length > 2
              "
            >
              <PlusIcon />
              {{ i18n('fileManager.uploadButton') }}
            </a>
            <a
              href="javascript:;"
              data-toggle="modal"
              data-target="#create-folder-modal-preview"
              class="btn bg-theme-1 mr-1 mb-2 text-white whitespace-nowrap"
              style="background-color:#000"
              type="button"
              v-if="
                ((tab != 'Trash' && currentUser.roles?.includes('Upload')) ||
                  !currentUser.roles?.includes('Employee')) &&
                  chevronPath.length > 2
              "
            >
              <PlusIcon />
              {{ i18n('fileManager.createFolderButton') }}
            </a>
          </div>
        </div>
        <!-- END: File Manager Filter -->
        <!-- BEGIN: Directory & Files -->
        <div
          style="width:100%; height:80vh;"
          class="flex justify-center"
          v-if="loading"
        >
          <LoadingIcon icon="grid" class="" style="width:100px" />
        </div>
        <div
          class="flex flex-wrap mt-5"
          v-if="!loading && currentUser.roles != 'Employee'"
        >
          <div v-for="path in chevronPath" :key="path" class="">
            <!-- <div class="w-full"> -->
            <ChevronRightIcon class="breadcrumb__icon" v-if="!isRTL" />
            <ChevronLeftIcon class="breadcrumb__icon" v-else />
            <a @click="navigateChevron(path)" class="cursor-pointer">{{
              path
            }}</a>
            <!-- </div> -->
          </div>
        </div>
        <ArrowLeftIcon
          v-if="!homePath && !loading && tab != 'Trash' && !isRTL"
          class="mt-10 cursor-pointer"
          @click="navigateBack()"
        />
        <ArrowRightIcon
          v-if="!homePath && !loading && tab != 'Trash' && isRTL"
          class="mt-10 cursor-pointer"
          @click="navigateBack()"
        />
        <div
          v-if="tab !== 'Trash' && chevronPath.length > 2 && !loading"
          class="flex justify-center sm:justify-end"
        >
          <div class="flex items-center mx-2 mt-2 sm:mt-0">
            <input
              id="checkbox-select-all"
              name="checkbox-select-all"
              class="form-check-input border border-gray mx-3 cursor-pointer"
              style="border-color: #d5aa0f"
              type="checkbox"
              v-model="selectAllCheckbox"
              :disabled="destroyLoading || files.length == 0"
              @change="onSelectAllCheckboxChange()"
            />
            <label for="checkbox-select-all" class="cursor-pointer">
              {{
                selectAllCheckbox
                  ? i18n('common.unselectAll')
                  : i18n('common.selectAll')
              }}
            </label>
          </div>
          <button
            id="delete-all"
            type="button"
            class="btn text-white btn-danger sm:w-32 sm:mr-3 sm:ml-3 mt-2 sm:mt-0 cursor-pointer"
            style="background-color: red"
            :disabled="
              files.filter(file => file.checkbox).length === 0 || destroyLoading
            "
            @click="
              () => {
                doDeleteAll(files.filter(file => file.checkbox))
                selectAllCheckbox = false
              }
            "
          >
            <span class="material-icons-outlined mx-2">delete_forever</span>
            {{ i18n('common.delete') }}
          </button>
        </div>
        <div
          v-if="tab == 'Trash' && filesComputed.length > 0 && !loading"
          class="flex justify-center"
        >
          <h1 class="text-2xl">
            {{ i18n('fileManager.filesDeletedAfterThirtyDays') }}
          </h1>
          <button
            id="empty-trash"
            type="button"
            class="btn text-white bg-theme-39 w-full sm:w-32 sm:mr-3 sm:ml-3 mt-2 sm:mt-0 cursor-pointer"
            style="background: #d5aa0f"
            @click="
              doEmptyTrash({
                files: rows,
                path: path
              })
            "
          >
            {{ i18n('common.emptyTrash') }}
          </button>
        </div>
        <div
          class="py-5"
          v-if="
            tab !== 'Trash' &&
              chevronPath.length > 2 &&
              !loading &&
              progressUploaderCount > 0
          "
        >
          <div class="w-full bg-gray-200 rounded-full">
            <div
              class="ease-in duration-500 bg-theme-39 text-xs font-medium text-blue-100 text-center p-0.5 leading-none rounded-l-full"
              :style="`width: ${progress}%`"
            >
              {{ progress }}%
            </div>
          </div>
        </div>

        <div
          class="intro-y inbox box mt-5"
          style="position: relative;"
          v-if="!loading"
        >
          <loading-spinner
            type="half-circle"
            :duration="1000"
            :size="40"
            color="#4469A8"
            :loading="destroyLoading"
          />
          <div class="overflow-x-auto sm:overflow-x-visible">
            <div
              v-for="(file, key) in filesComputed"
              :key="key"
              class="intro-y relative"
            >
              <div
                class="flex file inbox__item sm:block text-slate-600 dark:text-slate-500 bg-slate-100 dark:bg-darkmode-400/70 border-b border-slate-200/60 dark:border-darkmode-400 inbox__item--active"
              >
                <div class="flex  flex-1 px-5 py-3">
                  <div
                    class=" flex items-center mr-5 justify-between"
                    v-if="
                      chevronPath.length > 2 && file.name !== 'Shared with me'
                    "
                  >
                    <input
                      class="form-check-input flex-none"
                      type="checkbox"
                      v-model="file.checkbox"
                    />
                  </div>
                  <div @click="openeFile(file)">
                    <a
                      v-if="file.type.toLowerCase() === 'folder'"
                      class="w-16 mx-8 file__icon file__icon--directory"
                    ></a>
                    <a
                      v-else-if="file.type == 'pdf'"
                      class="w-16 mx-8 file__icon file__icon--image"
                    >
                      <div class="file__icon--image__preview image-fit">
                        <img
                          :src="require(`@/assets/images/pdf.svg`)"
                          :alt="file.name"
                        />
                      </div>
                    </a>
                    <a
                      v-else-if="file.type == 'doc' || file.type == 'docx'"
                      class="w-16 mx-8 file__icon file__icon--image"
                    >
                      <div class="file__icon--image__preview image-fit">
                        <img
                          :src="require(`@/assets/images/doc.svg`)"
                          :alt="file.name"
                        />
                      </div>
                    </a>
                    <a
                      v-else-if="file.type == 'xlsx'"
                      class="w-16 mx-8 file__icon file__icon--image"
                    >
                      <div class="file__icon--image__preview image-fit">
                        <img
                          :src="require(`@/assets/images/xls.svg`)"
                          :alt="file.name"
                        />
                      </div>
                    </a>
                    <a
                      v-else-if="file.type == 'image'"
                      class="w-16 mx-8 file__icon file__icon--image"
                    >
                      <div class="file__icon--image__preview image-fit">
                        <img :src="file.url" :alt="file.name" />
                      </div>
                    </a>
                    <a v-else class="w-16 mx-8 file__icon file__icon--file">
                      <div class="file__icon__file-name">{{ file.type }}</div>
                    </a>
                  </div>
                  <div
                    @click="openeFile(file)"
                    class="inbox__item--sender truncate ml-3 flex flex-col flex-1 items-center justify-center"
                  >
                    <span class="font-medium text-sm">{{
                      barcode(file.id)
                    }}</span
                    >
                    <Tippy :content="file.name">
                      <a class="block font-medium mt-4 text-center truncate">{{
                        file.name
                      }}</a>
                    </Tippy>
                    <div class="text-gray-600 text-xs text-center mt-0.5">
                      {{ file.size }}
                    </div>
                    <!-- </div> -->
                  </div>
                  <div class="dropdown flex items-center justify-center">
                    <a
                      class="dropdown-toggle w-5 h-5 block"
                      href="javascript:;"
                      aria-expanded="false"
                      id="item-dropdown-toggle"
                      v-if="
                        (currentUser.roles?.includes('Upload') ||
                          currentUser.roles?.includes('Download') ||
                          !currentUser.roles?.includes('Employee')) &&
                          chevronPath.length > 2
                      "
                    >
                      <!-- file.type != 'folder' -->
                      <MoreVerticalIcon class="w-5 h-5 text-gray-600" />
                    </a>
                    <div class="dropdown-menu w-40">
                      <div
                        class="dropdown-menu__content box dark:bg-dark-1 p-2"
                      >
                        <a
                          @click="downloadFile(file)"
                          href="javascript:;"
                          class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2  rounded-md"
                          v-if="
                            file.type != 'folder' &&
                              tab != 'Trash' &&
                              hasDownloadAccess(file) &&
                              (currentUser.roles?.includes('Download') ||
                                !currentUser.roles?.includes('Employee'))
                          "
                        >
                          <DownloadIcon class="w-4 h-4 mx-2" />
                          {{ i18n('fileManager.download') }}
                        </a>
                        <a
                          @click="print(file)"
                          href="javascript:;"
                          class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2  rounded-md"
                          v-if="
                            file.type != 'folder' &&
                              hasPrintAccess(file) &&
                              tab != 'Trash' &&
                              (currentUser.roles?.includes('Print') ||
                                !currentUser.roles?.includes('Employee'))
                          "
                        >
                          <PrinterIcon class="w-4 h-4 mx-2" />
                          {{ i18n('fileManager.print') }}
                        </a>
                        <a
                          @click="printFileBarcode(file)"
                          href="javascript:;"
                          class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2  rounded-md"
                          v-if="
                            file.type != 'folder' &&
                              tab != 'Trash' &&
                              !currentUser?.roles?.includes('Employee')
                          "
                        >
                          <PrinterIcon class="w-4 h-4 mx-2" />
                          {{ i18n('common.printBarcode') }}
                        </a>
                        <a
                          @click="sharedFile = file"
                          href="javascript:;"
                          data-toggle="modal"
                          data-target="#rename-file-modal"
                          type="button"
                          class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                          v-if="
                            file.url?.includes('foldername') &&
                              tab != 'Trash' &&
                              !currentUser.roles?.includes('Employee')
                          "
                        >
                          <!-- <span class="material-icons w-4 h-4 mx-2">drive_file_rename_outline</span> -->
                          <span class="material-icons-outlined w-4 h-4 mx-2"
                            >drive_file_rename_outline</span
                          >
                          {{ i18n('common.rename') }}
                        </a>
                        <a
                          @click="sharedFile = file"
                          class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                          href="javascript:;"
                          data-toggle="modal"
                          data-target="#share-file-modal-preview"
                          type="button"
                          v-if="
                            tab != 'Trash' &&
                              file.type != 'folder' &&
                              hasShareAccess(file) &&
                              (currentUser.roles?.includes('Share') ||
                                !currentUser.roles?.includes('Employee'))
                          "
                        >
                          <Share2Icon class="w-4 h-4 mx-2" />
                          {{ i18n('fileManager.share') }}
                        </a>
                        <a
                          @click="sharedFile = file"
                          class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2  rounded-md"
                          href="javascript:;"
                          data-toggle="modal"
                          data-target="#share-download-modal-preview"
                          type="button"
                          v-if="
                            tab != 'Trash' &&
                              file.type != 'folder' &&
                              hasShareAccess(file) &&
                              (currentUser.roles?.includes('Share') ||
                                !currentUser.roles?.includes('Employee'))
                          "
                        >
                          <Share2Icon class="w-4 h-4 mx-2" />
                          {{ i18n('fileManager.shareDownloadAccess') }}
                        </a>
                        <a
                          @click="sharedFile = file"
                          class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2  rounded-md"
                          href="javascript:;"
                          data-toggle="modal"
                          data-target="#share-print-modal-preview"
                          type="button"
                          v-if="
                            tab != 'Trash' &&
                              file.type != 'folder' &&
                              hasShareAccess(file) &&
                              (currentUser.roles?.includes('Share') ||
                                !currentUser.roles?.includes('Employee'))
                          "
                        >
                          <Share2Icon class="w-4 h-4 mx-2" />
                          {{ i18n('fileManager.sharePrintAccess') }}
                        </a>
                        <a
                          @click="sharedFile = file"
                          class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2  rounded-md"
                          href="javascript:;"
                          data-toggle="modal"
                          data-target="#remove-access-modal"
                          type="button"
                          v-if="
                            tab != 'Trash' &&
                              file.type != 'folder' &&
                              hasShareAccess(file) &&
                              (currentUser.roles?.includes('Share') ||
                                !currentUser.roles?.includes('Employee'))
                          "
                        >
                          <TrashIcon class="w-4 h-4 mx-2" />
                          {{ i18n('common.removeAccess') }}
                        </a>
                        <a
                          @click="sharedFile = file"
                          class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2  rounded-md"
                          data-toggle="modal"
                          data-target="#delete-modal-preview"
                          id="disableModal"
                          v-if="currentUser.isOwner"
                        >
                          <TrashIcon class="w-4 h-4 mx-2" />
                          {{ i18n('fileManager.delete') }}
                        </a>
                        <a
                          class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                          @click="restoreFile(file)"
                          v-if="
                            (hasDeleteAccess(file) ||
                              !currentUser.roles?.includes('Employee')) &&
                              tab == 'Trash'
                          "
                        >
                          <CornerLeftUpIcon class="w-4 h-4 mx-2" />
                          {{ i18n('fileManager.restore') }}
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              v-if="filesComputed.length == 0"
              class="col-span-12 items-center flex justify-center"
              style="height: 300px;"
            >
              <div>
                <div class="flex justify-center">
                  <FileTextIcon style="height:125px; width: 125px;" />
                </div>
                <h3 class="font-medium text-3xl mt-5">
                  {{ i18n('fileManager.noFiles') }}
                </h3>
              </div>
            </div>
          </div>
        </div>
      </div>
      <UploadFilesModal
        ref="uploader"
        :showModal="uploadFilesModal"
        :destination="path"
        :currentUser="currentUser"
        @close="uploadFilesModal = false"
        @setPrintToggle="setPrintToggle"
      />
      <FsLightbox
        :toggler="toggler"
        :sources="[source]"
        :key="source"
        type="image"
        :types="['image']"
      />
      <CreateFolderModal
        :destination="path"
        :serverPath="serverPath"
        :showCreateFolderModal="true"
        @close="() => ''"
      />
      <DeleteModal
        :showModal="sharedFile"
        :item="sharedFile && sharedFile.type === 'folder' ? 'folder' : 'file'"
        @delete="deleteOrMove(sharedFile)"
      />
      <ShareFileModal :showModal="sharedFile" :file="sharedFile" />
      <ShareDownloadAccess :showModal="sharedFile" :file="sharedFile" />
      <SharePrintAccess :showModal="sharedFile" :file="sharedFile" />
      <RemoveAccessModal :showModal="sharedFile" :file="sharedFile" />
      <PDFViewer :showPDFModal="true" :path="path" :url="openedPDF" />
      <PrintBarcodeLoadingModal :showModal="true" />
      <RenameFileModal
        :showModal="!!sharedFile"
        :file="sharedFile"
        @doSubmit="doRename"
      />
      <ErrorBarcodePrinter
        :message="
          isRTL
            ? 'يرجى التأكد من اختيار طابعة الباركود'
            : 'Please make sure you choose the barcode printer'
        "
      />
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import { mapActions, mapGetters } from 'vuex'
import Toastify from 'toastify-js'
import AuthService from '../../auth/auth-service'
import ErrorBarcodePrinter from '@/components/notifications/error-barcode-printer.vue'
import PrintBarcodeLoadingModal from '@/components/loading/print-barcode-loading.vue'
import RenameFileModal from './rename-file.vue'
import UploadFilesModal from './upload-files.vue'
import DeleteModal from '@/components/modals/delete-modal.vue'
import CreateFolderModal from './create-folder.vue'
import ShareFileModal from './share-file.vue'
import ShareDownloadAccess from './share-download-access.vue'
import SharePrintAccess from './share-print-access.vue'
import RemoveAccessModal from '../share-files/remove-access.vue'
import PDFViewer from './pdf-viewer.vue'
import FsLightbox from 'fslightbox-vue/v3'
import * as BrowserPrint from './BrowserPrint-3.0.216.min.js'
import bwipjs from 'bwip-js'
// import HelperClass from '@/shared/helperClass'
import html2canvas from 'html2canvas'
// import paginationVue from '../../components/pagination/pagination.vue'
import * as Printjs from 'print-js'
import config from '@/config'
const editorUrl = config.editorUrl
let selectedDevice
function setup() {
  // Get the default device from the application as a first step. Discovery takes longer to complete.
  BrowserPrint.getLocalDevices(
    function(deviceList) {
      selectedDevice = deviceList[0]
    },
    function() {},
    'printer'
  )
}

function sendImage(imageUrl) {
  selectedDevice.convertAndSendFile(imageUrl, undefined, errorCallback)
}
var errorCallback = function(errorMessage) {
  alert('Error: ' + errorMessage)
}
export default defineComponent({
  components: {
    ErrorBarcodePrinter,
    FsLightbox,
    UploadFilesModal,
    RenameFileModal,
    DeleteModal,
    CreateFolderModal,
    PDFViewer,
    ShareFileModal,
    ShareDownloadAccess,
    SharePrintAccess,
    RemoveAccessModal,
    // paginationVue,
    PrintBarcodeLoadingModal
  },

  data() {
    return {
      uploadFilesModal: false,
      selectAllCheckbox: false,
      canvasHeight: 250,
      canvasWidth: 300,
      filesToPrintCount: 0,
      checkbox: false,
      search: '',
      type: '',
      toggler: false,
      source: '',
      tab: 'All',
      files: [],
      path: '',
      serverPath: '',
      openedPDF: '',
      sharedFile: null,
      chevronPath: ['Data-Center'],
      filesInFirestoreLength: 0,
      printBarcodeToggle: false,
      pageSize: 10,
      totalFilesCount: null,
      errorMessage: ''
    }
  },
  computed: {
    ...mapGetters({
      currentUser: 'authStore/currentUser',
      rows: 'dataCenterStore/rows',
      loading: 'dataCenterStore/loading',
      destroyLoading: 'dataCenterStore/destroyLoading',
      filesInFirestore: 'dataCenterStore/filesInFirestore',
      users: 'usersStore/rows',
      loadingStorage: 'dataCenterStore/loadingStorage',
      lastPage: 'dataCenterStore/lastPage',
      totalFiles: 'dataCenterStore/totalFiles',
      progressUploaderCount: 'dataCenterStore/progressUploaderCount',
      totalProgressCount: 'dataCenterStore/totalProgressCount'
    }),
    isRTL() {
      return this.$i18n.locale == 'ar'
    },
    language() {
      return this.$i18n.locale
    },
    filesComputed() {
      return this.files.filter(element => {
        return this.hasReadAccess(element)
      })
    },
    filesToDelete() {
      return this.files.filter(file => {
        return file.checkbox == true
      })
    },
    homePath() {
      return this.path == this.serverPath
    },
    barcode() {
      return fileId => {
        const file = this.filesInFirestore.filter(file => file.id == fileId)
        return file.length
          ? file[0].type == 'File'
            ? file[0].barcode
            : ''
          : ''
      }
    },
    PageCount() {
      return Math.ceil(this.filesComputed / this.pageSize)
    },
    progress() {
      return this.totalProgressCount > 0
        ? Math.round(
            100 - (100 / this.totalProgressCount) * this.progressUploaderCount
          )
        : 0
    },
    fileName() {
      return file => {
        return file.name
      }
    }
  },
  async created() {
    if (this.currentUser.roles != 'Employee') {
      this.path = 'Data Center'
      this.serverPath = 'Data Center'
    } else {
      this.path = `Data Center/${this.currentUser.company.name}?:${this.currentUser.company.prefix}00${this.currentUser.company.id}/${this.currentUser.department.name}?:${this.currentUser.company.prefix}${this.currentUser.department.prefix}${this.currentUser.department.id}`
      this.serverPath = `Data Center/${this.currentUser.company.name}?:${this.currentUser.company.prefix}00${this.currentUser.company.id}/${this.currentUser.department.name}?:${this.currentUser.company.prefix}${this.currentUser.department.prefix}${this.currentUser.department.id}`
    }
    // this.doTurnOnProgressUploader()
    this.doFetch({ path: this.path })
    this.doFetchFirestore()
    // this.getFiles(this.path)
    await this.getCurrentUser()
    if (
      this.currentUser &&
      this.currentUser.settings &&
      this.currentUser.settings.scale
    ) {
      this.canvasHeight = this.currentUser.settings.scale.height
      this.canvasWidth = this.currentUser.settings.scale.width
    }
    this.fetchUsers()
  },
  mounted() {
    setup()
  },
  methods: {
    ...mapActions({
      getCurrentUser: 'authStore/getCurrentUser',
      fetchUsers: 'usersStore/doFetch',
      saveCurrentUser: 'authStore/saveCurrentUser',
      doFetch: 'dataCenterStore/doFetch',
      doFetchFirestore: 'dataCenterStore/doFetchFirestore',
      doFetchFileLength: 'dataCenterStore/doFetchFileLength',
      doEmptyTrash: 'dataCenterStore/doEmptyTrash',
      doMoveFile: 'dataCenterStore/doMoveFile',
      doRestore: 'dataCenterStore/doRestore',
      doDeleteFile: 'dataCenterStore/doDeleteFile',
      doDestroyFolder: 'dataCenterStore/doDestroyFolder',
      doDestroyAll: 'dataCenterStore/doDestroyAll',
      doRenameFile: 'dataCenterStore/doRenameFile',
      doSearch: 'dataCenterStore/doSearch',
      // doTurnOnProgressUploader: 'dataCenterStore/doTurnOnProgressUploader'
    }),
    i18n(key, args) {
      return this.$t(key, args)
    },
    errorNotificationToggle() {
      Toastify({
        node: cash('#error-barcode-printer')
          .clone()
          .removeClass('hidden')[0],
        duration: 3000,
        newWindow: true,
        close: true,
        gravity: 'top',
        position: 'right',
        stopOnFocus: true
      }).showToast()
    },
    print(file) {
      Printjs({
        printable: file.url,
        type: file.type,
        showModal: true,
        modalMessage: 'Loading...'
      })
    },
    async doFetchFirstPage(path) {
      await this.doFetchWithPagination({
        path: path,
        pageSize: this.pageSize
      })

      this.totalFiles = await this.doFetchFileLength()
    },
    async fetchMore() {
      await this.doFetch({ path: this.path, lastPage: this.lastPage })
    },
    async doFetchNextPage(path) {
      await this.doFetchWithPagination({
        path: path,
        pageSize: this.pageSize,
        lastPage: this.lastPage
      })
    },
    async doFetchPrevPage(path) {
      await this.doFetchWithPagination({
        path: path,
        pageSize: this.pageSize
      })
    },
    // Searching
    onSearch() {
      if (this.search != '') {
        this.doSearch(this.search)
      } else {
        this.doFetch({ path: this.path })
      }
    },
    onResetFilter() {
      this.search = ''
      this.onSearch()
    },
    // Delete or Move or Restore
    async restoreFile(file) {
      // debugger
      document.getElementById('item-dropdown-toggle').click()
      const fileInFirestore = this.filesInFirestore.filter(
        el => el.id == file.id
      )[0]
      await this.doRestore({ file: file, fileInFirestore: fileInFirestore })
      await this.doFetch(this.path + '/trash')
    },
    async deleteOrMove(file) {
      document.getElementById('item-dropdown-toggle').click()
      if (file.type === 'folder') {
        this.doDestroyFolder({
          id: file.id,
          url: file.url
        })
      } else {
        this.tab == 'Trash'
          ? await this.deleteFile(file)
          : await this.moveFile(file)
      }
    },
    async deleteFile(file) {
      await this.doDeleteFile({ file: file, path: this.path })
      // await this.doFetch(this.path + '/trash')
    },
    async moveFile(file) {
      await this.doMoveFile({ file: file, path: this.path })
      // await this.doFetch({path: this.path})
    },
    deleteOrMoveManyFiles() {
      this.tab == 'Trash' ? this.deleteManyFiles() : this.moveManyFiles()
    },
    moveManyFiles() {
      this.filesToDelete.forEach(file => {
        this.moveFile(file)
      })
    },
    deleteManyFiles() {
      this.filesToDelete.forEach(file => {
        this.deleteFile(file)
      })
    },
    // Download
    async downloadFile(file) {
      const data = await fetch(file.url)
      const blob = await data.blob()
      const url = URL.createObjectURL(blob)
      const anchor = document.createElement('a')
      anchor.href = url
      anchor.download = file.name
      document.body.appendChild(anchor)
      anchor.click()
      document.body.removeChild(anchor)
    },
    async filterFiles(tab) {
      this.tab = tab
      if (tab == 'Trash') {
        await this.doFetch(this.path + '/trash')
      } else if (tab == 'All') {
        await this.doFetch({ path: this.path })
      } else {
        await this.doFetch({ path: this.path })
        this.files = this.files.filter(el => {
          return el.type == tab
        })
      }
    },
    // Navigate
    async navigateChevron(path) {
      for (let index = this.chevronPath.length - 1; index >= 0; index--) {
        const element = this.chevronPath[index]
        if (element != path) {
          this.path = this.path.substring(0, this.path.lastIndexOf('/'))
          this.chevronPath.pop()
        } else {
          await this.doFetch({ path: this.path })
          return
        }
      }
    },
    navigate(file) {
      if (this.tab == 'Trash') {
        this.path += '/' + file.url
        this.doFetch(this.path + '/trash')
      } else {
        this.path = file.url || file?.fullPath
        this.doFetch({ path: this.path })
        this.chevronPath.push(file.name)
      }
    },
    navigateBack() {
      if (this.tab == 'Trash') {
        this.trashPath = this.trashPath.substring(
          0,
          this.trashPath.lastIndexOf('/')
        )
        this.doFetch(this.trashPath)
      } else {
        this.path = this.path.substring(0, this.path.lastIndexOf('/'))
        this.doFetch({ path: this.path })
        this.chevronPath.pop()
        history.popState()
      }
    },
    // Verify Permessions
    hasReadAccess(file) {
      if (this.currentUser.roles != 'Employee') return true
      let fileInFirestore = this.filesInFirestore.filter(el => {
        return el.id == file.id
      })
      if (fileInFirestore.length > 0) fileInFirestore = fileInFirestore[0]
      if (
        fileInFirestore.readAccess?.includes(this.currentUser.id) ||
        file.departmentNumber == this.currentUser.department.prefix
      ) {
        return true
      } else {
        return false
      }
    },
    hasDownloadAccess(file) {
      if (this.currentUser.roles != 'Employee') return true
      const fileInFirestore = this.filesInFirestore.filter(el => {
        return el.id == file.id
      })[0]
      if (fileInFirestore.downloadAccess?.includes(this.currentUser.id)) {
        return true
      } else {
        return false
      }
    },
    hasPrintAccess(file) {
      if (this.currentUser.roles != 'Employee') return true
      const fileInFirestore = this.filesInFirestore.filter(el => {
        return el.id == file.id
      })[0]
      if (fileInFirestore.printAccess?.includes(this.currentUser.id)) {
        return true
      } else {
        return false
      }
    },
    hasDeleteAccess(file) {
      if (this.currentUser.roles != 'Employee') return true
      const fileInFirestore = this.filesInFirestore.filter(el => {
        return el.id == file.id
      })[0]
      if (fileInFirestore.deleteAccess?.includes(this.currentUser.id)) {
        return true
      } else {
        return false
      }
    },
    hasShareAccess(file) {
      if (this.currentUser.roles != 'Employee') return true
      const fileInFirestore = this.filesInFirestore.filter(el => {
        return el.id == file.id
      })[0]
      if (fileInFirestore.shareAccess?.includes(this.currentUser.id)) {
        return true
      } else {
        return false
      }
    },
    setPrintToggle(toggle, filesCount) {
      this.printBarcodeToggle = toggle
      this.filesToPrintCount = filesCount || 0
    },
    printBarcode(index) {
      try {
        const companyName = this.chevronPath[1]
        const backendBarcode = this.filesInFirestore[index].barcode
        const Barcode =
          backendBarcode ||
          this.filesInFirestore[index].id.substr(0, 4) +
            this.filesInFirestore[index].createdAt.seconds // Text to encode
        setup()
        bwipjs.toCanvas('mycanvas', {
          bcid: 'code128', // Barcode type,
          paddingtop: 20,
          paddingleft: 7,
          text: Barcode,
          scale: 2, // 3x scaling factor
          height: 10, // Bar height, in millimeters
          includetext: true, // Show human-readable text
          textxalign: 'center' // Always good to set this
        })
        html2canvas(document.getElementById('mycanvas'), { scale: 1 }).then(
          canvas => {
            const space = 300 - companyName.length * 12
            const xlpadding = space > 0 ? space / 2 : 0
            const img = canvas.toDataURL('image/png')
            const image = new Image()
            image.src = img
            image.onload = () => {
              const c = document.getElementById('printCanvas')
              const ctx = c.getContext('2d')
              ctx.drawImage(image, 27, 10)
              ctx.font = 'bold 20px Lato'
              ctx.fillText(companyName, xlpadding, 145)
              html2canvas(document.getElementById('printCanvas')).then(
                canvas => {
                  const img = canvas.toDataURL('image')
                  sendImage(img)
                }
              )
            }
          }
        )
      } catch (error) {
        console.error(error)
      }
    },

    printFileBarcode(file) {
      try {
        const Barcode = this.barcode(file.id)
        const companyName = this.chevronPath[1]
        setup()
        if (!selectedDevice) {
          this.errorMessage = this.isRTL
            ? 'يرجى التأكد من اختيار طابعة الباركود'
            : 'Please make sure you choose the barcode printer'
          this.errorNotificationToggle()
          return
        }
        bwipjs.toCanvas('mycanvas', {
          bcid: 'code128', // Barcode type,
          paddingtop: 20,
          paddingleft: 7,
          text: Barcode,
          scale: 2, // 3x scaling factor
          height: 10, // Bar height, in millimeters
          includetext: true, // Show human-readable text
          textxalign: 'center' // Always good to set this
        })
        html2canvas(document.getElementById('mycanvas'), { scale: 1 }).then(
          canvas => {
            const space = 300 - companyName.length * 12
            const xlpadding = space > 0 ? space / 2 : 0
            const img = canvas.toDataURL('image/png')
            const image = new Image()
            image.src = img
            image.onload = () => {
              const c = document.getElementById('printCanvas')
              const ctx = c.getContext('2d')
              ctx.drawImage(image, 27, 10)
              ctx.font = 'bold 20px Lato'
              ctx.fillText(companyName, xlpadding, 145)
              html2canvas(document.getElementById('printCanvas')).then(
                canvas => {
                  const img = canvas.toDataURL('image')
                  sendImage(img)
                }
              )
            }
          }
        )
      } catch (error) {
        console.error(error)
      }
      // cash('#print-barcode-loading-modal').modal('hide')
    },
    async doDeleteAll(files) {
      await this.doDestroyAll({
        baseUrl: this.path,
        files: files
      })
    },
    doRename({ fileId, newName }) {
      this.doRenameFile({ id: fileId, newName: newName })
      const index = this.files.findIndex(file => file.id == fileId)
      this.files[index].name = newName
    },
    incompleteUploadFiles() {
      if (this.progressUploaderCount > 0) {
        return true
      } else {
        setTimeout(() => {
          return false
        }, 1000)
      }
    },
    onSelectAllCheckboxChange() {
      for (let index = 0; index < this.files.length; index++) {
        if (this.files[index].name !== 'Shared with me') {
          this.files[index].checkbox = this.selectAllCheckbox
        }
      }
    },
    getFileType(name) {
      const images = ['png', 'jpg', 'jpeg', 'webp', 'psd', 'svg']
      const extension = name.split('.').pop()
      if (images?.includes(extension)) {
        return 'image'
      } else {
        return extension
      }
    },
    openeFile(file) {
      switch (file.type) {
        case 'docx':
        case 'doc':
          this.openWord(file)
          break
        case 'xlsx':
          this.openExcel(file)
          break
        case 'pdf':
          this.openPDF(file)
          break
        case 'image':
          this.openFsLightBox(file.url)
          break
        case 'folder':
          this.navigate(file)
          break
        case 'Folder':
          this.navigate(file)
          break
        case 'File':
          this.openFile(file)
          break
      }
    },
    openPDF(file) {
      const link = `/${this.language}/pdf/${file.id}`
      const anchor = document.createElement('a')
      anchor.href = link
      anchor.target = '_blank'
      document.body.appendChild(anchor)
      anchor.click()
    },
    openFile(file) {
      window.open(file?.publicUrl, '_blank')
    },
    async openExcel(file) {
      const token = await AuthService.createCustomToken()
      const link = `${editorUrl}/${this.language}/excel/${file.id}?tokenId=${token}`
      const anchor = document.createElement('a')
      anchor.href = link
      anchor.target = '_blank'
      document.body.appendChild(anchor)
      anchor.click()
    },
    async openWord(file) {
      const link = `/${this.language}/word/${file.id}`
      const anchor = document.createElement('a')
      anchor.href = link
      anchor.target = '_blank'
      document.body.appendChild(anchor)
      anchor.click()
    },
    openFsLightBox(source) {
      this.source = source
      setTimeout(() => {
        this.toggler = !this.toggler
      }, 1)
    }
  },
  watch: {
    rows: {
      handler: function(newval) {
        this.files = newval.sort((a, b) =>
          a.name.toLowerCase().localeCompare(b.name.toLowerCase(), undefined, {
            numeric: true,
            sensitivity: 'base'
          })
        )
      },
      deep: true
    }
  }
})
</script>

<template>
  <div>
    <DarkModeSwitcher />
    <div class="" style="z-index:2 !important;"></div>
    <div class="container sm:px-10">
      <div class="block xl:grid grid-cols-2 gap-4">
        <!-- BEGIN: Register Info -->
        <div class="hidden xl:flex flex-col min-h-screen">
          <div class="my-auto">
            <!-- <div class="loader"> -->
              <img
                alt="Icewall Tailwind HTML Admin Template"
                class="-intro-x w-1/2 -mt-16"
                style="width:500px;"
                src="/logo.png"
              />
            <!-- </div> -->
            <span
              class="text-4xl font-black"
              style="font-size:60px"
              :class="isRTL ? '' : 'ml-20'"
              :style="isRTL ? 'margin-right:140px !important' : ''"
              >El Ghoniemy Developments
              <!-- <span style="color:#bd212e">{{ i18n('app.sc') }}</span> -->
            </span>
            <p
              class="text-4xl font-bold mt-5"
              :style="isRTL ? 'margin-right:60px' : 'margin-left:40px'"
            >
              <!-- {{ i18n('app.dashboard') }} -->
            </p>
          </div>
        </div>
        <!-- END: Register Info -->
        <!-- BEGIN: Register Form -->
        <div class="h-screen xl:h-auto flex py-5 xl:py-0 my-10 xl:my-0">
          <div
            class="my-auto mx-auto bg-white dark:bg-dark-1 xl:bg-transparent px-5 sm:px-8 py-8 rounded-md shadow-md xl:shadow-none w-full sm:w-3/4 lg:w-2/4"
          >
            <div class="xl:hidden block flex justify-center">
              <div class="my-auto">
                <img
                  alt="Icewall Tailwind HTML Admin Template"
                  class="-intro-x w-1/2 -mt-16"
                  src="/logo-black.png"
                  style="width: 200px; padding-top:100px; padding-bottom:50px"
                />
              </div>
            </div>
            <div
              class="intro-x text-gray-700 dark:text-gray-600 text-center  xl: flex"
            >
              <!-- <i18nFlags class="mb-10" @click="changeLoginClass()" /> -->
            </div>
            <h2
              class="intro-x font-bold text-2xl xl:text-3xl text-center xl:"
            >
            Signup
              <!-- {{ i18n('signup.signup') }} -->
            </h2>
            <div
              class="intro-x mt-2 text-gray-500 dark:text-gray-500 xl:hidden text-center xl:"
            >
              A few more clicks to sign in to your account. Manage all your
              e-commerce accounts in one place
            </div>
            <div class="intro-x mt-8">
              <input
                v-model="form.firstName"
                type="text"
                class="intro-x login__input form-control py-3 px-4 border-gray-300 block "
                placeholder="first Name"
              />
              <input
                v-model="form.lastName"
                type="text"
                class="intro-x login__input form-control py-3 px-4 border-gray-300 block mt-4 "
                placeholder="last name"
              />
              <input
                v-model="form.email"
                type="text"
                class="intro-x login__input form-control py-3 px-4 border-gray-300 block mt-4 "
                placeholder="email address"
              />
              <input
                v-model="form.password"
                type="password"
                class="intro-x login__input form-control py-3 px-4 border-gray-300 block mt-4 "
                placeholder="password"
              />
              <!-- <div class="intro-x w-full grid grid-cols-12 gap-4 h-1 mt-3">
                <div class="col-span-3 h-full rounded bg-theme-10"></div>
                <div class="col-span-3 h-full rounded bg-theme-10"></div>
                <div class="col-span-3 h-full rounded bg-theme-10"></div>
                <div
                  class="col-span-3 h-full rounded bg-gray-200 dark:bg-dark-2"
                ></div>
              </div> -->
              <!-- <a
                href=""
                class="intro-x text-gray-600 block mt-2 text-xs sm:text-sm"
                >What is a secure password?</a
              > -->
              <input
                v-model="form.passwordConfirmation"
                type="password"
                class="intro-x login__input form-control py-3 px-4 border-gray-300 block mt-4 "
                placeholder="confirm password"
              />
            </div>
            <!-- <div
              class="intro-x flex items-center text-gray-700 dark:text-gray-600 mt-4 text-xs sm:text-sm"
            >
              <input
                id="remember-me"
                type="checkbox"
                v-model="form.privacyPolicy"
                class="form-check-input border mr-2 ml-2"
              />
              <label
                class="cursor-pointer select-none xl:"
                for="remember-me"
                >{{ i18n('signup.agree') }}
              </label>
              <a class="ml-1 mr-1 xl:" href="">{{
                i18n('signup.privacyPolicy')
              }}</a
              >.
            </div> -->
            <div
              v-if="error"
              class="alert alert-danger alert-dismissible show flex items-center mb-2 mt-2"
              role="alert"
              style="z-index:2 !important"
            >
              <AlertOctagonIcon class="w-6 h-6 mr-2" />
              <!-- {{ i18n(errorData) }} -->
            </div>
            <div
              class="intro-x mt-5 xl:mt-8 text-center grid grid-cols-12 login__input"
              :class="isRTL ? ' xl:text-right' : ' xl:text-left'"
            >
              <button
                class="btn text-white bg-theme-39 py-3 px-4 align-top lg:col-span-6 col-span-12"
                style="background-color: #d5aa0f;"
                :class="isRTL ? 'xl:ml-3' : 'xl:mr-3'"
                @click="register()"
              >
              Register
                <!-- {{ i18n('signup.register') }} -->
              </button>
              <button
                class="btn btn-outline-secondary py-3 px-4 mt-3 xl:mt-0 align-top xl: lg:col-span-6 col-span-12"
                @click="goToLogin()"
              >
              Sign In
                <!-- {{ i18n('signup.signIn') }} -->
              </button>
            </div>
          </div>
        </div>
        <!-- END: Register Form -->
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref, computed } from 'vue'
import DarkModeSwitcher from '@/components/dark-mode-switcher/Main.vue'
// import i18nFlags from '@/components/i18n/i18n-flags.vue'
import { useStore } from '@/store'
import { mapGetters, mapActions } from 'vuex'

export default defineComponent({
  // created() {
  //   document.title =
  //     this.i18n('routes.app') + ' | ' + this.i18n('routes.register')
  // },
  // watch: {
  //   isRTL() {
  //     document.title =
  //       this.i18n('routes.app') + ' | ' + this.i18n('routes.register')
  //   }
  // },
  components: {
    // i18nFlags,
    DarkModeSwitcher
  },
  computed: {
    ...mapGetters({
      ownerExist: 'authStore/ownerExist'
    }),
    isRTL() {
      return false
      // return this.$i18n.locale == 'ar'
    }
  },
  watch: {
    ownerExist(newVal) {
      newVal
        ? this.$router.push('/auth/login')
        : ''
    }
  },
  mounted() {
    // debugger
    // if (this.ownerExist) {
    //   this.$router.push('/auth/login')
    // }
    if (this.isRTL) {
      cash('body')
        .removeClass('main')
        .removeClass('error-page')
        .addClass('login-rtl')
    } else {
      cash('body')
        .removeClass('main')
        .removeClass('error-page')
        .addClass('login')
    }
  },
  setup() {
    const store = useStore()
    const darkMode = computed(() => store.state.main.darkMode)
    const error = ref(false)
    const errorData = ref('')
    const form = ref({
      firstName: ref(''),
      lastName: ref(''),
      email: ref(''),
      password: ref(''),
      passwordConfirmation: ref(''),
      privacyPolicy: ref(false)
    })
    return {
      darkMode,
      error,
      errorData,
      form
    }
  },
  methods: {
    ...mapActions({
      doRegisterOwner: 'authStore/doRegisterOwner'
    }),
    changeLoginClass() {
      if (this.isRTL) {
        cash('body')
          .removeClass('login')
          .addClass('login-rtl')
      } else {
        cash('body')
          .removeClass('login-rtl')
          .addClass('login')
      }
    },
    validateEmail(email) {
      return email.match(
        // eslint-disable-next-line
        /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      )
    },
    register() {
      if (
        this.form.email.length == 0 ||
        this.form.password.length == 0 ||
        this.form.firstName.length == 0 ||
        this.form.lastName.length == 0 ||
        this.form.passwordConfirmation.length == 0
      ) {
        this.error = true
        this.errorData = 'signup.errors.emptyFields'
      } else if (!this.validateEmail(this.form.email)) {
        this.error = true
        this.errorData = 'signup.errors.wrongEmail'
      } else if (this.form.password != this.form.passwordConfirmation) {
        this.error = true
        this.errorData = 'signup.errors.passwordsDontMatch'
      // } else if (!this.form.privacyPolicy) {
      //   this.error = true
      //   this.errorData = 'signup.errors.privacyPolicy'
      } else {
        this.error = false
        this.doRegisterOwner(this.form)
      }
    },
    goToLogin() {
      this.$router.push('/auth/login')
    }
    // i18n(key, args) {
    //   return this.$t(key, args)
    // }
  }
})
</script>

<style lang="scss">
.dark .btn-outline-secondary:hover {
  --tw-bg-opacity: 0.2;
  background-color: rgba(226, 232, 240, var(--tw-bg-opacity));
}

.logo-web {
  margin-left: 50px;
}
[dir='rtl'] .logo-web {
  margin-right: 50px;
}

.loader {
  width: 300px;
  height: 300px;
  // line-height: 150px;

  margin-left: 100px !important;
  margin-bottom: 20px !important;
  margin-right: 100px !important;
  position: relative;
  box-sizing: border-box;
  text-align: center;
  z-index: 0;
  text-transform: uppercase;
}

.loader:before,
.loader:after {
  opacity: 0;
  box-sizing: border-box;
  content: '\0020';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border: 5px solid #bd212e;
  box-shadow: 0 0 50px #bd212e, inset 0 0 50px #bd212e;
}

.loader:after {
  z-index: 1;
  -webkit-animation: gogoloader 2s infinite 1s;
}

.loader:before {
  z-index: 2;
  -webkit-animation: gogoloader 2s infinite;
}

@-webkit-keyframes gogoloader {
  0% {
    -webkit-transform: scale(0);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(1);
    opacity: 0;
  }
}
</style>

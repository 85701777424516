import lodash from 'lodash'
import FirebaseRepository from '../shared/firebase/firebase-repository'
import { firestoreAction, vuexfireMutations } from 'vuexfire'
import firebase from 'firebase/compat/app'
import 'firebase/compat/firestore'
import store from './index'
import HelperClass from '@/shared/helperClass'

const state = () => {
  return {
    rows: [],
    loading: false,
    record: null,
    loadMoreLastRecord: null,
    lastFetchedRecord: null,
    firstFetchedRecord: null,
    moreRowsPending: true,
    rowsBatch: []
  }
}
const getters = {
  rows: state => state.rows,
  loading: state => state.loading,
  record: state => state.record,
  lastFetchedRecord: state => state.lastFetchedRecord,
  firstFetchedRecord: state => state.firstFetchedRecord,
  loadMoreLastRecord: state => state.loadMoreLastRecord,
  moreRows: state => state.moreRowsPending,
  rowsBatch: state => state.rowsBatch || []
}
const mutations = {
  ...vuexfireMutations,
  v_FETCH_STARTED(state) {
    state.loading = true
  },
  v_FETCH_SUCCESS(state) {
    state.loading = false
  },
  v_FETCH_ERROR(state) {
    state.loading = false
  },
  ADD_TO_ROWS(state, payload) {
    state.rows.push(payload)
  },
  RESET_ROWS(state) {
    state.rows = []
  },
  RESET_CURSOR(state) {
    state.loadMoreLastRecord = null
    state.moreRowsPending = true
    state.rowsBatch = []
    state.rows = []
  },
  ADD_AT_FIRST_ROWS(state, payload) {
    const myArray = state.rows
    myArray.unshift(payload)
    state.rows = myArray
  },
  MERGE_ROWS_BATCH(state, payload) {
    const myArray = lodash.concat(state.rows, payload)
    state.rows = lodash.uniqWith(myArray, lodash.isEqual)
  },
  SET_LOAD_MORE_LAST(state) {
    state.loadMoreLastRecord = state.rows[state.rows.length - 1]._doc
    state.rowsBatch = []
  },
  ALL_ROWS_LOADED(state) {
    state.moreRowsPending = false
  },
  VUEXFIRE_FETCH_STARTED(state) {
    state.loading = true
  },
  VUEXFIRE_FETCH_SUCCESS(state, payload) {
    state.loading = false
    state.rows = payload
  },
  VUEXFIRE_FETCH_ERROR(state) {
    state.loading = false
    state.rows = []
  },
  FETCH_STARTED(state) {
    state.loading = true
  },
  FETCH_SUCCESS(state, payload) {
    state.loading = false
    state.rows = payload
    state.lastFetchedRecord = payload[payload.length - 1]
    state.firstFetchedRecord = payload[0]
  },
  FETCH_ERROR(state) {
    state.loading = false
    state.rows = []
  }
}
const actions = {
  async log(context, { ref, entityName, entityId, action, values }) {
    const currentUser = store.getters['authStore/currentUser']
    const id = firebase
      .firestore()
      .collection('ids')
      .doc().id
    const log = {
      id: id,
      ref: firebase.firestore().doc(ref),
      entityName,
      entityId,
      action,
      values,
      timestamp: new Date(),
      // timestamp: firebase.firestore.Timestamp.now(),
      createdById: currentUser ? currentUser.id : null,
      createdByEmail: currentUser ? currentUser.emailAddress : null,
      name: currentUser ? currentUser.name : null,
      username: currentUser ? currentUser.username : null,
      phoneNumber: currentUser ? currentUser.phoneNumber : null
    }

    await firebase
      .firestore()
      .doc(`auditLogs/${log.id}`)
      .set(log)
    return log
  },
  // #region [ Vuexfire ]
  resetCursor({ commit }) {
    commit('RESET_CURSOR')
  },
  async doFetch({ commit }) {
    try {
      commit('FETCH_STARTED')
      const results = await firebase
        .firestore()
        .collection('auditLogs')
        .orderBy('timestamp', 'desc')
        .limit(5)
        .get()
      const logs = []
      results.forEach(res => {
        const data = res.data()
        logs.push({ ...data, timestamp: data.timestamp.toDate() })
      })
      commit('FETCH_SUCCESS', logs)
    } catch (error) {
      commit('FETCH_ERROR')
    }
  },
  async doFetchNext({ commit, getters }) {
    try {
      commit('FETCH_STARTED')
      const value = getters.lastFetchedRecord.timestamp
      const results = await firebase
        .firestore()
        .collection('auditLogs')
        .orderBy('timestamp', 'desc')
        .startAfter(value)
        .limit(5)
        .get()

      const logs = []
      results.forEach(res => {
        const data = res.data()
        logs.push({ ...data, timestamp: data.timestamp.toDate() })
      })

      commit('FETCH_SUCCESS', logs)
    } catch (error) {
      commit('FETCH_ERROR')
    }
  },
  async doFetchPrev({ commit, getters }) {
    try {
      commit('FETCH_STARTED')
      const value = getters.lastFetchedRecord.timestamp
      const results = await firebase
        .firestore()
        .collection('auditLogs')
        .orderBy('timestamp', 'desc')
        .endBefore(value)
        .limitToLast(5)
        .get()
      const logs = []
      results.forEach(res => {
        const data = res.data()
        logs.push({ ...data, timestamp: data.timestamp.toDate() })
      })
      commit('FETCH_SUCCESS', logs)
    } catch (error) {
      console.log(error)
      commit('FETCH_ERROR')
    }
  },
  doFetchByVuexfire: firestoreAction(async (context, payload) => {
    try {
      const limit = payload && payload.limit ? payload.limit : 0
      context.commit('VUEXFIRE_FETCH_STARTED')

      const customSerializer = doc => {
        // let data = doc.data()
        const data = FirebaseRepository.mapDocument(doc)
        Object.defineProperty(data, '_doc', { value: doc })
        data.createdBy = FirebaseRepository.findRelation(
          'users',
          data.createdById
        )
        return data
      }

      const db = firebase.firestore()
      let query = db.collection('auditLogs').orderBy('timestamp', 'desc')
      if (limit > 0) query = query.limit(limit)

      await context
        .bindFirestoreRef('rowsBatch', query, { serialize: customSerializer })
        .then(logs => {
          if (logs.length > 0) {
            context.commit('VUEXFIRE_FETCH_SUCCESS', logs)
            context.commit('SET_LOAD_MORE_LAST')
          }
          if (limit > 0 && logs.length < limit)
            context.commit('ALL_ROWS_LOADED')
        })
      context.commit('v_FETCH_SUCCESS')
    } catch (error) {
      context.commit('v_FETCH_ERROR')
    }
  })
  // #endregion
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}

<template>
  <div>
    <div class="intro-y flex items-center mt-8">
      <h2 class="text-lg font-medium">
        {{i18n('profile.title')}}
      </h2>
    </div>
    <div class="grid grid-cols-12 gap-6 mt-5">
      <div class="intro-y col-span-12 lg:col-span-12">
        <!-- BEGIN: Form Layout -->
        <div class="intro-y box p-5">
          <div class="w-20 h-20 sm:w-24 sm:h-24 flex-none lg:w-32 lg:h-32 image-fit relative m-auto cursor-pointer">
            <div>
              <img alt="Icewall Tailwind HTML Admin Template" class="rounded-full profile-picture" id="profilePicture"
                :src="
                  currentUser.profilePicture
                    ? currentUser.profilePicture
                    : require(`@/assets/images/profile-1.jpg`)
                " />
              <div
                class="absolute mb-1 mr-1 flex items-center justify-center bottom-0 right-0 bg-theme-17 rounded-full p-2">
                <CameraIcon class="w-4 h-4 text-white" />
              </div>
            </div>

            <input type="file" id="profileUploader" name="upload"
              style="position: absolute; top:0%; opacity:0 !important"
              class="cursor-pointer w-20 h-20 sm:w-24 sm:h-24 flex-none lg:w-32 lg:h-32 image-fit relative m-auto cursor-pointer " />
          </div>
          <div class=" flex items-center justify-center grid grid-cols-12 mt-5">
            <div class="lg:col-span-2"></div>
            <h4 class="text-lg font-medium leading-none flex items-center mr-2 lg:col-span-2 col-span-12">
              {{i18n('profile.name')}}
            </h4>
            <div class="lg:col-span-6 col-span-12 mt-2 lg:mt-0">
              <input id="crud-form-1" type="text" class="form-control w-full dark:bg-dark-9" v-model="form.name" />
            </div>
          </div>
          <div class=" flex items-center justify-center grid grid-cols-12 mt-5">
            <div class="lg:col-span-2"></div>
            <h4 class="text-lg font-medium leading-none flex items-center mr-2 lg:col-span-2 col-span-12">
              {{i18n('profile.username')}}
            </h4>
            <div class="lg:col-span-6 col-span-12 mt-2 lg:mt-0">
              <input id="crud-form-1" type="text" class="form-control w-full dark:bg-dark-9" v-model="form.username" />
            </div>
          </div>
          <div class=" flex items-center justify-center grid grid-cols-12 mt-5">
            <div class="lg:col-span-2"></div>
            <h4 class="text-lg font-medium leading-none flex items-center mr-2 lg:col-span-2 col-span-12">
              {{i18n('profile.emailAddress')}}
            </h4>
            <div class="lg:col-span-6 col-span-12 mt-2 lg:mt-0">
              <input id="crud-form-1" type="text" class="form-control w-full dark:bg-dark-9"
                v-model="form.emailAddress" />
            </div>
          </div>
          <div class=" flex items-center justify-center grid grid-cols-12 mt-5">
            <div class="lg:col-span-2"></div>
            <h4 class="text-lg font-medium leading-none flex items-center mr-2 lg:col-span-2 col-span-12">
              {{i18n('profile.phoneNumber')}}
            </h4>
            <div class="lg:col-span-6 col-span-12 mt-2 lg:mt-0">
              <input id="crud-form-1" type="text" class="form-control w-full dark:bg-dark-9"
                v-model="form.phoneNumber" />
            </div>
          </div>
          <div class="mt-5 flex justify-center">
            <button type="button" class="btn text-white bg-theme-39 mx-2 w-24" @click="doSubmit()"
              style="background-color:#d5aa0f">
              {{i18n('common.save')}}
            </button>
            <div class="btn btn-secondary" @click="goToChangePassword()">
              {{i18n('profile.changePassword')}}
            </div>
          </div>
        </div>
        <!-- END: Form Layout -->
      </div>
    </div>
    <ErrorNotification :message="errorMessage" />
    <SuccessNotification :message="i18n('profile.profileEdited')" />
  </div>
</template>

<script>
import { defineComponent, ref } from 'vue'
import ErrorNotification from '@/components/notifications/error-notification.vue'
import SuccessNotification from '@/components/notifications/success-notification.vue'
import Toastify from 'toastify-js'
import { mapActions, mapGetters } from 'vuex'
import { FileUploader } from '../../auth/file-uploader'
import lodash from 'lodash'

export default defineComponent({
  components: {
    ErrorNotification,
    SuccessNotification
  },
  computed: {
    ...mapGetters({
      currentUser: 'authStore/currentUser'
    }),
    isRTL() {
      return this.$i18n.locale == 'ar'
    },
    language() {
      return this.$i18n.locale
    }
  },
  setup() {
    const form = ref({
      name: '',
      username: '',
      emailAddress: '',
      phoneNumber: ''
    })
    const errorMessage = ref('')
    const errorNotificationToggle = () => {
      Toastify({
        node: cash('#error-notification-content').clone().removeClass('hidden')[0],
        duration: 3000,
        newWindow: true,
        close: true,
        gravity: 'top',
        position: 'right',
        stopOnFocus: true
      }).showToast()
    }

    const successNotificationToggle = () => {
      Toastify({
        node: cash('#success-notification-content').clone().removeClass('hidden')[0],
        duration: 3000,
        newWindow: true,
        close: true,
        gravity: 'top',
        position: 'right',
        stopOnFocus: true
      }).showToast()
    }

    return {
      form,
      errorMessage,
      errorNotificationToggle,
      successNotificationToggle
    }
  },
  methods: {
    ...mapActions({
      getCurrentUser: 'authStore/getCurrentUser',
      saveCurrentUser: 'authStore/saveCurrentUser',
      fetchMe: 'authStore/fetchMe',
      editProfile: 'usersStore/editProfile'
    }),
    async doSubmit() {
      const input = document.getElementById('profileUploader').files[0]
      if (this.isFormValid()) {
        if (!input) {
          await this.editProfile({ id: this.currentUser.id, form: this.form })
        } else {
          const publicUrl = await FileUploader.uploadProfilePicture(input)
          this.form.profilePicture = publicUrl
          await this.editProfile({
            id: this.currentUser.id,
            form: this.form
          })
        }
        this.successNotificationToggle()
        this.$router.push({ name: 'data-center', params: { locale: this.language } })
      } else {
        setTimeout(() => {
          this.errorNotificationToggle()
        }, 100)
      }
    },
    goToChangePassword() {
      this.$router.push({ name: 'change-password', params: { locale: this.language } })
    },
    i18n(key, args) {
      return this.$t(key, args)
    },
    isFormValid() {
      for (var key in this.form) {
        var value = this.form[key]
        if (lodash.isEmpty(value) && key != 'phoneNumber') {
          this.errorMessage = this.i18n('profile.errors.emptyField')
          return false
        }
      }
      return true
    }
  },
  mounted() {
    this.getCurrentUser()
    this.form.name = this.currentUser.name
    this.form.username = this.currentUser.username
    this.form.emailAddress = this.currentUser.emailAddress
    this.form.phoneNumber = this.currentUser.phoneNumber
    const input = document.getElementById('profileUploader')
    const profilePicture = document.getElementById('profilePicture')
    input.onchange = evt => {
      const file = input.files[0]
      if (file) {
        profilePicture.src = URL.createObjectURL(file)
      }
    }
  }
})
</script>
<style lang="scss">
.profile-picture {
  height: 128px;
  width: 128px;

  @media screen and (max-width: 700px) {
    height: 80px !important;
    width: 80px !important;
  }
}
</style>

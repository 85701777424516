<template>
  <div>
    <div class="grid grid-cols-12 gap-6 mt-5">
      <div class="intro-y col-span-12 lg:col-span-12">
        <!-- BEGIN: Form Layout -->
        <div class="intro-y box dark:bg-dark-8 p-5">
          <loading-spinner type="half-circle" :duration="1000" :size="40" color="#4469A8" :loading="loading" />
          <h2 class="text-xl font-medium mr-auto border-b pb-5">
            {{i18n('companies.addOrEdit.add')}}
          </h2>

          <div class="grid grid-cols-12 mt-5">
            <h4 class="text-lg font-medium leading-none items-center mr-2 lg:col-span-2 col-span-12">
              {{i18n('companies.addOrEdit.name')}}
            </h4>
            <div class="lg:col-span-4 col-span-12 lg:mt-0 mt-2 required">
              <input id="crud-form-1" type="text" class="form-control w-full dark:placeholder-white"
                v-model="form.name" />
            </div>
          </div>
          <!-- <div class="grid grid-cols-12 mt-5">
            <h4
              class="text-lg font-medium leading-none items-center mr-2 lg:col-span-2 col-span-12"
            >
              {{i18n('companies.addOrEdit.address')}}
            </h4>
            <div class="lg:col-span-4 col-span-12 lg:mt-0 mt-2 required">
              <input
                id="crud-form-1"
                type="text"
                class="form-control w-full dark:placeholder-white"
                v-model="form.address"
              />
            </div>
          </div> -->
          <div class="grid grid-cols-12 mt-5">
            <h4 class="text-lg font-medium leading-none items-center mr-2 lg:col-span-2 col-span-12">
              {{i18n('companies.addOrEdit.emailAddress')}}
            </h4>
            <div class="lg:col-span-4 col-span-12 lg:mt-0 mt-2">
              <input id="crud-form-1" type="text" class="form-control w-full dark:placeholder-white"
                v-model="form.email" />
            </div>
          </div>
          <div class="grid grid-cols-12 mt-5">
            <h4 class="text-lg font-medium leading-none items-center mr-2 lg:col-span-2 col-span-12">
              {{i18n('companies.addOrEdit.phoneNumber')}}
            </h4>
            <div class="lg:col-span-4 col-span-12 lg:mt-0 mt-2">
              <vue-tel-input v-model="this.form.phoneNumber" mode="international"></vue-tel-input>
            </div>
          </div>
          <!-- END -->
          <div class="mt-5">
            <button type="button" class="btn text-white bg-theme-39 w-24 cursor-pointer" @click="doSubmit()"
              style="background-color:#d5aa0f">
              {{i18n('common.save')}}
            </button>
            <button type="button" class="btn btn-secondary w-24 ml-3 mr-3 cursor-pointer" @click="doCancel()">
              {{i18n('common.cancel')}}
            </button>
          </div>
        </div>
        <!-- END: Form Layout -->
      </div>
    </div>
    <ErrorNotification :message="errorMessage" />
    <SuccessNotification :message="i18n('companies.addOrEdit.companyCreated')" />
  </div>
</template>

<script>
import { defineComponent, ref } from 'vue'
import ErrorNotification from '@/components/notifications/error-notification.vue'
import SuccessNotification from '@/components/notifications/success-notification.vue'

import Toastify from 'toastify-js'
import { mapActions, mapGetters } from 'vuex'
export default defineComponent({
  components: {
    ErrorNotification,
    SuccessNotification
  },
  computed: {
    ...mapGetters({
      currentUser: 'authStore/currentUser',
      loading: 'companyStore/loading'
    }),
    isRTL() {
      return false
    }
  },

  setup() {
    const form = ref({
      name: '',
      email: '',
      phoneNumber: '',
      phone: {}
    })
    const phone = ref(null)
    const errorMessage = ref('')
    const errorNotificationToggle = () => {
      Toastify({
        node: cash('#error-notification-content')
          .clone()
          .removeClass('hidden')[0],
        duration: 3000,
        newWindow: true,
        close: true,
        gravity: 'top',
        position: 'right',
        stopOnFocus: true
      }).showToast()
    }
    return {
      form,
      errorMessage,
      errorNotificationToggle,
      phone
    }
  },
  methods: {
    ...mapActions({
      createCompany: 'companyStore/createCompany',
      getCurrentUser: 'authStore/getCurrentUser'
    }),
    i18n(key, args) {
      return this.$t(key, args)
    },
    successNotificationWithButtonsToggle() {
      Toastify({
        node: cash('#success-notification-with-buttons')
          .clone()
          .removeClass('hidden')[0],
        duration: 3000,
        newWindow: true,
        close: true,
        gravity: 'top',
        position: 'right',
        stopOnFocus: true
        // onClick: () => {
        //   this.$router.push('/company')
        //   const element = document.getElementById(
        //     'success-notification-with-buttons'
        //   )
        //   element.remove()
        // }
      }).showToast()
    },
    successNotificationToggle() {
      Toastify({
        node: cash('#success-notification-content')
          .clone()
          .removeClass('hidden')[0],
        duration: 3000,
        newWindow: true,
        close: true,
        gravity: 'top',
        position: 'right',
        stopOnFocus: true
      }).showToast()
    },
    phoneNumberInput(data) {
      this.phone = data
    },
    async doSubmit() {
      if (this.isFormValid()) {
        await this.createCompany({ form: this.form, createdBy: this.currentUser.id, partitionId: this.$route.params.id })
        this.successNotificationToggle()
        this.$router.push({ name: 'company' })
      } else {
        setTimeout(() => {
          this.errorNotificationToggle()
        }, 100)
      }
    },
    generatePassword() {
      var generator = require('generate-password')

      this.form.password = generator.generate({
        length: 10,
        numbers: true
      })
    },
    copyPassword() {
      navigator.clipboard.writeText(this.form.password)
      this.successNotificationToggle()
    },
    doCancel() {
      this.$router.back()
    },
    isFormValid() {
      for (var key in this.form) {
        var value = this.form[key]
        if (value.length == 0 && key != 'phoneNumber' && key != 'email') {
          this.errorMessage = this.i18n('companies.addOrEdit.errors.emptyField')
          return false
        }
      }
      if (this.form.email && !this.validEmail(this.form.email)) {
        this.errorMessage = this.i18n('companies.addOrEdit.errors.invalidEmail')
        return false
      }
      return true
    },
    validEmail(email) {
      return String(email)
        .toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        )
    }
    // i18n(key, args) {
    //   return this.$t(key, args)
    // }
  },
  mounted() {
    this.getCurrentUser()
  }
})
</script>
<style lang="scss">

</style>

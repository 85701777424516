<template>
  <div>
    <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
      <h2 class="text-lg font-medium ">{{ i18n('auditLogs.title') }}</h2>
    </div>

    <div class="intro-y box p-5 mt-5" v-if="!loading">
      <!-- <div class="flex flex-col sm:flex-row sm:items-end xl:items-start">
        <form
          id="tabulator-html-filter-form"
          class="xl:flex"
          :class="isRTL ? 'sm:ml-auto' : 'sm:mr-auto'"
        >
          <div class="sm:flex items-center sm:mr-4">
            <label class="w-12 flex-none xl:w-auto xl:flex-initial mr-2 ml-2">{{ i18n('auditLogs.filter.field') }}</label>
            <select
              id="tabulator-html-filter-field"
              v-model="filter.field"
              class="form-select w-full sm:w-32 2xl:w-full mt-2 sm:mt-0 sm:w-auto dark:bg-dark-9"
            >
              <option value="name">{{ i18n('auditLogs.filter.name') }}</option>
              <option value="emailAddress">{{ i18n('auditLogs.filter.emailAddress') }}</option>
              <option value="phoneNumber">{{ i18n('auditLogs.filter.phoneNumber') }}</option>
            </select>
          </div>
          <div class="sm:flex items-center sm:mr-4 mt-2 xl:mt-0">
            <label class="w-12 flex-none xl:w-auto xl:flex-initial mr-2 ml-2">{{ i18n('auditLogs.filter.value') }}</label>
            <input
              id="tabulator-html-filter-value"
              v-model="filter.value"
              type="text"
              class="form-control sm:w-40 2xl:w-full mt-2 sm:mt-0 dark:bg-dark-9"
            />
          </div>
          <div class="mt-2 xl:mt-0">
            <button
              id="tabulator-html-filter-go"
              type="button"
              class="btn text-white bg-theme-39 w-full sm:w-16 sm:mr-3"
              @click="filterAuditLogs()"
            >
              {{ i18n('auditLogs.filter.go') }}
            </button>
            <button
              id="tabulator-html-filter-reset"
              type="button"
              class="btn btn-secondary dark:text-black w-full sm:w-24 mt-2 sm:mt-0 sm:ml-1 sm:mr-1"
              @click="fetchAuditLogs()"
            >
              {{ i18n('auditLogs.filter.reset') }}
            </button>
          </div>
        </form>
      </div> -->
      <div class="overflow-x-auto">
        <div v-if="rows.length > 0">
          <table class="table table-report sm:mt-2">
            <thead>
              <tr>
                <th class="whitespace-nowrap">#</th>
                <th class="text-center whitespace-nowrap">
                  {{ i18n('auditLogs.fields.action') }}
                </th>
                <th class="text-center whitespace-nowrap">
                  {{ i18n('auditLogs.fields.entityName') }}
                </th>
                <th class="text-center whitespace-nowrap">
                  {{ i18n('auditLogs.fields.entityId') }}
                </th>
                <th class="text-center whitespace-nowrap">
                  {{ i18n('auditLogs.fields.createdBy') }}
                </th>
                <th class="text-center whitespace-nowrap">
                  {{ i18n('auditLogs.fields.createdAt') }}
                </th>
                <!-- <th class="text-center whitespace-nowrap">
                 {{ i18n('auditLogs.fields.ref') }}
                </th> -->
                <th class="text-center whitespace-nowrap">
                  {{ i18n('auditLogs.fields.values') }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(row, index) in rows" :key="index">
                <td class="">
                  <div class="text-gray-600 text-xs whitespace-nowrap mt-0.5">
                    #{{ (page + 1) * 5 - 5 + index + 1 }}
                  </div>
                </td>
                <td class="text-center">
                  <div
                    class="font-medium whitespace-nowrap"
                    style="color: green;"
                  >
                    {{
                      i18n(`auditLogs.actions.${row.action}`, [row.entityName])
                    }}
                  </div>
                </td>
                <td
                  :class="
                    row.action === 'external-share-file' ? '' : 'text-center'
                  "
                >
                  <div href="" class="font-medium whitespace-nowrap ">
                    <!-- {{ row.entityName }} -->
                    <span v-if="row.action === 'external-share-file'"
                      >File name:
                    </span>
                    <span style="color: blue;">{{
                      presenterName(row.values, 'name')
                    }}</span>

                    <div
                      v-if="row.action === 'external-share-file' && row.values"
                    >
                      <div
                        v-for="(email, index) in row.values.users"
                        :key="index"
                      >
                        Receiver: <span style="color: blue;">{{ email }}</span>
                      </div>
                    </div>
                  </div>
                </td>
                <td class="text-center">
                  <div href="" class="font-medium whitespace-nowrap ">
                    {{ row.entityId }}
                  </div>
                </td>
                <td class="">
                  <div class="font-medium whitespace-nowrap ">
                    <div
                      style="display: flex; align-items: center;"
                      v-if="row.username"
                    >
                      <span
                        class="material-icons-outlined"
                        style="font-size: 1.125rem; padding-left: 6px;padding-right: 6px"
                        >person</span
                      >
                      <span style="color: blue;">{{ row.username }}</span>
                    </div>
                    <div
                      style="display: flex; align-items: center;"
                      v-if="row.phoneNumber"
                    >
                      <span
                        class="material-icons-outlined"
                        style="font-size: 1.125rem; padding-left: 6px;padding-right: 6px"
                        >phone</span
                      >
                      <span style="color: blue;">{{ row.phoneNumber }}</span>
                    </div>
                    <div
                      style="display: flex; align-items: center;"
                      v-if="row.createdByEmail"
                    >
                      <span
                        class="material-icons-outlined"
                        style="font-size: 1.125rem; padding-left: 6px;padding-right: 6px"
                        >email</span
                      >
                      <span style="color: blue;">{{ row.createdByEmail }}</span>
                    </div>
                  </div>
                </td>
                <td class="text-center">
                  <div class="font-medium whitespace-nowrap ">
                    <div>{{ presenterDate(row, 'timestamp') }}</div>
                    {{ presenterTime(row, 'timestamp') }}
                  </div>
                </td>
                <td class="table-report__action w-56">
                  <div class="flex justify-center items-center cursor-pointer">
                    <a
                      href="javascript:"
                      data-toggle="modal"
                      data-target="#view-details-modal-preview"
                      class="mr-2"
                      @click="viewValues(row.values)"
                      ><EyeIcon class="w-6 h-6 mr-1"
                    /></a>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <!-- <div class="flex justify-center items-center" style="margin: 100px" v-else>
          <h1 class="text-4xl">{{ i18n('auditLogs.noAuditLogs') }}</h1>
        </div> -->
        <div
          class="col-span-12 items-center flex justify-center"
          style="height: 50vh;"
          v-else
        >
          <div>
            <div class="flex justify-center">
              <ActivityIcon style="height:125px; width: 125px;" />
            </div>
            <h3 class="font-medium text-3xl mt-5">
              {{ i18n('auditLogs.noAuditLogs') }}
            </h3>
          </div>
        </div>
      </div>
      <paginationVue
        :pageCount="pageCount"
        :page="page"
        @prevPage="prevPage"
        @nextPage="nextPage"
      />
    </div>
    <div v-else style="width:100%; height:80vh;" class="flex justify-center">
      <LoadingIcon icon="grid" class="" style="width:100px" />
    </div>

    <ViewDetailsModal
      :record="selectedData"
      :serverPath="serverPath"
      :showViewDetailsModal="true"
      @close="fetchFiles()"
    />
  </div>
</template>

<script>
import ViewDetailsModal from './view-details.vue'
import { defineComponent, ref, reactive } from 'vue'
import { mapActions, mapGetters } from 'vuex'
import paginationVue from '../../components/pagination/pagination.vue'
// import xlsx from 'xlsx'
// import feather from 'feather-icons'
import moment from 'moment'
// import {helpers} from '../utils/helpers'

export default defineComponent({
  components: {
    ViewDetailsModal,
    paginationVue
  },
  setup() {
    // const rows = ref([])
    const tableLimit = ref(10)
    const auditLogs = ref([])
    const tableRef = ref()
    const tabulator = ref()
    const selected = ref([])
    const selectAll = ref(false)
    const selectedUser = ref(null)
    const selectedData = ref(null)
    const page = ref(0)
    const size = ref(5)
    const itemIndex = ref(0)
    const filter = reactive({
      field: 'name',
      value: ''
    })
    const onFilter = () => {
      tabulator.value.setFilter(filter.field, filter.type, filter.value)
    }
    const onResetFilter = () => {
      filter.field = 'name'
      filter.value = ''
      onFilter()
    }
    return {
      selectedUser,
      selected,
      selectAll,
      tableLimit,
      tableRef,
      filter,
      onFilter,
      onResetFilter,
      auditLogs,
      selectedData,
      page,
      size,
      itemIndex,
      paginationVue
    }
  },
  computed: {
    ...mapGetters({
      currentUser: 'authStore/currentUser',
      loading: 'auditLogStore/loading',
      rows: 'auditLogStore/rows'
    }),
    pageCount() {
      const l = this.auditLogs.length
      const s = this.size
      return Math.ceil(l / s)
    },
    // paginated() {
    //   const start = this.page * this.size
    //   const end = start + this.size
    //   return this.auditLogs.slice(start, end)
    // },
    isRTL() {
      return this.$i18n.locale == 'ar'
    },
    currentLanguageCode() {
      return this.$i18n.locale
    },
    selectedAuditLogs() {
      return this.rows.filter(el => {
        return el.selected
      })
    }
  },
  methods: {
    ...mapActions({
      getCurrentUser: 'authStore/getCurrentUser',
      saveCurrentUser: 'authStore/saveCurrentUser',
      doFetch: 'auditLogStore/doFetch',
      doFetchNext: 'auditLogStore/doFetchNext',
      doFetchPrev: 'auditLogStore/doFetchPrev',
      doFilter: 'auditLogStore/doFilterAuditLogs'
    }),
    async nextPage() {
      await this.doFetchNext()
      this.page++
    },
    async prevPage() {
      await this.doFetchPrev()
      this.page--
    },
    i18n(key, args) {
      return this.$t(key, args)
    },
    select() {
      if (!this.selectAll) {
        this.rows.forEach(element => {
          element.selected = true
          this.selectedUsers.push(element.id)
        })
      } else {
        this.rows.forEach(element => {
          element.selected = false
          this.selectedUsers = []
        })
      }
    },
    formatDate(date) {
      return moment.unix(date.seconds).format('DD/MM/YYYY')
    },
    presenterName(row, fieldName) {
      const val = row[fieldName]
      // return val ? val.substring(0, val.lastIndexOf('?')) : 'ـــ'
      return val && val.includes('?:')
        ? val.substring(0, val.lastIndexOf('?'))
        : val
    },
    presenterDateTime(row, fieldName) {
      const val = row[fieldName]
      return val
        ? moment(val)
            .locale(this.currentLanguageCode)
            .format('DD-MM-YYYY h:mm A')
        : 'ـــ'
    },
    presenterDate(row, fieldName) {
      const val = row[fieldName]
      return val
        ? moment(val)
            .locale(this.currentLanguageCode)
            .format('D MMM, YYYY')
        : 'ـــ'
    },
    presenterDay(row, fieldName) {
      const val = row[fieldName]
      return val
        ? moment(val)
            .locale(this.currentLanguageCode)
            .format('dddd')
        : 'ـــ'
    },
    presenterTime(row, fieldName) {
      const date = row[fieldName]
      return moment(date, "YYYY-MM-DD'T'hh:mm:ss.SSS'Z'")
        .locale(this.currentLanguageCode)
        .format('hh:mm a')
    },
    filterAuditLogs() {
      this.auditLogs = this.rows.filter(el => {
        return el[this.filter.field].includes(this.filter.value)
      })
    },
    viewValues(values) {
      this.selectedData = values
    }
  },
  async mounted() {
    await this.doFetch()
    this.selectAll = false
  }
})
</script>
<style scoped>
.table-report:not(.table-report--bordered):not(.table-report--tabulator) tr {
  border-radius: 0px !important;
  box-shadow: 15px 0px 15px #0000000b;
}

.table-report:not(.table-report--bordered):not(.table-report--tabulator) td {
  border-radius: 0px !important;
  box-shadow: unset !important;
}

.pagination-ar {
  margin-right: unset !important;
  margin-left: auto !important;
}
</style>

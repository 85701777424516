/* eslint-disable */
import { vuexfireMutations } from 'vuexfire'
import FirebaseRepository from '../shared/firebase/firebase-repository'
import firebase from 'firebase/compat/app'
import 'firebase/compat/firestore'
import 'firebase/compat/storage'
const state = () => {
  return {
    rows: [],
    filesInFirestore: [],
    loading: false,
    record: null,
    lastPage: null
  }
}
const getters = {
  rows: state => state.rows,
  loading: state => state.loading,
  record: state => state.record,
  lastPage: state => state.lastPage,
  filesInFirestore: state => state.filesInFirestore
}
const mutations = {
  ...vuexfireMutations,
  SET_LAST_PAGE(state, payload) {
    state.lastPage = payload
  },
  FETCH_START(state) {
    state.loading = true
  },
  FETCH_SUCCESS(state) {
    state.loading = false
    // state.rows = payload
  },
  FETCH_ERROR(state) {
    state.loading = false
  },
  ADD_TO_ROWS(state, payload) {
    state.rows.push(payload)
  },
  RESET_ROWS(state) {
    state.rows = []
  },
  ADD_TO_FIRESTORE(state, payload) {
    state.filesInFirestore.push(payload)
  },
  FETCH_FIRESTORE_STARTED(state) {
    state.loading = true
    state.filesInFirestore = []
  },
  FETCH_FIRESTORE_SUCCESS(state, payload) {
    state.loading = false
    state.filesInFirestore = payload
  },
  FETCH_FIRESTORE_ERROR(state) {
    state.loading = false
    state.filesInFirestore = []
  },
  ADD_ROWS(state, payload) {
    state.rows = payload
  }
}
const actions = {
  async doSearch({ commit }, { search, currentUser }) {
    const {
      partition: partitions,
      company: companies,
      department: departments
    } = currentUser

    let companiesIds = companies.map(company => company.id)
    let departmentsIds = departments.map(department => department.id)

    commit('RESET_ROWS')
    commit('FETCH_START')
    let strSearch = search
    let strlength = strSearch.length
    let strFrontCode = strSearch.slice(0, strlength - 1)
    let strEndCode = strSearch.slice(strlength - 1, strSearch.length)
    let startcode = strSearch
    let endcode =
      strFrontCode + String.fromCharCode(strEndCode.charCodeAt(0) + 1)
    try {
      const firebaseSearchResults = await firebase
        .firestore()
        .collection('files')
        .orderBy('name')
        .where('name', '>=', startcode)
        .where('name', '<', endcode)
        .where('departmentId', 'in', [...departmentsIds, ...companiesIds])
        .get()
      const searchResults = FirebaseRepository.mapCollection(
        firebaseSearchResults
      )
      commit('FETCH_SUCCESS')
      commit('ADD_ROWS', searchResults)
    } catch (err) {
      commit('FETCH_SUCCESS')
      console.log(err)
    }
  },

  async doFetch({ commit, dispatch }, { path, currentUser, lastPage }) {
    const {
      partition: partitions,
      company: companies,
      department: departments
    } = currentUser

    // if (!lastPage) {
    commit('RESET_ROWS')
    commit('FETCH_START')
    // }

    path = path || 'Data Center'

    var storageRef = firebase.storage().ref(path)
    const result = await storageRef.listAll()
    // let result = null

    // if (!lastPage) {
    //   result = await storageRef.list({ maxResults: 18 })
    // } else if (lastPage.nextPageToken) {
    //   result = await storageRef.list({
    //     maxResults: 18,
    //     pageToken: lastPage.nextPageToken
    //   })
    // } else {
    //   commit('FETCH_SUCCESS')
    //   return
    // }
    // commit('SET_LAST_PAGE', result)

    for (let index = 0; index < result.items.length; index++) {
      if (result.items[index].name != 'starterFile') {
        await dispatch('getDownloadUrl', result.items[index])
      }
    }

    const pathLength = path.split('/').length

    let prefixes = result.prefixes

    if (pathLength === 1 && partitions) {
      const filteredPartitionsId = partitions.map(part => part.id)
      prefixes = prefixes.filter(prefix =>
        filteredPartitionsId.includes(
          prefix.fullPath.slice(
            prefix.fullPath.indexOf('/') + 1,
            prefix.fullPath.lastIndexOf('?:')
          )
        )
      )
    }

    if (pathLength === 2 && companies) {
      const filteredCompaniesId = companies?.map(comp => comp.id)
      prefixes = prefixes.filter(prefix =>
        filteredCompaniesId.includes(
          prefix.fullPath.split('/')[2].split('?:')[0]
        )
      )
    }

    if (pathLength === 3 && departments) {
      const filteredDepartmentsId = departments.map(dep => dep.id)
      prefixes = prefixes?.filter(prefix => {
        if (prefix.fullPath?.includes('foldername')) {
          return filteredDepartmentsId.includes(
            prefix.fullPath?.slice(prefix.fullPath.lastIndexOf('?:') + 2)
          )
        } else {
          return filteredDepartmentsId.includes(
            prefix.fullPath.split('/')[3].split('?:')[0]
          )
        }
      })
    }

    for (let index = 0; index < prefixes.length; index++) {
      const folder = prefixes[index]
      if (folder.name != 'trash') {
        let folderNaming = null

        if (pathLength <= 3 && !folder.name.startsWith('foldername')) {
          const refId = folder.name.substr(0, folder.name.indexOf('?:'))
          if (refId) {
            if (path.includes('Data Center/')) {
              let partitionId = path
                .substr(0, path.lastIndexOf('?:'))
                .replace('Data Center/', '')
              if (partitionId) {
                if (pathLength == 2) {
                  const company = await FirebaseRepository.findDocument(
                    `partition/${partitionId}/company`,
                    refId
                  )
                  folderNaming = company ? company.name : null
                } else {
                  partitionId = path.split('/')[1].split('?:')[0]
                  const companyId = path.split('/')[2].split('?:')[0]
                  const department = await FirebaseRepository.findDocument(
                    `partition/${partitionId}/company/${companyId}/department`,
                    refId
                  )
                  folderNaming = department ? department.name : null
                }
              }
            } else {
              const partition = await FirebaseRepository.findDocument(
                'partition',
                refId
              )
              folderNaming = partition ? partition.name : null
            }
          }
        }
        const ID = folder.name.substr(folder.name.indexOf('?:') + 2)
        const folderInFirestore = await FirebaseRepository.findDocument(
          'files',
          ID
        )

        const folderName =
          folderNaming ||
          (folderInFirestore && folderInFirestore.name
            ? folderInFirestore.name
            : folder.name.substr(0, folder.name.indexOf('?:')))

        commit('ADD_TO_ROWS', {
          url: folder.fullPath,
          name: folderName,
          type: 'folder',
          company: folder.companyName,
          id: ID
        })
      }
    }

    commit('FETCH_SUCCESS')
  },

  async doFetchShared({ commit }, currentUser) {
    try {
      commit('FETCH_FIRESTORE_STARTED')

      const filesSharedWithMe = FirebaseRepository.mapCollection(
        await firebase
          .firestore()
          .collection('files')
          .orderBy('state')
          .orderBy('createdAt')
          .where('readAccess', 'array-contains', currentUser.id)
          .where('location', '==', 'DC')
          .where('state', '!=', 'deleted')
          .get()
      )
    } catch {}
  },
  async doFetchFirestore({ commit }, currentUser) {
    try {
      // debugger
      // commit('RESET_ROWS')
      commit('FETCH_FIRESTORE_STARTED')
      const filesInOwnDepartment = FirebaseRepository.mapCollection(
        await firebase
          .firestore()
          .collection('files')
          .orderBy('state')
          .orderBy('createdAt')
          .where('department', '==', currentUser.department.id)
          .where('location', '==', 'DC')
          .where('state', '!=', 'deleted')
          .get()
      )
      const filesSharedWithMe = FirebaseRepository.mapCollection(
        await firebase
          .firestore()
          .collection('files')
          .orderBy('state')
          .orderBy('createdAt')
          .where('readAccess', 'array-contains', currentUser.id)
          .where('location', '==', 'DC')
          .where('state', '!=', 'deleted')
          .get()
      )
      let files = [...filesInOwnDepartment, ...filesSharedWithMe]
      const uniqueIds = new Set()
      const uniqueFiles = files.filter(file => {
        const isDuplicate = uniqueIds.has(file.id)
        uniqueIds.add(file.id)
        // return !isDuplicate
        if (!isDuplicate) {
          return true
        }
        return false
      })
      commit('FETCH_FIRESTORE_SUCCESS', uniqueFiles)
    } catch (error) {
      console.error(error)
      commit('FETCH_FIRESTORE_ERROR')
    }
  },
  async shareFile(context, payload) {
    firebase
      .firestore()
      .collection('email')
      .add({
        to: payload.users,
        message: {
          subject: 'A File has been shared with you',
          html: 'This is the file ',
          attachments: [{ path: payload.fileUrl, filename: payload.name }]
        }
      })
    if (payload.usersIDs) {
      firebase
        .firestore()
        .collection('files')
        .doc(payload.fileId)
        .update({
          readAccess: firebase.firestore.FieldValue.arrayUnion(
            ...payload.usersIDs
          )
        })
    }
  },
  async getDownloadUrl({ commit }, imageRef) {
    // debugger
    const type = getFileType(
      imageRef.name.substr(0, imageRef.name.indexOf('?:'))
    )
    const url = await imageRef.getDownloadURL()
    const ID = imageRef.name.substr(imageRef.name.indexOf('?:') + 2)
    const fileInFirestore = await FirebaseRepository.findDocument('files', ID)
    const fileName =
      fileInFirestore && fileInFirestore.name
        ? fileInFirestore.name
        : imageRef.name.substr(0, imageRef.name.indexOf('?:'))
    commit('ADD_TO_ROWS', {
      name: fileName,
      url,
      type: type,
      id: ID
    })
  }
}

function getFileType(name) {
  const images = ['png', 'jpg', 'jpeg', 'webp', 'psd']
  const extension = name.split('.').pop()
  if (images.includes(extension)) {
    return 'image'
  } else {
    return extension
  }
}
function getCompanyNumber(path) {
  // debugger
  const company = path.split('/')[1]
  return company.substr(company.indexOf('?:') + 2, 2)
}
function getDepartmentNumber(path) {
  const pathArray = path.split('/')
  if (pathArray.length > 2) {
    const department = pathArray[2]
    return department.substr(department.indexOf('?:') + 2, 2)
  } else {
    return '00'
  }
}

function getCompanyID(path) {
  const company = path.split('/')[1]
  return company.substr(0, company.lastIndexOf('?:'))
}
function getDepartmentID(path) {
  const pathArray = path.split('/')
  if (pathArray.length > 2) {
    const department = pathArray[2]
    return department.substr(0, department.lastIndexOf('?:'))
  } else {
    return null
  }
}
function getFolderID(path) {
  const pathArray = path.split('/')
  if (pathArray.length > 1) {
    const lastElement = pathArray[pathArray.length - 1]
    const folderId = lastElement.substr(
      lastElement.indexOf('?:') + 2,
      lastElement.length
    )
    return folderId
  }
  return null
}
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}

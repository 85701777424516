import { createRouter, createWebHistory } from 'vue-router'
import SideMenu from '../layouts/side-menu/Main.vue'
import DataCenter from '../views/data-center/MainDetailsView.vue'
import dataCenterEmployee from '../views/data-center-employee/Main.vue'
import Share from '../views/share-files/Main.vue'
import Login from '../views/login/Main.vue'

import Register from '../views/register/Main.vue'
import ErrorPage from '../views/error-page/Main.vue'

import Users from '../views/users/users-list-page.vue'
import CreateUser from '../views/users/create-user.vue'
import EditUser from '../views/users/edit-user.vue'

import Companies from '../views/companies/company-list-page.vue'
import CreateCompany from '../views/companies/create-company.vue'
import EditCompany from '../views/companies/edit-company.vue'

import Partitions from '../views/partitions/partition-list-page.vue'
import CreatePartition from '../views/partitions/create-partition.vue'
import EditPartition from '../views/partitions/edit-partition.vue'

import Departments from '../views/department/department-list-page.vue'
import CreateDepartment from '../views/department/create-department.vue'
import EditDepartment from '../views/department/edit-department.vue'

import Profile from '../views/profile/profile.vue'
import ChangePassword from '../views/profile/change-password.vue'

import ChangeUserPassword from '../views/users/change-user-password.vue'

import WordEditor from '../components/editor/word-editor.vue'
import PdfViewer from '../components/editor/pdf-viewer.vue'
import AuditLogs from '../views/auditLogs/auditLogs-list-page.vue'
import Notifications from '../views/notifications/Main.vue'
import Settings from '../views/settings/settings.vue'

import authGuardMixin from './authGuardMixin'

const routes = [
  {
    path: '/:locale?',
    meta: { auth: true },
    component: SideMenu,
    children: [
      {
        path: 'data-center',
        name: 'data-center',
        component: DataCenter
      },
      {
        path: 'data-center/:folderName?',
        name: 'data-center/:folderName?',
        component: DataCenter
      },
      {
        path: 'data-center-employee',
        name: 'data-center-employee',
        component: dataCenterEmployee
      },
      {
        path: 'share',
        name: 'share',
        component: Share
      },
      {
        path: 'users',
        name: 'users',
        component: Users
      },
      {
        path: 'users/add',
        name: 'users/add',
        component: CreateUser
      },
      {
        path: 'users/edit/:id',
        name: 'userEdit',
        component: EditUser
      },
      {
        path: 'partition',
        name: 'partition',
        component: Partitions
      },
      {
        path: 'partition/add',
        name: 'partition/add',
        component: CreatePartition
      },
      {
        path: 'partition/edit/:id',
        name: 'partition/edit/:id',
        component: EditPartition
      },
      {
        path: 'partition/:id/company',
        name: 'company',
        component: Companies
      },
      {
        path: 'partition/:id/company/add',
        name: 'company/add',
        component: CreateCompany
      },
      {
        path: 'partition/:partitionId/company/edit/:id',
        name: 'company/edit/:id',
        component: EditCompany
      },
      {
        path: 'partition/:partitionId/company/:id/department',
        name: 'department',
        component: Departments
      },
      {
        path: 'partition/:partitionId/company/:id/department/add',
        name: 'department/add',
        component: CreateDepartment
      },
      {
        path: 'partition/:partitionId/company/:companyId/department/edit/:id',
        name: 'department/edit/:id',
        component: EditDepartment
      },
      {
        path: 'profile',
        name: 'profile',
        component: Profile
      },
      {
        path: 'change-password',
        name: 'change-password',
        component: ChangePassword
      },
      {
        path: 'change-user-password/:id',
        name: 'change-user-password',
        component: ChangeUserPassword
      },
      {
        path: 'auditLogs',
        name: 'auditLogs',
        component: AuditLogs
      },
      {
        path: 'notifications',
        name: 'notifications',
        component: Notifications
      },
      {
        path: 'settings',
        name: 'settings',
        component: Settings
      }
    ]
  },
  {
    path: '/:locale/word/:id',
    name: '/word/:id',
    meta: { auth: true },
    component: WordEditor
  },
  {
    path: '/:locale/pdf/:id',
    name: '/pdf/:id',
    meta: { auth: true },
    component: PdfViewer
  },
  {
    path: '/:locale/auth/login',
    name: 'login',
    component: Login
  },
  {
    path: '/:locale/auth/register',
    name: 'register',
    component: Register
  },
  // {
  //   path: '/auth/forgot-password',
  //   name: 'password',
  //   component: ForgotPassword
  // },
  {
    path: '/error-page',
    name: 'error-page',
    component: ErrorPage
  },
  {
    path: '/:pathMatch(.*)*',
    component: ErrorPage
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    return savedPosition || { left: 0, top: 0 }
  }
})
router.beforeEach(async (to, from, next) => {
  await authGuardMixin.beforeRouteEnter(to, from, next)
  // let { locale } = to.params
  // if (!locale) {
  //   locale = 'default-locale'
  // }

  // // Do something with locale, check availability of messages etc.
  // i18n.locale = locale
  // next()
  // await unauthGuardMixin.beforeRouteEnter(to, from, next)
})

// function setLocale(to, from, next) {
//   let { locale } = to.params
//   if (!locale) {
//     locale = 'default-locale'
//   }

//   // Do something with locale, check availability of messages etc.
//   i18n.locale = locale
//   next()
// }

export default router

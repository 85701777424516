<template>
  <div id="success-notification-content" class="toastify-content hidden flex">
    <CheckCircleIcon class="text-theme-37" />
    <div class="ml-4 mr-4">
      <div class="font-medium">{{ i18n('common.success') }}</div>
      <div class="text-gray-600 mt-1">
        {{ message }}
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    message: {
      type: String
    }
  },
  methods: {
    i18n(key, args) {
      return this.$t(key, args)
    }
  }
})
</script>

<style></style>

<template>
  <!-- BEGIN: Modal Content -->
  <div
    id="view-details-modal-preview"
    class="modal"
    tabindex="-1"
    aria-hidden="true"
    v-if="modalShow"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <!-- BEGIN: Modal Header -->
        <div class="modal-header">
          <h2 class="font-medium text-base">
            {{ i18n('auditLogs.ViewDetails') }}
          </h2>
        </div>
        <!-- END: Modal Header -->
        <!-- BEGIN: Modal Body -->
        <div class="p-5" v-if="record">
          <table class="table table-report sm:mt-2 border-separate border border-slate-500">
            <!-- <thead>
              <tr>
                <th class=" whitespace-nowrap">
                  {{ i18n('auditLogs.fields.fieldName') }}
                </th>
                <th class="text-center whitespace-nowrap">
                  {{ i18n('auditLogs.fields.fieldValue') }}
                </th>
              </tr>
            </thead> -->
            <tbody>
              <tr v-for="(fieldName, index) in Object.keys(record)" :key="index">
                <td class="">
                  <div class="font-medium whitespace-nowrap">
                    {{ fieldName  }}
                  </div>
                </td>
                <td class="text-center">
                  <div class="font-medium whitespace-wrap" style="word-break: break-all;">
                    {{ record[fieldName] }}
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
          <!-- <div v-for="fieldName in Object.keys(record)" :key="fieldName">
            <label for="regular-form-1" class="form-label">{{ fieldName }}</label>
            <div>{{ record[fieldName] }}</div>
          </div> -->
        </div>
      </div>
    </div>
  </div>
  <!-- END: Modal Content -->
</template>

<script>
import { defineComponent, ref } from 'vue'
import Toastify from 'toastify-js'
// import FirebaseRepository from '../../shared/firebase/firebase-repository'

export default defineComponent({
  props: [
    'record',
    'showViewDetailsModal'
  ],
  data() {
    return {
      modalShow: this.showViewDetailsModal
    }
  },
  setup(props) {
    const name = ref('')
    const hideModal = () => {
      cash('#view-details-modal-preview').modal('hide')
    }
    const errorNotificationToggle = () => {
      Toastify({
        node: cash('#error-notification-folder').clone().removeClass('hidden')[0],
        duration: 3000,
        newWindow: true,
        close: true,
        gravity: 'top',
        position: 'right',
        stopOnFocus: true
      }).showToast()
    }
    return {
      name,
      hideModal,
      errorNotificationToggle
    }
  },
  computed: {
    // values() {
    //   return this.record
    //     ? FirebaseRepository.mapDocument(this.record)
    //     : this.record
    // }
  },
  methods: {
    i18n(key, args) {
      return this.$t(key, args)
    },
    doSave() {
      this.hideModal()
      this.$emit('close')
    }
  }
})
</script>
<style></style>

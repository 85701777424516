import store from './index'
import lodash from 'lodash'
import Toastify from 'toastify-js'
import FirebaseRepository from '../shared/firebase/firebase-repository'
import { firestoreAction, vuexfireMutations } from 'vuexfire'
import firebase from 'firebase/compat/app'
import 'firebase/compat/firestore'
// import { doc, onSnapshot } from 'firebase/firestore'
import { collection, query, orderBy, onSnapshot } from 'firebase/firestore'

const state = () => {
  return {
    rows: [],
    loading: false,
    record: null,
    loadMoreLastRecord: null,
    moreRowsPending: true,
    rowsBatch: [],
    isFirstFetch: true
  }
}
const getters = {
  rows: state => state.rows,
  loading: state => state.loading,
  record: state => state.record,
  loadMoreLastRecord: state => state.loadMoreLastRecord,
  moreRows: state => state.moreRowsPending,
  rowsBatch: state => state.rowsBatch || [],
  isFirstFetch: state => state.isFirstFetch
}
const mutations = {
  ...vuexfireMutations,
  FETCH_STARTED(state) {
    state.loading = true
  },
  FETCH_SUCCESS(state) {
    state.loading = false
  },
  FETCH_ERROR(state) {
    state.loading = false
  },
  ADD_TO_ROWS(state, payload) {
    state.rows.push(payload)
  },
  RESET_ROWS(state) {
    state.rows = []
  },
  RESET_CURSOR(state) {
    state.loadMoreLastRecord = null
    state.moreRowsPending = true
    state.rowsBatch = []
    state.rows = []
  },
  ADD_AT_FIRST_ROWS(state, payload) {
    const myArray = state.rows
    myArray.unshift(payload)
    state.rows = myArray
  },
  MERGE_ROWS_BATCH(state, payload) {
    const myArray = lodash.concat(state.rows, payload)
    state.rows = lodash.uniqWith(myArray, lodash.isEqual)
  },
  SET_LOAD_MORE_LAST(state) {
    state.loadMoreLastRecord = state.rows[state.rows.length - 1]._doc
    state.rowsBatch = []
  },
  ALL_ROWS_LOADED(state) {
    state.moreRowsPending = false
  },
  VUEXFIRE_FETCH_STARTED(state) {
    state.loading = true
  },
  VUEXFIRE_FETCH_SUCCESS(state, payload) {
    state.loading = false
    state.rows = payload
  },
  VUEXFIRE_FETCH_ERROR(state) {
    state.loading = false
    state.rows = []
  },
  SET_FIRST_FETCH(state, payload) {
    state.isFirstFetch = !!payload
  }
}
const actions = {
  resetCursor({ commit }) {
    commit('RESET_CURSOR')
  },
  doFetchByVuexfire: firestoreAction(async (context, payload) => {
    try {
      const limit = payload && payload.limit ? payload.limit : 0
      context.commit('VUEXFIRE_FETCH_STARTED')

      const customSerializer = doc => {
        // let data = doc.data()
        const data = FirebaseRepository.mapDocument(doc)
        Object.defineProperty(data, '_doc', { value: doc })
        // if (context.getters['isFirstFetch']) {}
        return data
      }

      const currentUser = store.getters['authStore/currentUser']
      const db = firebase.firestore()
      let query = db
        .collection(`users/${currentUser.id}/notifications`)
        .orderBy('createdAt', 'desc')
      if (limit > 0) query = query.limit(limit)

      await context
        .bindFirestoreRef('rowsBatch', query, {
          serialize: customSerializer
        })
        .then(notifications => {
          // debugger
          context.commit('SET_FIRST_FETCH', false)
          if (notifications.length > 0) {
            context.commit('VUEXFIRE_FETCH_SUCCESS', notifications)
          }
        })
      context.commit('FETCH_SUCCESS')
    } catch (error) {
      context.commit('FETCH_ERROR')
    }
  }),

  doRunNotificationListener({ commit, dispatch }) {
    const currentUser = store.getters['authStore/currentUser']
    const db = firebase.firestore()
    const q = query(
      collection(db, `users/${currentUser.id}/notifications`),
      orderBy('createdAt', 'desc')
    )
    onSnapshot(q, querySnapshot => {
      const notifications = []
      querySnapshot.forEach(doc => {
        const notification = FirebaseRepository.mapDocument(doc)
        notifications.push(notification)
        if (notification.isNew) {
          dispatch('NewNotification', notification)
        }
      })
      this.notifications = notifications
      commit('VUEXFIRE_FETCH_SUCCESS', notifications)
    })
  },
  NewNotification({ getters }, notification) {
    Toastify({
      text: notification.en,
      duration: 3000,
      newWindow: true,
      close: true,
      gravity: 'top',
      position: 'right',
      stopOnFocus: true,
      className: 'toastify-content'
      // style: {
      //   // background: 'linear-gradient(to right, #00b09b, #96c93d)',
      //   background: '#FFFFFF',
      //   padding: '20px 40px 20px 20px'
      // }
      // onClick: () => {
      //   this.$router.push('/company')
      //   const element = document.getElementById(
      //     'success-notification-with-buttons'
      //   )
      //   element.remove()
      // }
    }).showToast()
    const currentUser = store.getters['authStore/currentUser']
    firebase
      .firestore()
      .doc(`users/${currentUser.id}/notifications/${notification.id}`)
      .update({
        isNew: false
      })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}

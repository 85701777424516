<template>
  <div>
    <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
      <h2 class="text-lg font-medium" :class="isRTL ? 'ml-auto' : 'mr-auto'">
        {{ i18n('notifications.title') }}
      </h2>
    </div>
    <div class="intro-y tab-content mt-5">
      <div class="grid grid-cols-12 gap-6" v-if="rows.length > 0">
        <div
          class="intro-y box col-span-12 p-5 flex cursor-pointer"
          v-for="(notification, index) in rows"
          :key="index"
          @click="readNotification(notification)"
        >
          <div
            class="w-10 h-10 flex-none image-fit rounded-md overflow-hidden col-span-2"
          >
            <img
              class="rounded-full"
              :src="require(`@/assets/images/notify.png`)"
            />
          </div>
          <div :class="isRTL ? 'mr-5' : 'ml-5'">
            <div class="font-medium">{{ notification.username }}</div>
            <div>{{ isRTL ? notification.ar : notification.en }}</div>
          </div>
          <div :class="isRTL ? 'mr-auto' : 'ml-auto'">
            <div>{{ presenterDate(notification, 'createdAt') }}</div>
            <div>{{ presenterTime(notification, 'createdAt') }}</div>
          </div>
        </div>
      </div>
      <div
        v-else
        class="col-span-12 items-center flex justify-center"
        style="height: 80vh; border-radius: 10px; box-shadow: 0px 3px 10px rgb(0 0 0 / 9%);"
      >
        <div>
          <div class="flex justify-center">
            <!-- <FileIcon style="height:125px; width: 125px;" /> -->
            <span class="material-icons-outlined" style="font-size: 125px;"
              >notifications_off</span
            >
          </div>
          <h3 class="font-medium text-3xl mt-5">
            {{ i18n('notifications.noNotification') }}
          </h3>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import { defineComponent, ref } from 'vue'
import { mapGetters, mapActions } from 'vuex'
import moment from 'moment'

export default defineComponent({
  created() {
    // document.title =
    //   this.i18n('routes.app') + ' | ' + this.i18n('routes.notifications')
  },
  watch: {
    // isRTL() {
    //   document.title = this.i18n('routes.app') + ' | ' + this.i18n('routes.notifications')
    // }
  },
  computed: {
    ...mapGetters({
      rows: 'notification/rows'
    }),
    isRTL() {
      return this.$i18n.locale == 'ar'
    },
    language() {
      return this.$i18n.locale
    }
  },
  setup() {
    const selected = ref([])
    const selectAll = ref(false)
    return {
      selected,
      selectAll
    }
  },
  methods: {
    ...mapActions({
      doFetchNotifications: 'notification/doFetchByVuexfire'
    }),
    select() {
      this.selected = []
      if (!this.selectAll) {
        this.rows.forEach(element => {
          this.selected.push(element.id)
        })
      }
    },
    readNotification(notification) {
      const paths = notification.fullPath.split('/')
      // this.$router.push(`/${this.language}/${paths[0].toLowerCase()}`)
      this.$router.push({ name: paths[0].toLowerCase() })
    },
    openCreateNotification() {
      this.$router.push('/notification-create')
    },
    i18n(key, args) {
      return this.$t(key, args)
    },
    formatDate(date) {
      return moment.unix(date.seconds).format('DD/MM/YYYY')
    },
    presenterDateTime(row, fieldName) {
      const val = row[fieldName]
      return val
        ? moment(val)
            .locale(this.language)
            .format('DD-MM-YYYY h:mm A')
        : 'ـــ'
    },
    presenterDate(row, fieldName) {
      const val = row[fieldName]
      return val
        ? moment(val)
            .locale(this.language)
            .format('D MMM, YYYY')
        : 'ـــ'
    },
    presenterDay(row, fieldName) {
      const val = row[fieldName]
      return val
        ? moment(val)
            .locale(this.language)
            .format('dddd')
        : 'ـــ'
    },
    presenterTime(row, fieldName) {
      const date = row[fieldName]
      return moment(date, "YYYY-MM-DD'T'hh:mm:ss.SSS'Z'")
        .locale(this.language)
        .format('hh:mm a')
    }
  }
})
</script>

<style scoped>
.table-report:not(.table-report--bordered):not(.table-report--tabulator) tr {
  border-radius: 0px !important;
  box-shadow: 15px 0px 15px #0000000b;
}

.table-report:not(.table-report--bordered):not(.table-report--tabulator) td {
  border-radius: 0px !important;
  box-shadow: unset !important;
}

.pagination-ar {
  margin-right: unset !important;
  margin-left: auto !important;
}
</style>

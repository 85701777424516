<template v-if="showModal">
  <!-- BEGIN: Modal Content -->
  <div id="rename-file-modal" class="modal" tabindex="-1" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <!-- BEGIN: Modal Header -->
        <div class="modal-header">
          <h2 class="font-medium text-base">
            {{ i18n('common.rename') }}
          </h2>
        </div>
        <!-- END: Modal Header -->
        <!-- BEGIN: Modal Body -->
        <div class="p-5">
          <input
            id="regular-form-1"
            type="text"
            v-model="name"
            class="form-control"
          />
        </div>
        <!-- END: Modal Body -->
        <!-- BEGIN: Modal Footer -->
        <div class="modal-footer">
          <button
            type="button"
            id="submit-btn"
            class="btn text-white bg-theme-39w-20"
            style="background-color: #d5aa0f;"
            @click="doSave()"
          >
            {{ i18n('common.save') }}
          </button>
          <button
            type="button"
            data-dismiss="modal"
            class="btn btn-outline-secondary w-20 ml-1 mr-1"
          >
            {{ i18n('common.cancel') }}
          </button>
        </div>
        <!-- END: Modal Footer -->
      </div>
    </div>
  </div>
  <!-- END: Modal Content -->
</template>

<script>
import { defineComponent, ref } from 'vue'
function extractExtensionFrom(filename) {
  if (!filename) {
    return null
  }
  const regex = /(?:\.([^.]+))?$/
  return regex.exec(filename)[1]
}

export default defineComponent({
  props: {
    showModal: {
      type: Boolean
    },
    file: {
      type: Object
    }
  },

  setup(props) {
    const name = ref('')
    const extension = ref('')
    const hideModal = () => {
      cash('#rename-file-modal').modal('hide')
    }

    return {
      name,
      extension,
      hideModal
    }
  },
  methods: {
    i18n(key, args) {
      return this.$t(key, args)
    },
    doSave() {
      const newName = this.file.url.includes('foldername')
        ? this.name
        : `${this.name}.${this.extension}`
      this.$emit('doSubmit', {
        fileId: this.file.id,
        newName
      })
      this.hideModal()
    }
  },
  watch: {
    file: {
      handler: function(newval) {
        if (newval && newval.name) {
          this.extension = extractExtensionFrom(newval.name)
          this.name = newval.name.substr(0, newval.name.lastIndexOf('.'))
        }
      },
      deep: true
    }
  }
})
</script>

<style></style>

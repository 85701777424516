/* eslint-disable */
import axios from 'axios'
import config from '../config'
const backendUrl = config.backendUrlFile
// const backendUrl = 'http://localhost:5001/el-ghoneimy-dc/europe-west1/filev2'
// const backendUrl = 'http://127.0.0.1:5001/el-ghoneimy-dc/europe-west3/file'
import { AuthToken } from '../auth/auth-token'
import i18n from '../../src/vueI18n'

async function setHeadersContext({ headers }) {
  let token = await AuthToken.get()
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
      'Accept-Language': i18n.locale || 'en',
      'application-type': 'web'
    }
  }
}

export class FileService {
  static async uploadFile(file, path, location, listenerId) {
    const formData = new FormData()
    formData.append('listenerId', listenerId)
    formData.append('destination', path)
    formData.append('location', location)
    formData.append('file', file)

    const url = `${backendUrl}/upload-file`
    const config = await setHeadersContext({
      charset: 'ISO-8859-1',
      'Content-Type': 'multipart/form-data',
      'Access-Control-Allow-Origin': '*'
    })
    // debugger
    await axios.post(url, formData, config)
  }

  static async createFolder(folderName, path, location) {
    const url = `${backendUrl}/create-folder`
    const data = {
      folderName: folderName,
      destination: path,
      location: location
    }
    const config = await setHeadersContext({
      charset: 'ISO-8859-1',
      'Content-Type': 'multipart/form-data',
      'Access-Control-Allow-Origin': '*'
    })

    await axios.post(url, data, config)
  }

  static async deleteAll(files, baseUrl) {
    const requestData = {
      baseUrl: baseUrl,
      files: files
    }

    const data = {
      data: JSON.stringify(requestData)
    }
    const url = `${backendUrl}/delete-all`
    const config = await setHeadersContext({
      // charset: 'ISO-8859-1',
      // 'Content-Type': 'multipart/form-data',
      'Access-Control-Allow-Origin': '*'
    })

    return await axios.post(url, data, config)
  }

  //#region [ Helmet Functions ]
  static getFileType(name) {
    const images = ['png', 'jpg', 'jpeg', 'webp', 'psd']
    const extension = name.split('.').pop()
    if (images.includes(extension)) {
      return 'image'
    } else {
      return extension
    }
  }
  static getCompanyNumber(path) {
    if (!path) return null
    const company = path.split('/')[1]
    return company.substr(company.indexOf('?:') + 2, 2)
  }
  static getDepartmentNumber(path) {
    if (!path) return null
    const pathArray = path.split('/')
    if (pathArray.length > 2) {
      const department = pathArray[2]
      return department.substr(department.indexOf('?:') + 4, 2)
    } else {
      return '00'
    }
  }
  static getCompanyID(path) {
    if (!path) return null
    const company = path.split('/')[1]
    return company.substr(0, company.lastIndexOf('?:'))
  }
  static getDepartmentID(path) {
    if (!path) return null
    const pathArray = path.split('/')
    if (pathArray.length > 2) {
      const department = pathArray[2]
      return department.substr(0, department.lastIndexOf('?:'))
    } else {
      return null
    }
  }
  static getFolderID(path) {
    if (!path) return null
    const pathArray = path.split('/')
    if (pathArray.length > 1) {
      const lastElement = pathArray[pathArray.length - 1]
      const folderId = lastElement.substr(
        lastElement.indexOf('?:') + 2,
        lastElement.length
      )
      return folderId
    }
    return null
  }
}

<template>
  <section class="document-containr" style='background: #FFF;'>
    <div class="header">
      <div class="flex justify-center items-center" style="cursor: pointer;">
        <div class="avatar">
          <img src="/logo.png" />
        </div>
        <span class="title">{{ isRTL ? 'مستعرض الغنيمي' : 'El Ghoniemy Viewer' }}</span>
      </div>
    </div>
    <embed id="pdfViewer" :src="record.publicUrl" width="100%"  class="full-height" v-if="record" />
    <section v-else>
    <div style="width:100%; height:80vh;" class="flex justify-center">
      <LoadingIcon icon="grid" class="" style="width:100px" />
    </div>
  </section>
  </section>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  data() {
    return {
      documentPath: 'https://firebasestorage.googleapis.com/v0/b/el-ghoneimy-dc.appspot.com/o/Data%20Center%2FT9JlxfRvDhDqk9JPNyfd%3F%3A4500T9JlxfRvDhDqk9JPNyfd%2FTM0LUUm6bDRdcUU5WHYd%3F%3A4540TM0LUUm6bDRdcUU5WHYd%2Ffile.pdf%3F%3A4545B5wY8hkJYhbkQJzRGZP0?alt=media&token=4396e746-3f09-4748-8897-a2fe7321c183'
    }
  },
  computed: {
    ...mapGetters({
      record: 'dataCenterStore/record'
    }),
    id() {
      return this.$route.params.id
    },
    language() {
      return this.$i18n.locale
    },
    isRTL() {
      return this.$i18n.locale == 'ar'
    }
  },
  mounted() {
    this.doFetch(this.id)
  },
  methods: {
    ...mapActions({
      doFetch: 'dataCenterStore/doFetchFile'
    })
  }
}
</script>
<style>
.full-height {
  height: calc(100vh - 76px)
}
#pdfViewer #toolbar #end {
  display: none !important;
}
</style>

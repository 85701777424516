<template>
  <div>
    <div class="intro-y flex items-center mt-8">
      <h2 class="text-lg font-medium" :class="isRTL ? 'ml-auto' : 'mr-auto'">
        {{ i18n('settings.title') }}
      </h2>
    </div>
    <!-- BEGIN: Barcode Tabs -->
    <div class="intro-y box px-5 pt-5 mt-5">
      <div class="nav nav-tabs flex-col sm:flex-row justify-center lg:justify-start" role="tablist">
        <a
          id="barcode-tab"
          data-toggle="tab"
          data-target="#barcode"
          href="javascript:;"
          class="py-4 sm:mr-8 active"
          role="tab"
          aria-selected="true"
          @click="tab = 'barcode'"
          >{{ i18n('settings.barcodeTabTitle') }}</a
        >
      </div>
    </div>
    <!-- END:  Barcode Tabs -->
    <div class="intro-y tab-content mt-5">
      <loading-spinner type="half-circle" :duration="1000" :size="40" color="#4469A8" :loading="loading" />
      <BarcodeTab v-if="tab == 'barcode'" />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { defineComponent, ref, onMounted } from 'vue'
import BarcodeTab from '@/views/settings/barcode-tab.vue'

export default defineComponent({
  // async created() {
  //   // document.title = this.i18n('routes.app') + ' | ' + this.i18n('routes.settings')
  //   await this.doFindBarcodeScales()
  // },
  // watch: {
  //   isRTL() {
  //     document.title = this.i18n('routes.app') + ' | ' + this.i18n('routes.settings')
  //   }
  // },
  components: {
    BarcodeTab
  },
  computed: {
    ...mapGetters({
      loading: 'settings/loading',
      currentUser: 'authStore/currentUser'
    }),
    isRTL() {
      return this.$i18n.locale == 'ar'
    },
    language() {
      return this.$i18n.locale
    }
  },
  setup() {
    const activated = ref(true)
    const tab = ref('barcode')
    onMounted(() => {
      // document.getElementById('offers').style.display = 'none'
    })
    return {
      tab,
      activated
    }
  },
  methods: {
    ...mapActions({
      doFindBarcodeScales: 'settings/doFindBarcodeScales'
    }),
    i18n(key, args) {
      return this.$t(key, args)
    }
  }
})
</script>

<template>
  <div>
    <div class="grid grid-cols-12 gap-6 mt-8">
      <div class="col-span-12 lg:col-span-3 2xl:col-span-2">
        <h2 class="intro-y text-lg font-medium mr-auto mt-2">
          {{ i18n('fileManager.fileManager') }}
        </h2>
        <!-- BEGIN: File Manager Menu -->
        <div class="intro-y box p-5 mt-6">
          <div class="mt-1">
            <a
              class="flex items-center px-3 py-2 rounded-md cursor-pointer"
              :class="
                this.tab == 'All' ? 'bg-theme-1 text-white font-medium' : ''
              "
              @click="filterFiles('All')"
            >
              <ListIcon class="w-4 h-4 mx-2" />
              {{ i18n('fileManager.filter.all') }}
            </a>
            <a
              class="flex items-center px-3 py-2 mt-2 rounded-md cursor-pointer"
              :class="
                this.tab == 'folder' ? 'bg-theme-1 text-white font-medium' : ''
              "
              @click="filterFiles('folder')"
            >
              <FolderIcon class="w-4 h-4 mx-2" />
              {{ i18n('fileManager.filter.folders') }}
            </a>
            <a
              class="flex items-center px-3 py-2 mt-2 rounded-md cursor-pointer"
              :class="
                this.tab == 'image' ? 'bg-theme-1 text-white font-medium' : ''
              "
              @click="filterFiles('image')"
            >
              <ImageIcon class="w-4 h-4 mx-2" />
              {{ i18n('fileManager.filter.images') }}
            </a>
            <a
              class="flex items-center px-3 py-2 mt-2 rounded-md cursor-pointer"
              :class="
                this.tab == 'docx' ? 'bg-theme-1 text-white font-medium' : ''
              "
              @click="filterFiles('docx')"
            >
              <FileIcon class="w-4 h-4 mx-2" />
              {{ i18n('fileManager.filter.textFiles') }}
            </a>
            <a
              class="flex items-center px-3 py-2 mt-2 rounded-md cursor-pointer"
              :class="
                this.tab == 'pdf' ? 'bg-theme-1 text-white font-medium' : ''
              "
              @click="filterFiles('pdf')"
            >
              <FileIcon class="w-4 h-4 mx-2" />
              {{ i18n('fileManager.filter.pdfFiles') }}
            </a>
            <a
              class="flex items-center px-3 py-2 mt-2 rounded-md cursor-pointer"
              :class="
                this.tab == 'xlsx' ? 'bg-theme-1 text-white font-medium' : ''
              "
              @click="filterFiles('xlsx')"
            >
              <FileIcon class="w-4 h-4 mx-2" />
              {{ i18n('fileManager.filter.excelSheets') }}
            </a>
          </div>
        </div>
        <!-- END: File Manager Menu -->
      </div>
      <div class="col-span-12 lg:col-span-9 2xl:col-span-10">
        <!-- BEGIN: File Manager Filter -->
        <div class="intro-y flex flex-col-reverse sm:flex-row items-center">
          <div
            class="w-full sm:w-auto relative mt-3"
            :class="isRTL ? 'ml-auto' : 'mr-auto'"
          >
            <SearchIcon
              class="
                w-4
                absolute
                mt-2
                inset-y-0
                z-10
                text-gray-700
                dark:text-gray-300
              "
              :class="isRTL ? 'mr-3 right-0' : 'ml-3 left-0'"
            />
            <input
              type="text"
              class="
                form-control
                w-full
                sm:w-64
                box
                px-10
                text-gray-700
                dark:text-gray-300
                placeholder-theme-13
              "
              :placeholder="i18n('fileManager.search.placeholder')"
              v-model="search"
            />
            <button
              id="tabulator-html-filter-go"
              type="button"
              class="
                btn
                text-white
                bg-theme-39
                w-full
                sm:w-16 sm:mr-3 sm:ml-3
                mt-2
                sm:mt-0
              "
              style="background-color: #d5aa0f"
              @click="onSearch()"
            >
              {{ i18n('fileManager.search.go') }}
            </button>
            <button
              id="tabulator-html-filter-reset"
              type="button"
              class="
                btn btn-secondary
                dark:text-black
                w-full
                sm:w-24
                mt-2
                sm:mt-0 sm:ml-1 sm:mr-1
              "
              @click="onResetFilter()"
            >
              {{ i18n('fileManager.search.reset') }}
            </button>
          </div>
        </div>
        <!-- END: File Manager Filter -->
        <!-- BEGIN: Directory & Files -->
        <div
          style="width: 100%; height: 80vh"
          class="flex justify-center"
          v-if="loading"
        >
          <LoadingIcon icon="grid" class="" style="width: 100px" />
        </div>
        <div class="flex flex-wrap mt-5" v-if="!loading">
          <div v-for="path in chevronPath" :key="path" class="">
            <!-- <div class="w-full"> -->
            <ChevronRightIcon class="breadcrumb__icon" v-if="!isRTL" />
            <ChevronLeftIcon class="breadcrumb__icon" v-else />
            <a @click="navigateChevron(path)" class="cursor-pointer">{{
              path
            }}</a>
            <!-- </div> -->
          </div>
        </div>
        <ArrowLeftIcon
          v-if="!homePath && !loading && tab != 'Trash' && !isRTL"
          class="mt-10 cursor-pointer"
          @click="navigateBack()"
        />
        <ArrowRightIcon
          v-if="!homePath && !loading && tab != 'Trash' && isRTL"
          class="mt-10 cursor-pointer"
          @click="navigateBack()"
        />
        <div
          class="intro-y grid grid-cols-12 gap-3 sm:gap-6 mt-5"
          v-if="!loading"
        >
          <div
            v-for="(file, key) in files"
            :key="key"
            class="
              intro-y
              col-span-6
              sm:col-span-4
              md:col-span-3
              2xl:col-span-2
            "
          >
            <div
              class="
                file
                box
                rounded-md
                px-5
                pt-8
                pb-5
                px-3
                sm:px-5
                relative
                zoom-in
              "
              @click="openeFile(file)"
            >
              <!-- <div class="absolute top-0 mt-2" :class="isRTL ? 'left-50 mr-10' : 'left-50 ml-6'">
                <input class="form-check-input border border-gray-500" type="checkbox" v-model="file.checkbox" />
              </div> -->
              <div class="flex justify-center mb-2">
                <span class="font-medium text-sm">{{ barcode(file.id) }}</span>
              </div>
              <a
                v-if="file.type == 'folder'"
                class="w-3/5 file__icon file__icon--directory mx-auto"
              ></a>
              <a
                v-else-if="file.type == 'image'"
                class="w-3/5 file__icon file__icon--image mx-auto"
              >
                <div class="file__icon--image__preview image-fit">
                  <img :src="file.url" :alt="file.name" />
                </div>
              </a>
              <a v-else class="w-3/5 file__icon file__icon--file mx-auto">
                <div class="file__icon__file-name">{{ file.type }}</div>
              </a>
              <Tippy :content="file.company || file.name">
                <a class="block font-medium mt-4 text-center truncate">{{
                  file.name
                }}</a>
              </Tippy>
              <div class="text-gray-600 text-xs text-center mt-0.5">
                {{ file.size }}
              </div>
            </div>
            <div class="absolute top-0 right-0 mx-2 mt-2 dropdown ml-auto">
              <a
                class="dropdown-toggle w-5 h-5 block"
                href="javascript:;"
                aria-expanded="false"
                id="item-dropdown-toggle"
                v-if="hasDownloadAccess(file) || hasPrintAccess(file)"
              >
                <MoreVerticalIcon class="w-5 h-5 text-gray-600" />
              </a>
              <div class="dropdown-menu w-40">
                <div class="dropdown-menu__content box dark:bg-dark-1 p-2">
                  <a
                    class="
                      flex
                      items-center
                      block
                      p-2
                      transition
                      duration-300
                      ease-in-out
                      bg-white
                      dark:bg-dark-1
                      hover:bg-gray-200
                      dark:hover:bg-dark-2
                      rounded-md
                    "
                    @click="downloadFile(file)"
                    v-if="file.type != 'Folder' && hasDownloadAccess(file)"
                  >
                    <DownloadIcon class="w-4 h-4 mx-2" />
                    {{ i18n('fileManager.download') }}
                  </a>
                  <a
                    class="
                      flex
                      items-center
                      block
                      p-2
                      transition
                      duration-300
                      ease-in-out
                      bg-white
                      dark:bg-dark-1
                      hover:bg-gray-200
                      dark:hover:bg-dark-2
                      rounded-md
                    "
                    @click="print(file)"
                    v-if="file.type != 'Folder' && hasPrintAccess(file)"
                  >
                    <PrinterIcon class="w-4 h-4 mx-2" />
                    {{ i18n('fileManager.print') }}
                  </a>
                  <!-- <a
                    class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2  rounded-md"
                    href="javascript:;"
                    data-toggle="modal"
                    data-target="#share-file-modal-preview"
                    type="button"
                    v-if="file.type != 'Folder' && hasShareAccess(file)"
                    @click="sharedFile = file"
                  >
                    <Share2Icon class="w-4 h-4 mr-2" /> {{ i18n('fileManager.share') }}
                  </a> -->
                </div>
              </div>
            </div>
          </div>
          <div
            v-if="files.length == 0"
            class="col-span-12 items-center flex justify-center"
            style="height: 300px"
          >
            <div>
              <div class="flex justify-center">
                <FileTextIcon style="height: 125px; width: 125px" />
              </div>
              <h3 class="font-medium text-3xl mt-5">
                {{ i18n('fileManager.noFiles') }}
              </h3>
            </div>
          </div>
        </div>
        <!-- END: Directory & Files -->
        <!-- BEGIN: Pagination -->
        <!-- END: Pagination -->
      </div>
      <FsLightbox
        :toggler="toggler"
        :sources="[source]"
        :key="source"
        type="image"
        :types="['image']"
      />
      <ShareFileModal :showModal="true" :file="sharedFile" />
      <PDFViewer :showPDFModal="true" :path="path" :url="openedPDF" />
    </div>
  </div>
</template>

<script>
import ShareFileModal from './share-file.vue'
import PDFViewer from './pdf-viewer.vue'
import { defineComponent } from 'vue'
import FsLightbox from 'fslightbox-vue/v3'
import { mapActions, mapGetters } from 'vuex'
import * as Printjs from 'print-js'
// import { AuthToken } from '../../auth/auth-token'
// import HelperClass from '@/shared/helperClass'
import AuthService from '../../auth/auth-service'
import config from '@/config'
const editorUrl = config.editorUrl

export default defineComponent({
  components: {
    FsLightbox,
    PDFViewer,
    ShareFileModal
  },

  data() {
    return {
      checkbox: false,
      search: '',
      type: '',
      files: [],
      toggler: false,
      source: '',
      tab: 'All',
      path: '',
      serverPath: '',
      openedPDF: '',
      sharedFile: null,
      chevronPath: ['Data Center']
    }
  },
  computed: {
    ...mapGetters({
      currentUser: 'authStore/currentUser',
      rows: 'dataCenterEmployeeStore/rows',
      filesInFirestore: 'dataCenterEmployeeStore/filesInFirestore',
      loading: 'dataCenterEmployeeStore/loading',
      lastPage: 'dataCenterEmployeeStore/lastPage'
    }),
    isRTL() {
      return this.$i18n.locale == 'ar'
    },
    language() {
      return this.$i18n.lcoale
    },
    barcode() {
      return fileId => {
        const file = this.filesInFirestore.filter(file => file.id == fileId)
        return file.length
          ? file[0].type == 'File'
            ? file[0].barcode
            : ''
          : ''
      }
    }
  },
  created() {
    // window.addEventListener('scroll', () => {
    //   if (
    //     window.innerHeight + window.pageYOffset >=
    //     document.body.offsetHeight
    //   ) {
    //     this.fetchMore()
    //   }
    // })
    this.getCurrentUser()
    this.doFetchFirestore(this.currentUser)
    this.doFetch({
      path: this.path,
      currentUser: this.currentUser
    })
  },
  methods: {
    ...mapActions({
      getCurrentUser: 'authStore/getCurrentUser',
      saveCurrentUser: 'authStore/saveCurrentUser',
      doFetch: 'dataCenterEmployeeStore/doFetch',
      doSearch: 'dataCenterEmployeeStore/doSearch',

      doFetchFirestore: 'dataCenterEmployeeStore/doFetchFirestore'
    }),
    i18n(key, args) {
      return this.$t(key, args)
    },
    print(file) {
      Printjs({
        printable: file.publicUrl,
        type: file.extension,
        showModal: true,
        modalMessage: 'Loading...'
      })
    },
    // Searching
    async onSearch() {
      if (this.search != '') {
        this.doSearch({ search: this.search, currentUser: this.currentUser })
      } else {
        await this.doFetch({ path: this.path, currentUser: this.currentUser })
      }
    },
    async onResetFilter() {
      this.search = ''
      await this.onSearch()
    },
    // Download
    async downloadFile(file) {
      const data = await fetch(file.url)
      const blob = await data.blob()
      const url = URL.createObjectURL(blob)
      const anchor = document.createElement('a')
      anchor.href = url
      anchor.download = file.name
      document.body.appendChild(anchor)
      anchor.click()
      document.body.removeChild(anchor)
    },
    async filterFiles(tab) {
      this.tab = tab
      if (tab == 'All') {
        await this.doFetch({
          // path: this.path,
          currentUser: this.currentUser
        })
      } else {
        // await this.doFetch(this.currentUser)
        this.files = this.rows.filter(el => {
          return el.type == tab
        })
      }
    },
    // Navigate
    navigate(file) {
      this.path = file.url || file?.fullPath
      this.doFetch({
        path: this.path,
        currentUser: this.currentUser
      })
      this.chevronPath.push(file.name)
    },
    navigateBack() {
      this.path = this.path.substring(0, this.path.lastIndexOf('/'))
      this.doFetch({
        path: this.path,
        currentUser: this.currentUser
      })
      this.chevronPath.pop()
      history.popState()
    },
    async fetchMore() {
      await this.doFetch({
        path: this.path,
        currentUser: this.currentUser,
        lastPage: this.lastPage
      })
    },
    async navigateChevron(path) {
      for (let index = this.chevronPath.length - 1; index >= 0; index--) {
        const element = this.chevronPath[index]
        if (element != path) {
          this.path = this.path.substring(0, this.path.lastIndexOf('/'))
          this.chevronPath.pop()
        } else {
          await this.doFetch({
            path: this.path,
            currentUser: this.currentUser
          })
          return
        }
      }
    },
    // Verify Permessions
    hasReadAccess(file) {
      const fileInFirestore = this.filesInFirestore.filter(
        el => el.id == file.id
      )[0]
      if (
        fileInFirestore &&
        fileInFirestore.readAccess.includes(this.currentUser.id)
      ) {
        return true
      } else {
        return false
      }
    },
    hasDownloadAccess(file) {
      const fileInFirestore = this.filesInFirestore.filter(
        el => el.id == file.id
      )[0]
      if (
        fileInFirestore &&
        fileInFirestore.downloadAccess.includes(this.currentUser.id)
      ) {
        return true
      } else {
        return false
      }
    },
    hasPrintAccess(file) {
      const fileInFirestore = this.filesInFirestore.filter(
        el => el.id == file.id
      )[0]
      if (
        fileInFirestore &&
        fileInFirestore.printAccess.includes(this.currentUser.id)
      ) {
        return true
      } else {
        return false
      }
    },
    hasDeleteAccess(file) {
      const fileInFirestore = this.filesInFirestore.filter(
        el => el.id == file.id
      )[0]
      if (
        fileInFirestore &&
        fileInFirestore.deleteAccess.includes(this.currentUser.id)
      ) {
        return true
      } else {
        return false
      }
    },
    hasShareAccess(file) {
      const fileInFirestore = this.filesInFirestore.filter(
        el => el.id == file.id
      )[0]
      if (
        fileInFirestore &&
        fileInFirestore.shareAccess.includes(this.currentUser.id)
      ) {
        return true
      } else {
        return false
      }
    },
    getFileType(name) {
      const images = ['png', 'jpg', 'jpeg', 'webp', 'psd', 'svg']
      const extension = name.split('.').pop()
      if (images.includes(extension)) {
        return 'image'
      } else {
        return extension
      }
    },
    openeFile(file) {
      switch (file.type) {
        case 'docx':
        case 'doc':
          this.openWord(file)
          break
        case 'xlsx':
          this.openExcel(file)
          break
        case 'pdf':
          this.openPDF(file)
          break
        case 'image':
          this.openFsLightBox(file.url)
          break
        case 'folder':
          this.navigate(file)
          break
        case 'File':
          this.openFile(file)
          break
      }
    },
    openPDF(file) {
      // this.openedPDF = file.url
      // cash('#view-pdf-modal-preview').modal('show')
      const link = `/${this.language}/pdf/${file.id}`
      const anchor = document.createElement('a')
      anchor.href = link
      anchor.target = '_blank'
      document.body.appendChild(anchor)
      anchor.click()
    },
    openFile(file) {
      window.open(file?.publicUrl, '_blank')
    },
    async openExcel(file) {
      // const link = `/excel/${file.id}`
      // const token = await AuthToken.get()
      const token = await AuthService.createCustomToken()
      const link = `${editorUrl}/${this.language}/excel/${file.id}?tokenId=${token}`
      const anchor = document.createElement('a')
      anchor.href = link
      anchor.target = '_blank'
      document.body.appendChild(anchor)
      anchor.click()
    },
    async openWord(file) {
      const link = `/${this.language}/word/${file.id}`
      const anchor = document.createElement('a')
      anchor.href = link
      anchor.target = '_blank'
      document.body.appendChild(anchor)
      anchor.click()
    },
    openFsLightBox(source) {
      this.source = source
      setTimeout(() => {
        this.toggler = !this.toggler
      }, 1)
    }
  },
  watch: {
    rows: {
      handler: function(newval) {
        this.files = [
          ...new Map(newval.map(val => [val.id, val])).values()
        ].sort((a, b) =>
          a.name.toLowerCase().localeCompare(b.name.toLowerCase(), undefined, {
            numeric: true,
            sensitivity: 'base'
          })
        )
      },
      deep: true
    }
  }
})
</script>

<template>
  <div id="error-barcode-printer" class="toastify-content hidden flex">
    <InfoIcon class="text-theme-38" />
    <div class="ml-4 mr-4">
      <div class="font-medium">{{ i18n('common.error') }}</div>
      <div class="text-gray-600 mt-1">
        {{ message }}
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    message: {
      type: String
    }
  },
  methods: {
    i18n(key, args) {
      return this.$t(key, args)
    }
  }
})
</script>

<style></style>

<template>
  <div>
    <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
      <h2 class="text-lg font-medium ">{{ i18n('companies.title') }}</h2>
    </div>
    <!-- BEGIN: HTML Table Data -->
    <div class="intro-y box p-5 mt-5">
      <div class="flex flex-col sm:flex-row sm:items-end xl:items-start">
        <form id="tabulator-html-filter-form" class="xl:flex" :class="isRTL ? 'sm:ml-auto' : 'sm:mr-auto'">
          <div class="sm:flex items-center sm:mr-4">
            <label class="w-12 flex-none xl:w-auto xl:flex-initial mr-2 ml-2">{{ i18n('companies.filter.field')
            }}</label>
            <select id="tabulator-html-filter-field" v-model="filter.field"
              class="form-select w-full sm:w-32 2xl:w-full mt-2 sm:mt-0 sm:w-auto dark:bg-dark-9">
              <option value="name">{{ i18n('companies.filter.name') }}</option>
              <option value="emailAddress">{{ i18n('companies.filter.emailAddress') }}</option>
              <option value="phoneNumber">{{ i18n('companies.filter.phoneNumber') }}</option>
            </select>
          </div>
          <div class="sm:flex items-center sm:mr-4 mt-2 xl:mt-0">
            <label class="w-12 flex-none xl:w-auto xl:flex-initial mr-2 ml-2">{{ i18n('companies.filter.value')
            }}</label>
            <input id="tabulator-html-filter-value" v-model="filter.value" type="text"
              class="form-control sm:w-40 2xl:w-full mt-2 sm:mt-0 dark:bg-dark-9" />
          </div>
          <div class="mt-2 xl:mt-0">
            <button id="tabulator-html-filter-go" type="button"
              class="btn text-white bg-theme-39 w-full sm:w-16 sm:mr-3" @click="filterCompanies()"
              style="background-color: #d5aa0f;">
              {{ i18n('companies.filter.go') }}
            </button>
            <button id="tabulator-html-filter-reset" type="button"
              class="btn btn-secondary dark:text-black w-full sm:w-24 mt-2 sm:mt-0 sm:ml-1 sm:mr-1"
              @click="fetchCompanies()">
              {{ i18n('companies.filter.reset') }}
            </button>
          </div>
        </form>
        <div class="flex mt-5 sm:mt-0">
          <button id="tabulator-print" class="btn btn-outline-secondary w-1/2 sm:w-auto" @click="goToCreateCompany()">
            <PlusIcon class="w-4 h-4" />
            {{ i18n('companies.add') }}
          </button>
        </div>
      </div>
      <div class="overflow-x-auto" style="position: relative; min-height: 200px;">
        <loading-spinner type="half-circle" :duration="1000" :size="40" color="#4469A8"
          :loading="loading || destroyLoading" />
        <table class="table table-report sm:mt-2" v-if="companies.length > 0">
          <thead>
            <tr>
              <th class="border-b-2 dark:border-dark-5 whitespace-nowrap">#</th>
              <th class="text-center whitespace-nowrap">
                {{ i18n('companies.name') }}
              </th>
              <th class="text-center whitespace-nowrap">
                {{ i18n('companies.emailAddress') }}
              </th>
              <th class="text-center whitespace-nowrap">
                {{ i18n('companies.phoneNumber') }}
              </th>
              <!-- <th class="text-center whitespace-nowrap">
                {{ i18n('companies.address') }}
              </th> -->
              <th class="text-center whitespace-nowrap">
                {{ i18n('companies.actions') }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(row, index) in paginated" :key="index">
              <td class="">
                <div class="text-gray-600 text-xs whitespace-nowrap mt-0.5">
                  #{{ (page + 1) * 5 - 5 + index + 1 }}
                </div>
              </td>
              <td class="text-center">
                <div class="font-medium whitespace-nowrap">
                  {{ presenter(row, 'name') }}
                </div>
              </td>
              <td class="text-center">
                <div href="" class="font-medium whitespace-nowrap ">
                  {{ presenter(row, 'emailAddress') }}
                </div>
              </td>
              <td class="text-center">
                <div href="" class="font-medium whitespace-nowrap ">
                  {{ presenter(row, 'phoneNumber') }}
                </div>
              </td>
              <!-- <td class="text-center">
                <div href="" class="font-medium whitespace-nowrap ">
                  {{ presenter(row, 'address') }}
                </div>
              </td> -->
              <td class="table-report__action w-56">
                <div class="flex justify-center items-center  cursor-pointer">
                  <a href="javascript:;" class="text-theme-37 mr-2" @click="editCompany(row)">
                    <EditIcon class="w-6 h-6 mr-1" />
                  </a>
                  <a v-if="currentUser.isOwner" id="destroyModal" href="javascript:;"
                    class="text-theme-38 cursor-pointer" data-toggle="modal" data-target="#delete-modal-preview"
                    type="button" @click="selectedCompany = row">
                    <TrashIcon class="w-6 h-6 mr-2" />
                  </a>
                  <a href="javascript:;" class="mr-2" @click="viewDepartments(row)">
                    <EyeIcon class="w-6 h-6 mr-1" />
                  </a>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <paginationVue :pageCount="pageCount" :page="page" @prevPage="prevPage" @nextPage="nextPage"
          v-if="companies.length > 5" />
        <div class="col-span-12 items-center flex justify-center" style="height: 50vh;"
          v-if="companies.length == 0 && !loading">
          <div>
            <div class="flex justify-center">
              <FileIcon style="height:125px; width: 125px;" />
            </div>
            <h3 class="font-medium text-3xl mt-5">
              {{ i18n('companies.noCompanies') }}
            </h3>
          </div>
        </div>
      </div>
    </div>
    <!-- <div v-else style="width:100%; height:80vh;" class="flex justify-center">
      <LoadingIcon icon="grid" class="" style="width:100px" />
    </div> -->
    <!-- END: HTML Table Data -->

    <!-- START: Delete Modal -->
    <DeleteModal :showModal="!!selectedCompany" item="company" @delete="destroyCompany(selectedCompany)" />
    <!-- END: Delete Modal -->
  </div>
</template>

<script>
import { defineComponent, ref, reactive } from 'vue'
import paginationVue from '../../components/pagination/pagination.vue'
import xlsx from 'xlsx'
// import feather from 'feather-icons'
import moment from 'moment'
import { mapActions, mapGetters } from 'vuex'
import DeleteModal from '@/components/modals/delete-modal.vue'

export default defineComponent({
  components: {
    DeleteModal,
    paginationVue
  },
  data() {
    return {
      selectedCompany: null
    }
  },
  computed: {
    ...mapGetters({
      currentUser: 'authStore/currentUser',
      rows: 'companyStore/rows',
      loading: 'companyStore/loading',
      destroyLoading: 'companyStore/destroyLoading'
    }),
    isRTL() {
      return this.$i18n.locale == 'ar'
    },
    pageCount() {
      const l = this.companies.length
      const s = this.size
      return Math.ceil(l / s)
    },
    paginated() {
      const start = this.page * this.size
      const end = start + this.size
      return this.companies.slice(start, end)
    },
    selectedCompanies() {
      return this.rows.filter(el => {
        return el.selected
      })
    }
  },
  setup() {
    // const rows = ref([])
    const tableLimit = ref(10)
    const companies = ref([])
    const tableRef = ref()
    const tabulator = ref()
    const selected = ref([])
    const selectAll = ref(false)
    const selectedUser = ref(null)
    const page = ref(0)
    const size = ref(5)
    const filter = reactive({
      field: 'name',
      value: ''
    })
    // Filter function
    const onFilter = () => {
      tabulator.value.setFilter(filter.field, filter.type, filter.value)
    }

    // On reset filter
    const onResetFilter = () => {
      filter.field = 'name'
      filter.value = ''
      onFilter()
    }

    // Export
    const onExportCsv = () => {
      tabulator.value.download('csv', 'data.csv')
    }

    const onExportJson = () => {
      tabulator.value.download('json', 'data.json')
    }

    const onExportXlsx = () => {
      const win = window
      win.XLSX = xlsx
      tabulator.value.download('xlsx', 'data.xlsx', {
        sheetName: 'Products'
      })
    }

    const onExportHtml = () => {
      tabulator.value.download('html', 'data.html', {
        style: true
      })
    }

    // Print
    const onPrint = () => {
      tabulator.value.print()
    }

    return {
      selectedUser,
      selected,
      selectAll,
      tableLimit,
      tableRef,
      filter,
      onFilter,
      onResetFilter,
      onExportCsv,
      onExportJson,
      onExportXlsx,
      onExportHtml,
      onPrint,
      companies,
      paginationVue,
      page,
      size
    }
  },
  methods: {
    ...mapActions({
      getCurrentUser: 'authStore/getCurrentUser',
      saveCurrentUser: 'authStore/saveCurrentUser',
      doFetch: 'companyStore/doFetch',
      doFilterCompanies: 'companyStore/doFilterCompanies',
      doDestroy: 'companyStore/doDestroy'
    }),
    i18n(key, args) {
      return this.$t(key, args)
    },
    nextPage() {
      this.page++
    },
    prevPage() {
      this.page--
    },
    presenter(row, fieldName) {
      const val = row ? row[fieldName] : null
      // return val ? val.substring(0, val.lastIndexOf('?')) : 'ـــ'
      return val || 'ـــ'
    },
    editCompany(row) {
      const { id } = row
      const { id: partitionId } = this.$route.params
      this.$router.push({ name: 'company/edit/:id', params: { partitionId, id } })
    },
    async destroyCompany(company) {
      const { id, prefix } = company
      await this.doDestroy({
        partitionId: this.$route.params.id,
        companyId: id,
        companyPrefix: prefix
      })
      this.selectedCompany = null
    },
    select() {
      if (!this.selectAll) {
        this.rows.forEach(element => {
          element.selected = true
          this.selectedUsers.push(element.id)
        })
      } else {
        this.rows.forEach(element => {
          element.selected = false
          this.selectedUsers = []
        })
      }
    },
    formatDate(date) {
      return moment.unix(date.seconds).format('DD/MM/YYYY')
    },
    filterCompanies() {
      this.companies = this.rows.filter(el => {
        return el[this.filter.field].includes(this.filter.value)
      })
    },
    fetchCompanies() {
      this.doFetch(this.$route.params.id)
    },
    goToCreateCompany() {
      const { id } = this.$route.params
      this.$router.push({ name: 'company/add', params: { id } })
    },
    viewDepartments(row) {
      const { id } = row
      const { id: partitionId } = this.$route.params
      this.$router.push({ name: 'department', params: { partitionId, id } })
    }
  },
  mounted() {
    this.fetchCompanies()
    this.getCurrentUser()
    this.selectAll = false
  },
  watch: {
    rows(val) {
      this.companies = val
    }
  }
})
</script>
<style scoped>
.table-report:not(.table-report--bordered):not(.table-report--tabulator) tr {
  border-radius: 0px !important;
  box-shadow: 15px 0px 15px #0000000b;
}

.table-report:not(.table-report--bordered):not(.table-report--tabulator) td {
  border-radius: 0px !important;
  box-shadow: unset !important;
}

.pagination-ar {
  margin-right: unset !important;
  margin-left: auto !important;
}
</style>

<template>
  <!-- BEGIN: Modal Content -->
  <div
    id="view-pdf-modal-preview"
    class="modal"
    tabindex="-1"
    aria-hidden="true"
    v-if="modalShow"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="app-content">
          <vue-pdf-embed
            ref="pdfRef"
            :source="pdfSource"
            :page="page"
            @rendered="handleDocumentRender"
          ></vue-pdf-embed>
        </div>
      </div>
    </div>
  </div>
  <!-- END: Modal Content -->
</template>

<script>
import { defineComponent, ref } from 'vue'
import VuePdfEmbed from 'vue-pdf-embed'
import Toastify from 'toastify-js'

export default defineComponent({
  components: {
    VuePdfEmbed
  },
  props: ['showPDFModal', 'path', 'url'],
  setup(props) {
    const name = ref('')
    const hideModal = () => {
      cash('#view-pdf-modal-preview').modal('hide')
    }
    const errorNotificationToggle = () => {
      Toastify({
        node: cash('#error-notification-folder').clone().removeClass('hidden')[0],
        duration: 3000,
        newWindow: true,
        close: true,
        gravity: 'top',
        position: 'right',
        stopOnFocus: true
      }).showToast()
    }
    return {
      name,
      hideModal,
      errorNotificationToggle
    }
  },
  data() {
    return {
      isLoading: true,
      page: null,
      pageCount: 1,
      showAllPages: true,
      modalShow: this.showPDFModal,
      pdfSource: ''
    }
  },
  methods: {
    handleDocumentRender() {
      this.isLoading = false
      this.pageCount = this.$refs.pdfRef.pageCount
    }
  },
  watch: {
    showAllPages() {
      this.page = this.showAllPages ? null : 1
    },
    async url() {
      this.pdfSource = this.url
    }
  }
})
</script>
<style>
.textLayer {
  border-bottom: 5px solid #ccc !important;
}
</style>

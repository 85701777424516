<template>
  <!-- BEGIN: Modal Content -->
  <div
    id="upload-modal-preview"
    class="modal" tabindex="-1"
    aria-hidden="true"
    data-backdrop="static"
  >
    <!-- v-if="showModal" -->
    <div class="modal-dialog modal-xl">
      <div class="modal-content" v-if="!loading">
        <!-- BEGIN: Modal Header -->
        <div class="modal-header">
          <h2 class="font-medium text-base">
            {{ i18n('upload.title') }}
          </h2>
        </div>
        <!-- END: Modal Header -->
        <!-- BEGIN: Modal Body -->
        <div id="multiple-file-upload" class="p-5">
          <div class="preview">
            <Dropzone ref-key="dropzoneMultipleRef" id="dropzoneMultipleRef" :options="{
              url: 'https://httpbin.org/post',
              paramName: 'file',
              thumbnailWidth: 150,
              maxFilesize: 32,
              headers: { 'My-Awesome-Header': 'header value' }
            }" class="dropzone">
              <div class="text-lg font-medium">
                {{ i18n('upload.dropzone') }}
              </div>
            </Dropzone>
          </div>
        </div>
        <div class="mt-5 mb-5 items-center">
          <div class="form-check form-switch mr-3 items-center">
            <input
              id="show-example-1"
              data-target="#input"
              class="show-code form-check-switch mr-0 ml-3"
              type="checkbox"
              v-model="seperatePdfPages" :disabled="!isPdfFile || isManyFilesUploads"
            />
            <label class="form-check-label ml-2" for="show-example-1">{{ i18n('upload.separatePages') }}</label>
          </div>
        </div>
        <!-- END: Modal Body -->
        <!-- BEGIN: Modal Footer -->
        <div class="modal-footer">
          <button id="submit-btn" type="button" data-dismiss="modal" class="btn text-white bg-theme-39 w-20" style="background-color:#d5aa0f"
            :disabled="uploadLoading || filesToSave.length == 0" @click="doSave(filesToSave)">
            {{ i18n('common.save') }}
          </button>
          <button type="button" data-dismiss="modal" class="btn btn-outline-secondary w-20 ml-1 mr-1" @click="doCancel()">
            {{ i18n('common.cancel') }}
          </button>
        </div>
        <!-- END: Modal Footer -->
      </div>
      <!-- <div class="modal-content" v-else style="padding:50px">
        <div class="flex justify-center">
          <LoadingIcon icon="puff" class="" style="width:200px" />
        </div>
      </div> -->
    </div>
    <div id="error-notification" class="toastify-content hidden flex">
      <AlertCircleIcon class="text-theme-38" />
      <div class="ml-4 mr-4">
        <div class="font-medium">{{ i18n('upload.errors.title') }}</div>
        <div class="text-gray-600 mt-1">
          {{ i18n('upload.errors.pleaseSelect') }}
        </div>
      </div>
    </div>
  </div>
  <!-- END: Modal Content -->
</template>

<script>
import { defineComponent, ref, provide } from 'vue'
import { mapActions } from 'vuex'
import Dropzone from 'dropzone'
import Toastify from 'toastify-js'
import HelperClass from '../../shared/helperClass'
import axios from 'axios'
import Message from '@/shared/message/toastify'
import config from '@/config'
const backendUrl = config.backendUrl

export default defineComponent({
  props: ['destination', 'serverPath', 'showModal'],
  setup(props) {
    const numberOfFolders = ref(10)
    const dropzoneMultipleRef = ref()
    const filesToSave = ref([])
    const path = ref('')
    provide('bind[dropzoneMultipleRef]', el => {
      dropzoneMultipleRef.value = el
    })
    const hideModal = () => {
      // cash('#upload-modal-preview').removeClass('show')
      cash('#upload-modal-preview').modal('hide')
      // cash('#upload-modal-preview').remove()
      // cash('#upload-modal-preview').addClass('hide')
      // cash('#upload-modal-preview').assign(this.$data)
    }
    const clearDropzone = () => {
      Dropzone.forElement('#dropzoneMultipleRef').removeAllFiles(true)
      while (filesToSave.value.length > 0) {
        filesToSave.value.pop()
      }
    }
    const errorNotificationToggle = () => {
      Toastify({
        node: cash('#error-notification').clone().removeClass('hidden')[0],
        duration: 3000,
        newWindow: true,
        close: true,
        gravity: 'top',
        position: 'right',
        stopOnFocus: true
      }).showToast()
    }
    const getDropZoneInstance = () => {
      return dropzoneMultipleRef.value
    }
    // const setupDropZone = () => {
    //   const elDropzoneMultipleRef = getDropZoneInstance()
    //   elDropzoneMultipleRef.dropzone.on('success', file => {
    //     filesToSave.value.push(file)
    //     // uploadFileToBackend(file.name, file, props.destination)
    //   })
    //   // elDropzoneMultipleRef.dropzone.on('error', () => {
    //   //   alert(this.i18n('upload.errors.noMoreFiles'))
    //   // })
    // }

    // onMounted(() => {
    //   setupDropZone()
    // })
    return {
      numberOfFolders,
      filesToSave,
      path,
      hideModal,
      getDropZoneInstance,
      clearDropzone,
      // setupDropZone,
      errorNotificationToggle
    }
  },
  data() {
    return {
      loading: false,
      uploadLoading: false,
      seperatePdfPages: false
      // filesToSave: []
    }
  },
  computed: {
    isPdfFile() {
      return this.filesToSave
        .map(file => HelperClass.getExtension(file.name))
        .includes('pdf')
    },
    isManyFilesUploads() {
      return this.filesToSave.length > 1
    }
  },
  mounted() {
    this.setupDropZone()
  },
  watch: {
    isManyFilesUploads(newVal) {
      if (newVal == true) {
        this.seperatePdfPages = false
      }
    }
  },
  methods: {
    ...mapActions({
      doFetch: 'shareStore/doFetch',
      doFetchFirestore: 'shareStore/doFetchFirestore',
      doUploadFiles: 'shareStore/doUploadFiles'
    }),
    i18n(key, args) {
      return this.$t(key, args)
    },
    setupDropZone() {
      const elDropzoneMultipleRef = this.getDropZoneInstance()
      elDropzoneMultipleRef.dropzone.on('success', file => {
        this.filesToSave.push(file)
      })

      elDropzoneMultipleRef.dropzone.on('complete', (file) => {
        this.uploadLoading = false
      })

      elDropzoneMultipleRef.dropzone.on('uploadprogress', () => {
        this.uploadLoading = true
      })

      elDropzoneMultipleRef.dropzone.on('error', () => {
        // const errorMessage = this.$i18n.locale == 'ar' ? 'لا مزيد من الملفات' : 'No more files please!'
        const errorMessage = this.$i18n.locale == 'ar' ? 'الحد الأقصى لحجم الملف المسموح به هو 32 ميغا بايت' : 'The maximum file size allowed is 32MB'
        Message.error(errorMessage)
        console.error(errorMessage)
      })
    },
    doCancel() {
      this.filesToSave = []
      this.clearDropzone()
      // this.setupDropZone()
    },
    doReset() {
      this.loading = false
      this.seperatePdfPages = false
      // this.filesToSave = []
      // this.filesToSave.splice(0, this.filesToSave.length)
      setTimeout(() => {
        this.clearDropzone()
      }, 500)
    },
    async doSave(files) {
      if (files.length > 0) {
        this.loading = true
        if (this.seperatePdfPages) {
          const file = files[0]
          this.parsePdfPages(file.name, file, this.destination)
        } else {
          this.doUploadFiles({
            files: files,
            path: this.destination,
            location: 'Share'
          })
        }

        this.doReset()
      } else {
        this.errorNotificationToggle()
      }
    },
    doSaveIsDone() {
      this.loading = false
      this.seperatePdfPages = false
      this.filesToSave = []
      this.$emit('close')
      this.hideModal()
      this.clearDropzone()
      this.setupDropZone()
    },
    async parsePdfPages(fileName, file, destination) {
      const data = {
        path: destination,
        name: fileName,
        currentUserId: this.currentUser ? this.currentUser.id : '',
        printBarcode: !!this.printBarcodeToggle
      }
      const formData = new FormData()
      formData.append('file', file)
      const api = axios.create({ baseURL: backendUrl })
      api.post('/splitPdf', formData, {
        headers: {
          data: JSON.stringify(data),
          'Content-Type': 'multipart/form-data',
          'Access-Control-Allow-Origin': '*'
        }
      }).then(res => {
        this.$emit('close')
      })
    }
  }
})
</script>
<style>
</style>

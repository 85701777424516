<template>
  <div>
    <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
      <h2 class="text-lg font-medium ">{{ i18n('users.title') }}</h2>
    </div>
    <!-- BEGIN: HTML Table Data -->
    <div class="intro-y box p-5 mt-5 " v-if="!loading">
      <div class="flex flex-col sm:flex-row sm:items-end xl:items-start ">
        <form
          id="tabulator-html-filter-form"
          class="xl:flex"
          :class="isRTL ? 'sm:ml-auto' : 'sm:mr-auto'"
        >
          <div class="sm:flex items-center sm:mr-4 ">
            <label class="w-12 flex-none xl:w-auto xl:flex-initial mr-2 ml-2">{{
              i18n('users.filter.field')
            }}</label>
            <select
              id="tabulator-html-filter-field"
              v-model="filter.field"
              class="form-select w-full sm:w-32 2xl:w-full mt-2 sm:mt-0 sm:w-auto dark:bg-dark-9 "
            >
              <option value="name">{{ i18n('users.filter.name') }}</option>
              <option value="emailAddress">{{
                i18n('users.filter.emailAddress')
              }}</option>
              <option value="phoneNumber">{{
                i18n('users.filter.phoneNumber')
              }}</option>
              <option value="username">{{
                i18n('users.filter.username')
              }}</option>
            </select>
          </div>
          <div class="sm:flex items-center sm:mr-4 mt-2 xl:mt-0">
            <label class="w-12 flex-none xl:w-auto xl:flex-initial mr-2 ml-2">{{
              i18n('users.filter.value')
            }}</label>
            <input
              id="tabulator-html-filter-value"
              v-model="filter.value"
              type="text"
              class="form-control sm:w-40 2xl:w-full mt-2 sm:mt-0 dark:bg-dark-9"
              :placeholder="i18n('users.filter.searchUsers')"
            />
          </div>
          <div class="mt-2 xl:mt-0">
            <button
              id="tabulator-html-filter-go"
              type="button"
              class="btn text-white bg-theme-39 w-full sm:w-16 sm:mr-3"
              @click="filterUsers()"
              style="background-color:#d5aa0f"
            >
              {{ i18n('users.filter.go') }}
            </button>
            <button
              id="tabulator-html-filter-reset"
              type="button"
              class="btn btn-secondary dark:text-black w-full sm:w-24 mt-2 sm:mt-0 sm:ml-1 sm:mr-1"
              @click="fetchUsers()"
            >
              {{ i18n('users.filter.reset') }}
            </button>
          </div>
        </form>
        <div class="flex mt-5 sm:mt-0">
          <button
            id="tabulator-print"
            class="btn btn-outline-secondary w-1/2 sm:w-auto"
            @click="goToCreateUser()"
          >
            <PlusIcon class="w-4 h-4" />
            {{ i18n('users.add') }}
          </button>
        </div>

        <div class="flex mt-5 sm:mt-0">
          <div class="dropdown w-1/2 sm:w-auto lg:ml-2 lg:mr-2">
            <div class="dropdown-menu w-40">
              <div class="dropdown-menu__content box dark:bg-dark-1 p-2">
                <a
                  href="javascript:;"
                  data-toggle="modal"
                  class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                  data-target="#activate-modal-preview"
                  @click="activateManyUsers()"
                >
                  <UnlockIcon class="w-4 h-4 mr-1 ml-1" />
                  {{ i18n('users.activate') }}
                </a>
                <a
                  href="javascript:;"
                  data-toggle="modal"
                  class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                  data-target="#disable-modal-preview"
                  @click="disableManyUsers()"
                >
                  <LockIcon class="w-4 h-4 mr-1 ml-1" />
                  {{ i18n('users.disable') }}
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="overflow-x-auto">
        <!-- scrollbar-hidden -->
        <table class="table table-report sm:mt-2" v-if="rows.length > 0">
          <thead>
            <tr>
              <th class="border-b-2 dark:border-dark-5 whitespace-nowrap">#</th>
              <th class="text-center whitespace-nowrap">
                {{ i18n('users.table.name') }}
              </th>
              <th class="text-center whitespace-nowrap">
                {{ i18n('users.table.username') }}
              </th>
              <th class="text-center whitespace-nowrap">
                {{ i18n('users.table.partition') }}
              </th>
              <th class="text-center whitespace-nowrap">
                {{ i18n('users.table.company') }}
              </th>
              <th class="text-center whitespace-nowrap">
                {{ i18n('users.table.department') }}
              </th>
              <th class="text-center whitespace-nowrap">
                {{ i18n('users.table.emailAddress') }}
              </th>
              <th class="text-center whitespace-nowrap">
                {{ i18n('users.table.tempPassword') }}
              </th>
              <th class="text-center whitespace-nowrap">
                {{ i18n('users.table.phoneNumber') }}
              </th>
              <th class="text-center whitespace-nowrap">
                {{ i18n('users.table.roles') }}
              </th>
              <th class="text-center whitespace-nowrap">
                {{ i18n('users.table.createdAt') }}
              </th>
              <th class="text-center whitespace-nowrap">
                {{ i18n('users.table.status') }}
              </th>
              <th class="text-center whitespace-nowrap">
                {{ i18n('users.table.actions') }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(row, index) in paginated" :key="index">
              <td class="">
                <div
                  class="text-gray-600 text-xs whitespace-nowrap mt-0.5 whitespace-nowrap"
                >
                  #{{ (page + 1) * 5 - 5 + index + 1 }}
                </div>
              </td>
              <td class="text-center">
                <div class="font-medium whitespace-nowrap">
                  {{ presenter(row, 'name') }}
                </div>
              </td>
              <td class="text-center">
                <div class="font-medium whitespace-nowrap">
                  {{ presenter(row, 'username') }}
                </div>
              </td>
              <td class="text-center">
                <div class="font-medium whitespace-nowrap">
                  {{ presenterArr(row.partition, 'name') }}
                </div>
              </td>
              <td class="text-center">
                <div class="font-medium whitespace-nowrap">
                  {{ presenterArr(row.company, 'name') }}
                </div>
              </td>
              <td class="text-center">
                <div class="font-medium whitespace-nowrap">
                  {{ presenterArr(row.department, 'name') }}
                </div>
              </td>
              <td class="text-center">
                <div href="" class="font-medium whitespace-nowrap ">
                  {{ presenter(row, 'emailAddress') }}
                </div>
              </td>
              <td class="text-center">
                <div
                  class="font-medium whitespace-nowrap flex justify-center items-center"
                  v-if="!row.isOwner && row.tempPassword"
                >
                  <input
                    v-model="row.tempPassword"
                    :type="row.visibility ? 'text' : 'password'"
                    readonly
                    :maxlength="row.tempPassword.length"
                    :size="row.tempPassword.length"
                    style="outline: unset !important; box-shadow: unset !important; color: green;"
                  />
                  <button
                    @click="row.visibility = !row.visibility"
                    class="dark:text-black"
                    :class="isRTL ? 'mr-2' : 'ml-2'"
                    style="outline: unset !important; box-shadow: unset !important; color: #718096;"
                  >
                    <span
                      class="material-icons-outlined"
                      style="font-size: 18px; vertical-align: middle;"
                      v-if="row.visibility"
                      >visibility</span
                    >
                    <span
                      class="material-icons-outlined"
                      style="font-size: 18px; vertical-align: middle;"
                      v-else
                      >visibility_off</span
                    >
                  </button>
                </div>
              </td>
              <td class="text-center">
                <div href="" class="font-medium whitespace-nowrap ">
                  {{ presenter(row, 'phoneNumber') }}
                </div>
              </td>
              <td class="text-center">
                <div href="" class="font-medium whitespace-nowrap ">
                  {{ presenter(row, 'roles') }}
                </div>
              </td>
              <td class="text-center">
                <div href="" class="font-medium whitespace-nowrap ">
                  {{ formatDate(row.createdAt) }}
                </div>
              </td>
              <td class="w-40">
                <div
                  class="flex items-center justify-center"
                  :class="{
                    'text-theme-38': !row.isActive,
                    'text-theme-37': row.isActive
                  }"
                >
                  <CheckSquareIcon class="w-4 h-4 mr-2 ml-2" />
                  {{
                    row.isActive
                      ? i18n('users.activated')
                      : i18n('users.disabled')
                  }}
                </div>
              </td>
              <td class="table-report__action w-56">
                <div class="flex justify-center items-center  cursor-pointer">
                  <a
                    href="javascript:;"
                    class="text-theme-37 mr-2"
                    v-if="currentUser.id != row.id"
                    @click="editUser(row)"
                    ><EditIcon class="w-6 h-6 mr-1"
                  /></a>
                  <a
                    v-if="currentUser.isOwner && currentUser.id != row.id"
                    href="javascript:;"
                    class="text-theme-38"
                    data-toggle="modal"
                    data-target="#delete-modal-preview"
                    type="button"
                    id="deleteModal"
                    @click="selectedUser = row"
                  >
                    <TrashIcon class="w-6 h-6 mr-1" />
                  </a>
                  <a
                    href="javascript:;"
                    class="text-theme-38"
                    data-toggle="modal"
                    data-target="#disable-modal-preview"
                    id="disableModal"
                    v-if="
                      row.isActive &&
                        currentUser.id != row.id &&
                        currentUser.isOwner
                    "
                    @click="disableUser(row)"
                    ><LockIcon class="w-6 h-6 mr-1"
                  /></a>
                  <a
                    href="javascript:;"
                    class="text-theme-37"
                    v-if="
                      !row.isActive &&
                        currentUser.id != row.id &&
                        currentUser.isOwner
                    "
                    data-toggle="modal"
                    id="enableModal"
                    data-target="#activate-modal-preview"
                    @click="activateUser(row)"
                    ><UnlockIcon class="w-6 h-6 mr-1"
                  /></a>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <div
          class="flex justify-center items-center"
          style="margin: 100px;"
          v-else
        >
          <div>
            <div class="flex justify-center">
              <UsersIcon style="height:125px; width: 125px;" />
            </div>
            <h1 class="text-4xl">{{ i18n('users.noUsers') }}</h1>
          </div>
        </div>
      </div>
    </div>

    <div v-else style="width:100%; height:80vh;" class="flex justify-center">
      <LoadingIcon icon="grid" class="" style="width:100px" />
    </div>
    <div>
      <paginationVue
        :pageCount="pageCount"
        :page="page"
        @prevPage="prevPage"
        @nextPage="nextPage"
        v-if="pageCount > 1"
      />
    </div>
    <!-- END: HTML Table Data -->
    <!-- START: Delete Modal -->
    <DeleteModal
      :showModal="!!selectedUser"
      item="user"
      @delete="doDeleteUser(selectedUser)"
    />
    <ActivateModal item="user" />
    <DisableModal item="user" />
    <!-- END: Delete Modal -->
  </div>
</template>

<script>
import { defineComponent, ref, reactive } from 'vue'
import xlsx from 'xlsx'
import DeleteModal from '@/components/modals/delete-modal.vue'
import ActivateModal from '@/components/modals/activate-modal.vue'
import DisableModal from '@/components/modals/disable-modal.vue'
import moment from 'moment'
import { mapActions, mapGetters } from 'vuex'
import paginationVue from '../../components/pagination/pagination.vue'
export default defineComponent({
  components: {
    DeleteModal,
    ActivateModal,
    DisableModal,
    paginationVue
  },
  data() {
    return {
      visibility: false
    }
  },
  computed: {
    ...mapGetters({
      currentUser: 'authStore/currentUser',
      rows: 'usersStore/rows',
      loading: 'usersStore/loading'
    }),
    pageCount() {
      const l = this.rows.length
      const s = this.size
      return Math.ceil(l / s)
    },
    paginated() {
      const start = this.page * this.size
      const end = start + this.size
      return this.rows.slice(start, end)
    },
    isRTL() {
      return false
    },
    selectedUsers() {
      return this.rows.filter(el => {
        if (el.id != this.currentUser.id) {
          return el.selected
        }
      })
    }
  },
  setup() {
    const tableLimit = ref(10)
    const tableRef = ref()
    const tabulator = ref()
    const selected = ref([])
    const selectAll = ref(false)
    const selectedUser = ref(null)
    const page = ref(0)
    const size = ref(5)
    const itemIndex = ref(0)
    const filter = reactive({
      field: 'username',
      value: ''
    })
    // Filter function
    const onFilter = () => {
      tabulator.value.setFilter(filter.field, filter.type, filter.value)
    }
    // On reset filter
    const onResetFilter = () => {
      filter.field = 'username'
      filter.value = ''
      onFilter()
    }
    // Export
    const onExportCsv = () => {
      tabulator.value.download('csv', 'data.csv')
    }
    const onExportJson = () => {
      tabulator.value.download('json', 'data.json')
    }
    const onExportXlsx = () => {
      const win = window
      win.XLSX = xlsx
      tabulator.value.download('xlsx', 'data.xlsx', {
        sheetName: 'Products'
      })
    }
    const onExportHtml = () => {
      tabulator.value.download('html', 'data.html', {
        style: true
      })
    }
    // Print
    const onPrint = () => {
      tabulator.value.print()
    }

    return {
      selectedUser,
      selected,
      selectAll,
      tableLimit,
      tableRef,
      filter,
      onFilter,
      onResetFilter,
      onExportCsv,
      onExportJson,
      onExportXlsx,
      onExportHtml,
      onPrint,
      page,
      size,
      itemIndex
    }
  },
  methods: {
    ...mapActions({
      getCurrentUser: 'authStore/getCurrentUser',
      saveCurrentUser: 'authStore/saveCurrentUser',
      doFetch: 'usersStore/doFetch',
      doFilterUsers: 'usersStore/doFilter',
      toggleUserStatus: 'usersStore/toggleUserStatus',
      doDeleteUser: 'usersStore/doDeleteUser'
    }),
    nextPage() {
      this.page++
    },
    prevPage() {
      this.page--
    },
    i18n(key, args) {
      return this.$t(key, args)
    },
    presenter(row, fieldName) {
      const val = row ? row?.[fieldName] : null
      return val || 'ـــ'
    },
    presenterArr(row, fieldName) {
      try {
        const val =
          row && row.length > 0 ? row.map(r => r[fieldName]).join(', ') : null
        return val || 'ـــ'
      } catch (error) {
        return 'ـــ'
      }
    },
    editUser(row) {
      this.$router.push({ name: 'userEdit', params: { id: row.id } })
    },
    select() {
      if (!this.selectAll) {
        this.rows.forEach(element => {
          element.selected = true
          this.selectedUsers.push(element.id)
        })
      } else {
        this.rows.forEach(element => {
          element.selected = false
          this.selectedUsers = []
        })
      }
    },
    formatDate(date) {
      return moment.unix(date.seconds).format('DD/MM/YYYY')
    },
    filterUsers() {
      this.doFilterUsers({ field: this.filter.field, value: this.filter.value })
    },
    async fetchUsers() {
      await this.doFetch()
    },
    goToCreateUser() {
      this.$router.push({ name: 'users/add' })
    },
    deleteManyUsers() {
      this.selectedUsers.forEach(element => {
        this.selectedUser = element
        this.deleteUser()
      })
    },
    disableManyUsers() {
      this.selectedUsers.forEach(element => {
        this.disableUser(element)
      })
    },
    async disableUser(user) {
      await this.toggleUserStatus(user)
    },
    activateManyUsers() {
      this.selectedUsers.forEach(element => {
        this.activateUser(element)
      })
    },
    async activateUser(user) {
      await this.toggleUserStatus(user)
    }
  },
  async mounted() {
    this.getCurrentUser()
    await this.fetchUsers()
    this.selectAll = false
  }
})
</script>

<style scoped>
.table-report:not(.table-report--bordered):not(.table-report--tabulator) tr {
  border-radius: 0px !important;
  box-shadow: 15px 0px 15px #0000000b;
}

.table-report:not(.table-report--bordered):not(.table-report--tabulator) td {
  border-radius: 0px !important;
  box-shadow: unset !important;
}

.pagination-ar {
  margin-right: unset !important;
  margin-left: auto !important;
}
</style>

/* eslint-disable */
// import lodash from 'lodash'
import FirebaseRepository from '../shared/firebase/firebase-repository'
import {
  // firestoreAction,
  vuexfireMutations
} from 'vuexfire'
import firebase from 'firebase/compat/app'
import 'firebase/compat/firestore'
import store from './index'

const state = () => {
  return {
    rows: [],
    scales: [],
    record: null,
    loading: false
  }
}
const getters = {
  rows: state => state.rows,
  scales: state => state.scales,
  record: state => state.record,
  loading: state => state.loading
}
const mutations = {
  ...vuexfireMutations,
  FETCH_SCALES_STARTED(state) {
    state.loading = true
  },
  FETCH_SCALES_SUCCESS(state, payload) {
    state.loading = false
    state.scales = payload
  },
  FETCH_SCALES_ERROR(state) {
    state.loading = false
    state.scales = []
  }
}
const actions = {
  async doCreateBarcodeScales() {
    try {
      const record = {
        id: 'barcodeScales',
        scales: [
          { name: 'scale1', height: 150, width: 300 },
          { name: 'scale2', height: 120, width: 230 },
          { name: 'scale3', height: 80, width: 150 }
        ],
        createdAt: firebase.firestore.Timestamp.now()
      }
      await firebase.firestore().collection('settings').doc(record.id).set(record)
      return record
    } catch (error) {
      console.error(error)
      return {}
    }
  },
  async doFindBarcodeScales({ commit, dispatch }) {
    try {
      commit('FETCH_SCALES_STARTED')
      await store.dispatch('authStore/getCurrentUser')
      const currentUser = store.getters['authStore/currentUser']
      const userScale = currentUser.settings && currentUser.settings.scale ? currentUser.settings.scale : null

      const record = await FirebaseRepository.findDocument('settings', 'barcodeScales')
      const scales = record && record.scales
        ? record.scales
        : (await dispatch('doCreateBarcodeScales')).scales

      scales.forEach((scale, index) => {
        scale['index'] = index
        scale['checked'] = userScale
          ? userScale.height === scale.height && userScale.width === scale.width
          : false
      })
      commit('FETCH_SCALES_SUCCESS', scales)
    } catch (error) {
      console.error(error)
      commit('FETCH_SCALES_ERROR')
    }
  },
  async doUpdateScaling({ dispatch }, scale) {
    try {
      const currentUser = store.getters['authStore/currentUser']
      const settings = {
          ...currentUser.settings,
          scale: scale
        }
      firebase.firestore().collection('users').doc(currentUser.id).update({
        settings: settings
      })
      dispatch('authStore/saveCurrentUser', { ...currentUser, settings }, { root: true })
    } catch (error) {
      console.error(error)
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}

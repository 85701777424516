<template>
  <div
    id="success-notification-with-buttons"
    class="toastify-content hidden flex"
  >
    <CheckCircleIcon class="text-theme-37"/>
    <div class="ml-4 mr-5 sm:mr-20">
      <div class="font-medium">{{ i18n('common.success') }}</div>
      <div class="text-gray-600 mt-1">
        {{ message }}
      </div>
      <div class="mt-2.5">
        <div class="mr-2 cursor-pointer">
         {{ button }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
  methods: {
    i18n(key, args) {
      return this.$t(key, args)
    }
  },
  props: {
    message: {
      type: String
    },
    button: {
      type: String
    },
    link: {
      type: String
    }
  }
})
</script>

<style></style>

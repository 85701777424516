import { vuexfireMutations } from 'vuexfire'
import firebase from 'firebase/compat/app'
import 'firebase/compat/firestore'
import store from './index'
import axios from 'axios'
import config from '../config'
const backendUrl = config.backendUrl

// import FirebaseRepository from '../../src/shared/firebase/firebase-repository'
const state = () => {
  return {
    rows: [],
    record: null,
    loading: false,
    destroyLoading: false
  }
}
const getters = {
  rows: state => state.rows,
  record: state => state.record,
  loading: state => state.loading,
  destroyLoading: state => state.destroyLoading
}
const mutations = {
  ...vuexfireMutations,
  FETCH_START(state) {
    state.loading = true
  },
  FETCH_SUCCESS(state) {
    state.loading = false
  },
  FETCH_ERROR(state) {
    state.loading = false
  },
  EDIT_START(state) {
    state.loading = true
  },
  EDIT_SUCCESS(state) {
    state.loading = false
  },
  EDIT_ERROR(state) {
    state.loading = false
  },
  CREATE_START(state) {
    state.loading = true
  },
  CREATE_SUCCESS(state) {
    state.loading = false
  },
  CREATE_ERROR(state) {
    state.loading = false
  },
  ADD_TO_ROWS(state, payload) {
    state.rows.push(payload)
  },
  RESET_ROWS(state) {
    state.rows = []
  },
  SET_COMPANY(state, company) {
    state.record = company
  },
  DESTROY_STARTED(state) {
    state.destroyLoading = true
  },
  DESTROY_SUCCESS(state) {
    state.destroyLoading = false
  },
  DESTROY_ERROR(state) {
    state.destroyLoading = false
  }
}
const actions = {
  async editCompany({ commit }, payload) {
    commit('EDIT_START')
    const db = firebase.firestore()
    const response = await db
      .collection('partition')
      .doc(payload.partitionId)
      .collection('company')
      .doc(payload.id)
      .update(payload.form)
    await store.dispatch('auditLogStore/log', {
      ref: `partition/${payload.partitionId}/company/${payload.id}`,
      entityName: 'company',
      entityId: payload.id,
      action: 'update',
      values: payload.form
    })
    commit('EDIT_SUCCESS')
    return response
  },
  // async editCompany(context, payload) {
  //   const db = firebase.firestore()
  //   const response = await db
  //     .collection('company')
  //     .doc(payload.id)
  //     .update(payload.form)
  //   // const companyName = payload.form.name
  //   // const folder = FirebaseRepository(
  //   //   await db.collection('files').where('', '==', '').limit(1).get()
  //   // )
  //   // const folderId = folder.length ? folder[0].id : null
  //   // const folderId = payload.form.folderId || null
  //   // if (folderId) {
  //   //   db.collection('files').doc(folderId).update({ name: `${payload.id}?:${folderId}` })
  //   // }
  //   await store.dispatch('auditLogStore/log', {
  //     ref: `company/${payload.id}`,
  //     entityName: 'company',
  //     entityId: payload.id,
  //     action: 'update',
  //     values: payload.form
  //   })
  //   return response
  // },
  async createCompany({ commit }, payload) {
    commit('CREATE_START')
    const id = firebase
      .firestore()
      .collection('ids')
      .doc().id
    const doc = await firebase
      .firestore()
      .collection('settings')
      .doc('prefix')
      .get()
    const prefixSettings = doc.data()
    const prefix = parseInt(prefixSettings.company) + 1
    const formattedPrefix = prefix.toLocaleString('en-US', {
      minimumIntegerDigits: 2,
      useGrouping: false
    })
    await firebase
      .firestore()
      .collection('settings')
      .doc('prefix')
      .update({
        company: formattedPrefix
      })
    const company = {
      id: id,
      name: payload.form.name,
      emailAddress: payload.form.email,
      phoneNumber: payload.form.phoneNumber,
      // address: payload.form.address,
      createdAt: firebase.firestore.Timestamp.now(),
      createdBy: payload.createdBy,
      // updatedAt: firebase.firestore.Timestamp.now(),
      // updatedBy: payload.createdBy,
      prefix: formattedPrefix,
      partition: payload.partitionId
    }
    await firebase
      .firestore()
      .collection('partition')
      .doc(payload.partitionId)
      .collection('company')
      .doc(id)
      .set(company)
    // debugger
    await store.dispatch('partitionStore/doFetchPartition', payload.partitionId)
    const partition = store.getters['partitionStore/record']

    const folder = await store.dispatch('dataCenterStore/createFolderWithId', {
      path: `Data Center/${partition.id}?:${partition.prefix}00${partition.id}/${id}?:${partition.prefix}${company.prefix}${id}`,
      serverPath: `Data Center/${partition.id}?:${partition.prefix}00${partition.id}`,
      destination: `Data Center/${partition.id}?:${partition.prefix}00${partition.id}/${id}`,
      id: id
    })
    firebase
      .firestore()
      .doc(`partition/${payload.partitionId}/company/${id}`)
      .update({ folderId: folder.id })
    await store.dispatch('auditLogStore/log', {
      ref: `partition/${payload.partitionId}/company/${id}`,
      entityName: 'company',
      entityId: id,
      action: 'create',
      values: company
    })
    commit('CREATE_SUCCESS')
    // @TODO mutate user state.
  },
  async doFetch({ commit }, partitionId) {
    commit('RESET_ROWS')
    commit('FETCH_START')
    const ref = firebase
      .firestore()
      .collection('partition')
      .doc(partitionId)
      .collection('company')
    const snapshot = await ref.get()
    if (snapshot.empty) {
      commit('FETCH_SUCCESS')
      return
    }

    snapshot.forEach(doc => {
      commit('ADD_TO_ROWS', doc.data())
    })
    commit('FETCH_SUCCESS')
  },
  async doFetchCompany({ commit }, { id, partitionId }) {
    const ref = firebase
      .firestore()
      .collection('partition')
      .doc(partitionId)
      .collection('company')
      .doc(id)
    const doc = await ref.get()
    commit('SET_COMPANY', doc.data())
  },
  async doFetchMultiple({ commit }, partitions) {
    commit('RESET_ROWS')
    commit('FETCH_START')
    partitions.forEach(async partitionId => {
      const ref = firebase
        .firestore()
        .collection('partition')
        .doc(partitionId)
        .collection('company')
      const snapshot = await ref.get()
      if (snapshot.empty) {
        commit('FETCH_SUCCESS')
        return
      }

      snapshot.forEach(doc => {
        commit('ADD_TO_ROWS', doc.data())
      })
    })
    commit('FETCH_SUCCESS')
  },
  async doDestroy(
    { commit, dispatch },
    { partitionId, companyId, companyPrefix }
  ) {
    try {
      commit('DESTROY_STARTED')
      await store.dispatch('partitionStore/doFetchPartition', partitionId)
      const partition = store.getters['partitionStore/record']
      const data = {
        partitionId,
        partitionPrefix: partition.prefix,
        companyId,
        companyPrefix
      }
      const response = await axios.post(`${backendUrl}/delete-company`, {
        data: JSON.stringify(data)
      })
      await dispatch('doFetch')
      commit('DESTROY_SUCCESS')
    } catch (error) {
      commit('DESTROY_ERROR')
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}

<template>
  <div>
    <MobileMenu />

    <!-- <MobileMenu :dashboard-layout="dashboardLayout" layout="side-menu" /> -->
    <div class="flex overflow-hidden">
      <nav class="side-nav">
        <a href="" class="intro-x flex items-center pl-1 pt-4 mt-3">
          <img
            alt="Tinker Tailwind HTML Admin Template"
            class="w-20"
            src="/logo.png"
          />
          <span class="hidden xl:block text-white text-lg ml-1">
            <span class="font-bold">{{ i18n('title') }}</span>
            <div class="font-bold">{{ i18n('subtitle') }}</div>
          </span>
        </a>
        <div class="side-nav__devider my-6"></div>
        <ul>
          <template v-for="(menu, menuKey) in formattedMenu">
            <!-- <li class="side-nav__devider my-6" v-if="menu == 'devider'"></li> -->
            <li v-if="hasPermissionToRead(menu.permissions)" :key="menuKey">
              <SideMenuTooltip
                tag="a"
                :content="menu.title"
                :href="
                  menu.subMenu
                    ? 'javascript:;'
                    : router.resolve({ name: menu.pageName }).path
                "
                class="side-menu"
                :class="{
                  'side-menu--active': menu.active,
                  'side-menu--open': menu.activeDropdown
                }"
                @click="linkTo(menu, router, $event)"
              >
                <div class="side-menu__icon">
                  <span class="material-icons-outlined" v-if="menu.icon == 'ShareIcon'">folder_shared</span>
                  <span class="material-icons-outlined" v-else-if="menu.icon == 'CompanyIcon'">business</span>
                  <span class="material-icons-outlined" v-else-if="menu.icon == 'auditLogsIcon'">timeline</span>
                  <span class="material-icons-outlined" v-else-if="menu.icon == 'SettingIcon'">settings</span>
                  <component :is="menu.icon" v-else />
                </div>
                <div class="side-menu__title">
                  {{ i18n(`menu.${menu.title}`) }}
                  <div
                    v-if="menu.subMenu"
                    class="side-menu__sub-icon"
                    :class="{ 'transform rotate-180': menu.activeDropdown }"
                  >
                    <ChevronDownIcon />
                  </div>
                </div>
              </SideMenuTooltip>
              <transition @enter="enter" @leave="leave">
                <ul v-if="menu.subMenu && menu.activeDropdown">
                  <li
                    v-for="(subMenu, subMenuKey) in menu.subMenu"
                    :key="subMenuKey"
                  >
                    <SideMenuTooltip
                      tag="a"
                      :content="subMenu.title"
                      :href="
                        subMenu.subMenu
                          ? 'javascript:;'
                          : router.resolve({ name: subMenu.pageName }).path
                      "
                      class="side-menu"
                      :class="{ 'side-menu--active': subMenu.active }"
                      @click="linkTo(subMenu, router, $event)"
                    >
                      <div class="side-menu__icon">
                        <ActivityIcon />
                      </div>
                      <div class="side-menu__title">
                        {{ subMenu.title }}
                        <div
                          v-if="subMenu.subMenu"
                          class="side-menu__sub-icon"
                          :class="{
                            'transform rotate-180': subMenu.activeDropdown
                          }"
                        >
                          <ChevronDownIcon />
                        </div>
                      </div>
                    </SideMenuTooltip>
                    <transition @enter="enter" @leave="leave">
                      <ul v-if="subMenu.subMenu && subMenu.activeDropdown">
                        <li
                          v-for="(lastSubMenu,
                          lastSubMenuKey) in subMenu.subMenu"
                          :key="lastSubMenuKey"
                        >
                          <SideMenuTooltip
                            tag="a"
                            :content="lastSubMenu.title"
                            :href="
                              lastSubMenu.subMenu
                                ? 'javascript:;'
                                : router.resolve({
                                    name: lastSubMenu.pageName
                                  }).path
                            "
                            class="side-menu"
                            :class="{
                              'side-menu--active': lastSubMenu.active
                            }"
                            @click="linkTo(lastSubMenu, router, $event)"
                          >
                            <div class="side-menu__icon">
                              <ZapIcon />
                            </div>
                            <div class="side-menu__title">
                              {{ lastSubMenu.title }}
                            </div>
                          </SideMenuTooltip>
                        </li>
                      </ul>
                    </transition>
                  </li>
                </ul>
              </transition>
            </li>
          </template>
        </ul>
        <div class="runProf flex justify-center mt-auto hidden xl:block">
          <a
            href="https://runprof.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src="https://firebasestorage.googleapis.com/v0/b/alghoneimy-175eb.appspot.com/o/public%2Frunprof.svg?alt=media&token=0a25f13c-e1ee-432a-8bf3-5cc58056b9dc"
              alt=""
            />
          </a>
        </div>
        <div class="runProf_small flex justify-center mt-auto block xl:hidden">
          <a
            href="https://runprof.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src="/RunProfLogo1.png" alt="" style="width: 70px;" />
          </a>
        </div>
      </nav>
      <!-- END: Side Menu -->
      <!-- BEGIN: Content -->
      <div :class="{ 'content--dashboard': dashboardLayout }" class="content">
        <TopBar />
        <router-view />
      </div>
      <!-- END: Content -->
    </div>
  </div>
</template>

<script>
import { defineComponent, computed, provide, onMounted, ref, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from '@/store'
import { helper as $h } from '@/utils/helper'
import TopBar from '@/components/top-bar/Main.vue'
import MobileMenu from '@/components/mobile-menu/Main.vue'
import DarkModeSwitcher from '@/components/dark-mode-switcher/Main.vue'
import SideMenuTooltip from '@/components/side-menu-tooltip/Main.vue'
import { linkTo, nestedMenu, enter, leave } from './index'
import { mapActions, mapGetters } from 'vuex'
import firebase from 'firebase/compat/app'
import 'firebase/compat/firestore'
import { doc, onSnapshot } from 'firebase/firestore'

export default defineComponent({
  components: {
    TopBar,
    MobileMenu,
    DarkModeSwitcher,
    SideMenuTooltip
  },
  setup() {
    const dashboardLayout = ref(false)
    const route = useRoute()
    const router = useRouter()
    const store = useStore()
    const formattedMenu = ref([])
    const sideMenu = computed(() =>
      nestedMenu(store.state.sideMenu.menu, route)
    )
    provide('setDashboardLayout', newVal => {
      dashboardLayout.value = newVal
    })
    watch(
      computed(() => route.path),
      () => {
        formattedMenu.value = $h.toRaw(sideMenu.value)
        dashboardLayout.value = false
      }
    )
    onMounted(() => {
      cash('body')
        .removeClass('error-page')
        .removeClass('login')
        .removeClass('login-rtl')
        .addClass('main')
      formattedMenu.value = $h.toRaw(sideMenu.value)
    })

    return {
      dashboardLayout,
      formattedMenu,
      router,
      linkTo,
      enter,
      leave
    }
  },
  computed: {
    ...mapGetters({
      currentUser: 'authStore/currentUser'
    })
  },
  async created() {
    await this.getCurrentUser()
    const db = firebase.firestore()
    onSnapshot(doc(db, 'users', this.currentUser.id), doc => {
      const user = doc.data()
      if (!user?.isActive) {
        this.doSignout()
      }
    })
  },
  methods: {
    ...mapActions({
      getCurrentUser: 'authStore/getCurrentUser',
      saveCurrentUser: 'authStore/saveCurrentUser',
      doSignout: 'authStore/doSignout'
    }),
    i18n(key, args) {
      return this.$t(key, args)
    },
    hasPermissionToRead(permissions) {
      if (this.currentUser != null) {
        return permissions.includes(this.currentUser.roles)
      }
    },
    logout() {
      this.doSignout()
    }
  }
})
</script>

<style lang="scss">
.runProf {
  position: absolute;
  // margin-bottom: auto !important;
  bottom: 5%;
  left: 4%;
  @media only screen and (max-width: 1024px) {
    width: 80px;
    left: 1%;
    // display: none;
  }
}

.runProf_small {
  position: absolute;
  // margin-bottom: auto !important;
  bottom: 5%;
  left: 20px;
  // @media only screen and (max-width: 1024px) {
  //   width: 80px;
  //   left: 1%;
  //   // display: none;
  // }
}

[dir='rtl'] .runProf {
  position: absolute;
  bottom: 5%;
  left: 91% !important;
  @media only screen and (max-width: 1024px) {
    width: 80px;
    left: 90% !important;
    // display: none;
  }
  @media only screen and (max-width: 768px) {
    width: 80px;
    left: 87% !important;
    // display: none;
  }
}
.side-nav .side-menu {
  padding-right: 1.25rem !important;
}
</style>

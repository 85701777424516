const firebaseConfig = {
  apiKey: 'AIzaSyB0MA2kMOqUXU05FtbcwbriWcsOWCPic7k',
  authDomain: 'el-ghoneimy-dc.firebaseapp.com',
  projectId: 'el-ghoneimy-dc',
  storageBucket: 'el-ghoneimy-dc.appspot.com',
  messagingSenderId: '311301551671',
  appId: '1:311301551671:web:db0d64484e84025fc471b2',
  measurementId: 'G-5Q386WNPX2'
}

const editorUrl = 'https://editor.elghoniemygroup.com'

const REGION = 'europe-west3'
const BASE_URL = `https://${REGION}-${
  firebaseConfig.projectId
}.cloudfunctions.net`

// Cloud Functions
const backendUrl = `${BASE_URL}/api`
const backendUrlUser = `${BASE_URL}/usersApi`
// const backendUrlFile = `${BASE_URL}/file`
const backendUrlFile = 'https://filev2-xgmolqemda-ew.a.run.app'
// const backendUrlFile = 'https://europe-west3-el-ghoneimy-dc.cloudfunctions.net/file'

export default {
  firebaseConfig,
  backendUrl,
  backendUrlFile,
  backendUrlUser,
  editorUrl
}

// import authStore from '../store/auth-store'
// import i18n from '../vueI18n'
import service from '../auth/auth-service'

export default {
  async beforeRouteEnter(to, from, next) {
    const language = localStorage.getItem('language')
      ? localStorage.getItem('language')
      : 'ar'

    if (!to.meta || !to.meta.auth) {
      return next()
    }
    const currentUser = service.decryption(
      localStorage.getItem('currentUser'),
      'secret-c-u'
    )
    if (!currentUser) {
      return next({ path: `/${language}/auth/login` })
    }

    const path = to.path.substring(4, to.path.length)
    if (
      currentUser?.roles?.includes('Employee') &&
      (to.path?.includes('users') ||
        to.path?.includes('company') ||
        path === 'data-center')
    ) {
      next({ path: `/${language}/data-center-employee` })
      return
    }
    if (currentUser?.roles?.includes('Employee') && to.path == '/') {
      return next({ path: `/${language}/data-center-employee` })
    }
    if (to.path == '/') {
      return next({ path: `/${language}/data-center` })
    }
    next()
  }
}

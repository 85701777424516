import firebase from 'firebase/compat'
import 'firebase/compat/auth'
import 'firebase/compat/firestore'
import firebaseInit from './firebase-init'

import { AuthToken } from './auth-token'
import axios from 'axios'
import vueI18n from '@/vueI18n'
import config from '@/config'
const baseUrl = config.backendUrl

export default class AuthService {
  static init() {
    return firebaseInit()
  }

  static onAuthStateChanged(callbackSuccess, callbackError) {
    return firebase.auth().onAuthStateChanged(callbackSuccess, callbackError)
  }

  static signout() {
    return firebase.auth().signOut()
  }

  static async createCustomToken() {
    try {
      // debugger
      const idToken = await AuthToken.get()
      const response = await axios.get(`${baseUrl}/generateCustomToken`, {
        headers: {
          authorization: idToken ? `Bearer ${idToken}` : '',
          'accept-language': vueI18n.locale || 'en'
        }
      })
      return response.status == 200 ? response.data : null
    } catch (error) {
      console.error(error)
      return null
    }
  }

  // #region [ Encryption Functions ]
  // ================================================================= //
  //                          ENCRYPT OBJECT                           //
  // ================================================================= //
  static encryptObject(object, key) {
    const cryptoJSON = require('crypto-json')
    const algorithm = 'aes256'
    const encoding = 'hex'
    const password = key
    const keys = []

    for (const [k, value] of Object.entries(object)) {
      if (value) {
        keys.push(k)
      }
    }
    const output = cryptoJSON.encrypt(object, password, {
      encoding,
      keys,
      algorithm
    })
    return output
  }
  // ================================================================= //
  //                          DECRYPT OBJECT                           //
  // ================================================================= //
  // eslint-disable-next-line
  static decryptObject(encryptedObject, key) {
    const cryptoJSON = require('crypto-json')
    const algorithm = 'aes256'
    const encoding = 'hex'
    const password = key
    const keys = []

    for (const [k, value] of Object.entries(encryptedObject)) {
      if (value) {
        keys.push(k)
      }
    }

    const output = cryptoJSON.decrypt(encryptedObject, password, {
      encoding,
      keys,
      algorithm
    })
    return output
  }

  // ================================================================= //
  //                          ENCRYPT STRING                           //
  // ================================================================= //
  static encryptString(message, key) {
    const CryptoJS = require('crypto-js')

    // Encrypt
    var ciphertext = CryptoJS.AES.encrypt(message, key)
    return ciphertext
  }

  // ================================================================= //
  //                          DECRYPT STRING                           //
  // ================================================================= //
  static decryptString(ciphertext, key) {
    const CryptoJS = require('crypto-js')

    // Decrypt
    var bytes = CryptoJS.AES.decrypt(ciphertext.toString(), key)
    var plaintext = bytes.toString(CryptoJS.enc.Utf8)
    return plaintext
  }
  // ================================================================= //
  //                      Object ENCRYPTION OTHER WAY                  //
  // ================================================================= //
  // eslint-disable-next-line
  static encryption(data, key) {
    const CryptoJS = require('crypto-js')

    // Encrypt
    var ciphertext = CryptoJS.AES.encrypt(JSON.stringify(data), key)
    return ciphertext
  }

  // ================================================================= //
  //                      Object DECRYPTION OTHER WAY                  //
  // ================================================================= //
  static decryption(cipherData, key) {
    try {
      var CryptoJS = require('crypto-js')

      // Decrypt
      var bytes = CryptoJS.AES.decrypt(cipherData.toString(), key)
      var decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8))
      return decryptedData
    } catch (error) {
      return ''
    }
  }
  // #endregion
}

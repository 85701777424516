/* eslint-disable */
// import FirebaseRepository from '../shared/firebase/firebase-repository'
import { vuexfireMutations } from 'vuexfire'
import firebase from 'firebase/compat/app'
import 'firebase/compat/firestore'
import { getAuth, updatePassword } from 'firebase/auth'
import store from './index.js'
import axios from 'axios'
import config from '../config'
const backendUrlUser = config.backendUrlUser

const state = () => {
  return {
    rows: [],
    loading: false,
    record: null
  }
}
const getters = {
  rows: state => state.rows,
  loading: state => state.loading,
  record: state => state.record
}
const mutations = {
  ...vuexfireMutations,
  FETCH_START(state) {
    state.loading = true
  },
  FETCH_SUCCESS(state) {
    state.loading = false
  },
  FETCH_ERROR(state) {
    state.loading = false
  },
  LOADING_START(state) {
    state.loading = true
  },
  LOADING_FINISHED(state) {
    state.loading = false
  },
  ADD_TO_ROWS(state, payload) {
    state.rows.push(payload)
    // state.rows.unshift(payload)
  },
  ADD_TO_FIRST(state, payload) {
    state.rows.unshift(payload)
  },
  RESET_ROWS(state) {
    state.rows = []
  },
  SET_USER(state, user) {
    state.record = user
  }
}
const actions = {
  async toggleUserStatus({ dispatch, commit }, user) {
    try {
      var bodyFormData = new FormData()
      bodyFormData.append('id', user.id)
      const res = await axios.post(backendUrlUser, {
        headers: {
          'Content-Type': 'multipart/form-data'
        },
        data: {
          id: user.id,
          uid: user.uid,
          isActive: !user.isActive
        }
      })
      // store.dispatch('auditLogStore/log', {
      //   ref: `users/${user.id}`,
      //   entityName: 'users',
      //   entityId: user.id,
      //   action: 'update',
      //   values: user
      // })
      if (res.status == 200) {
        dispatch('doFetch')
      }
    } catch (error) {
      console.log(error)
    }
  },
  async deleteUser({ dispatch, commit }, user) {
    try {
      const res = await axios.delete(`${backendUrlUser}/${user.uid}`)
      if (res.status == 200) {
        // dispatch('doFetch')
        return true
      }
      return false
    } catch (error) {
      console.log(error)
    }
  },
  changePassword(context, payload) {
    firebase
      .auth()
      .signInWithEmailAndPassword(payload.email, payload.oldPassword)
      .then(async response => {
        const auth = getAuth()
        const user = auth.currentUser
        updatePassword(user, payload.newPassword)
          .then(() => {})
          .catch(error => {
            console.log(error)
          })
      })
  },
  editProfile(context, payload) {
    const db = firebase.firestore()
    db.collection('users')
      .doc(payload.id)
      .update(payload.form)
    store.dispatch('authStore/fetchMe', payload.form.emailAddress)
    store.dispatch('auditLogStore/log', {
      ref: `users/${payload.id}`,
      entityName: 'users',
      entityId: payload.id,
      action: 'update-profile',
      values: payload.form
    })
  },
  async doAppendUser({ commit }, payload) {
    const partition = payload.partition
      ? await Promise.all(payload.partition.map(async part => await part.get()))
      : null
    // const company = await payload.company.get()
    const company = payload.company
      ? await Promise.all(payload.company.map(async comp => await comp.get()))
      : null
    // const department = await payload.department.get()
    const department = payload.department
      ? await Promise.all(payload.department.map(async dep => await dep.get()))
      : null
    const user = {
      ...payload,
      // company: company ? company.data() : null,
      partition: partition ? partition.map(part => part.data()) : null,
      company: company ? company.map(comp => comp.data()) : null,
      department: department ? department.map(dep => dep.data()) : null
      // department: department ? department.data() : null
    }
    commit('ADD_TO_FIRST', user)
  },
  async doFetch({ commit }) {
    // debugger
    commit('RESET_ROWS')
    commit('FETCH_START')
    const usersRef = firebase
      .firestore()
      .collection('users')
      .orderBy('createdAt', 'desc')
    const snapshot = await usersRef.get()
    if (snapshot.empty) {
      commit('FETCH_SUCCESS')
      return
    }

    snapshot.forEach(async doc => {
      var user = doc.data()
      user.partition = user.partition
        ? await Promise.all(user.partition.map(async part => await part.get()))
        : null
      user.company = user.company
        ? await Promise.all(user.company.map(async comp => await comp.get()))
        : null
      user.department = user.department
        ? await Promise.all(user.department.map(async dep => await dep.get()))
        : null
      user.partition = user.partition
        ? user.partition.map(part => part.data())
        : null
      user.company = user.company ? user.company.map(comp => comp.data()) : null
      user.department = user.department
        ? user.department.map(dep => dep.data())
        : null
      commit('ADD_TO_ROWS', user)
    })
    commit('FETCH_SUCCESS')
  },
  async doFetchUser({ commit }, id) {
    const userRef = firebase
      .firestore()
      .collection('users')
      .doc(id)
    const doc = await userRef.get()
    commit('SET_USER', doc.data())
  },
  async doFilter({ commit }, { field, value }) {
    commit('RESET_ROWS')
    const usersRef = firebase.firestore().collection('users')
    const snapshot = await usersRef.where(field, '>=', value).get()
    if (snapshot.empty) {
      return
    }

    snapshot.forEach(doc => {
      commit('ADD_TO_ROWS', doc.data())
    })
  },
  async editUser({ commit }, payload) {
    const { name, email, phoneNumber, username, departments } = payload.form
    console.log('deps', departments)
    commit('LOADING_START')
    const getUniqueListBy = (arr, key) => {
      return [...new Map(arr.map(item => [item[key], item])).values()]
    }
    const partitions = [
      ...new Set(
        departments.map(dep => {
          if (dep.type === 'partition') {
            return dep.id
          } else {
            return dep.partition
          }
        })
      )
    ]
    const companies = getUniqueListBy(
      departments
        .map(dep => {
          if (dep.type === 'company') {
            return { id: dep.id, partition: dep.partition }
          } else if (dep.type === 'department') {
            return { id: dep.company, partition: dep.partition }
          }
        })
        .filter(dep => dep),
      'id'
    )
    const filteredDepartments = departments.filter(
      dep => dep.type === 'department'
    )
    var obj = {
      name: name,
      emailAddress: email,
      phoneNumber: phoneNumber,
      username: username,
      roles: payload.roles,
      partition:
        partitions && partitions.length
          ? partitions.map(part =>
              firebase.firestore().doc(`partition/${part}`)
            )
          : null,
      company:
        companies && companies.length
          ? companies.map(comp =>
              firebase
                .firestore()
                .doc(`partition/${comp.partition}/company/${comp.id}`)
            )
          : null,
      department:
        filteredDepartments && filteredDepartments.length
          ? filteredDepartments.map(dep =>
              firebase
                .firestore()
                .doc(
                  `partition/${dep.partition}/company/${dep.company}/department/${dep.id}`
                )
            )
          : null
    }
    await firebase
      .firestore()
      .collection('users')
      .doc(payload.id)
      .update({ ...obj })
    await store.dispatch('auditLogStore/log', {
      ref: `users/${payload.id}`,
      entityName: 'users',
      entityId: payload.id,
      action: 'update',
      values: obj
    })
    commit('LOADING_FINISHED')
  },
  async editUserPassword({ commit }, { uid, password }) {
    commit('LOADING_START')
    await axios.post(`${backendUrlUser}/editPassword`, {
      data: {
        uid,
        password
      }
    })
    commit('LOADING_FINISHED')
  },
  async doDeleteUser({ dispatch }, user) {
    if (user) {
      const status = await dispatch('deleteUser', user)
      // await dispatch('toggleUserStatus', user)
      if (!status) return
      await firebase
        .firestore()
        .collection('users')
        .doc(user.id)
        .delete()
      dispatch('doFetch')
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}

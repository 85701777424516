<template>
  <div class="i18n-select q-pa-sm row justify-center cursor-pointer">
    <div
      v-if="$i18n.locale == 'ar'"
      flat
      round
      dense
      class="q-mx-xs text-white"
      @click="doChangeLanguage('en')"
    >
      <img
        src="/flags/en.svg"
        alt=""
        :class="inAuth ? 'language-auth' : 'language'"
      />
    </div>
    <div
      v-else
      flat
      round
      dense
      class="q-mx-xs text-white"
      @click="doChangeLanguage('ar')"
    >
      <img
        src="/flags/ic-lang-ar.png"
        style="border-radius:50%"
        alt=""
        :class="inAuth ? 'language-auth' : 'language'"
      />
    </div>
  </div>
</template>

<script>
import i18n from '@/vueI18n'
// import { Quasar } from 'quasar'
import { defineComponent, ref } from 'vue'

export default defineComponent({
  name: 'app-i18n-flags',
  setup() {
    const ar = ref(localStorage.getItem('language'))
    const value = ref(i18n.locale)
    return {
      ar,
      value
    }
  },
  computed: {
    inAuth() {
      return this.$route.path.includes('/auth')
    }
  },
  methods: {
    doChangeLanguage(locale) {
      this.$i18n.locale = locale
      localStorage.setItem('language', locale)
      const lang = this.$route.params.locale
      let route = ''
      if (lang && (lang === 'ar' || lang === 'en')) {
        route = `/${locale}${this.$route.fullPath.substring(3)}`
      } else {
        route = `/${locale}${this.$route.fullPath}`
      }
      if (locale == 'ar') {
        this.ar = true
        document.body.setAttribute('dir', 'rtl')
      } else {
        this.ar = false
        document.body.setAttribute('dir', 'ltr')
      }
      this.$router.push(route)
    }
  },
  mounted() {
    if (this.value === 'ar') {
      this.ar = true
    } else {
      this.ar = false
    }
  }
})
</script>

<style lang="scss">
.i18n-select {
  border-radius: 4px;
  width: 60px !important;
  text-align: center !important;
}
.language {
  width: 30px;
}
.language-auth {
  width: 50px;
}
</style>

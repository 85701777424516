import Chart from './chart/Main.vue'
import GoogleMapLoader from './google-map-loader/Main.vue'
import Highlight from './highlight/Main.vue'
import Litepicker from './litepicker/Main.vue'
import Tippy from './tippy/Main.vue'
import TippyContent from './tippy-content/Main.vue'
import TomSelect from './tom-select/Main.vue'
import LoadingIcon from './loading-icon/Main.vue'
import EpicSpinners from './epic-spinners/Main.vue'
import TinySlider from './tiny-slider/Main.vue'
import CKEditor from './ckeditor/Main.vue'
import Dropzone from './dropzone/Main.vue'
import FullCalendar from './calendar/Main.vue'
import FullCalendarDraggable from './calendar/Draggable.vue'
import * as featherIcons from '@zhuowenli/vue-feather-icons'
// import VueTelInput from 'vue3-tel-input'
// import 'vue3-tel-input/dist/vue3-tel-input.css'
import VueTelInput from 'vue-tel-input'
import 'vue-tel-input/dist/vue-tel-input.css'
import VueBarcode from '@chenfengyuan/vue-barcode'
// import Popper from 'vue3-popper'
import ErrorNotify from '@/global-components/toastify-notify/error-notify.vue'
import SuccessNotify from '@/global-components/toastify-notify/success-notify.vue'
import SuccessNotifyWithButtons from '@/global-components/toastify-notify/success-notify-with-buttons.vue'

export default app => {
  app.component('Chart', Chart)
  app.component('GoogleMapLoader', GoogleMapLoader)
  app.component('Highlight', Highlight)
  app.component('Litepicker', Litepicker)
  app.component('Tippy', Tippy)
  app.component('TippyContent', TippyContent)
  app.component('TomSelect', TomSelect)
  app.component('LoadingIcon', LoadingIcon)
  app.component('TinySlider', TinySlider)
  app.component('Dropzone', Dropzone)
  app.component('CKEditor', CKEditor)
  app.component('FullCalendar', FullCalendar)
  app.component('FullCalendarDraggable', FullCalendarDraggable)
  app.component('ErrorNotify', ErrorNotify)
  app.component('SuccessNotify', SuccessNotify)
  app.component('SuccessNotifyWithButtons', SuccessNotifyWithButtons)
  app.component(VueBarcode.name, VueBarcode)
  // app.component('Popper', Popper)
  app.component('loading-spinner', EpicSpinners)
  app.use(VueTelInput)

  for (const [key, icon] of Object.entries(featherIcons)) {
    icon.props.size.default = '24'
    app.component(key, icon)
  }
}

import { vuexfireMutations } from 'vuexfire'
import { createStore } from 'vuex'
import main from './main'
import sideMenu from './side-menu'
import simpleMenu from './simple-menu'
import topMenu from './top-menu'
import authStore from '../auth/auth-store'
import usersStore from './users-store'
import dataCenterStore from './data-center-store'
import dataCenterEmployeeStore from './data-center-employee'
import shareStore from './share-store'
import companyStore from './company-store'
import partitionStore from './partition-store'
import departmentStore from './department-store'
import auditLogStore from './auditLog-store'
import notification from './notification-store'
import settings from './settings-store'

const store = createStore({
  modules: {
    main,
    sideMenu,
    simpleMenu,
    topMenu,
    authStore,
    usersStore,
    dataCenterStore,
    dataCenterEmployeeStore,
    shareStore,
    partitionStore,
    companyStore,
    departmentStore,
    auditLogStore,
    notification,
    settings
  },
  mutations: {
    ...vuexfireMutations
  }
})

export function useStore() {
  return store
}

export default store

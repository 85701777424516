<template v-if="modalShow">
  <!-- BEGIN: Modal Content -->
  <div id="remove-access-modal" class="modal" tabindex="-1" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <!-- BEGIN: Modal Header -->
        <div class="modal-header">
          <h2 class="font-medium text-base">
            {{ i18n('common.removeAccess') }}
          </h2>
        </div>
        <!-- END: Modal Header -->
        <!-- BEGIN: Modal Body -->
        <div class="nav nav-tabs flex-col sm:flex-row justify-center" role="tablist">
          <a id="share-tab" data-toggle="tab" data-target="#share" href="javascript:;"
            class="py-4 pl-4 sm:pl-0 sm:mr-8 active" role="tab" aria-controls="share" @click="tab = 'share'"
            aria-selected="true">{{ i18n('common.read') }}</a>
          <a id="download-tab" data-toggle="tab" data-target="#download" href="javascript:;"
            class="py-4 pl-4 sm:pl-0 sm:mr-8" @click="tab = 'download'" role="tab" aria-selected="false">{{
            i18n('common.download') }}</a>
          <a id="print-tab" data-toggle="tab" data-target="#print" href="javascript:;" class="py-4 pl-4 sm:pl-0 sm:mr-8"
            @click="tab = 'print'" role="tab" aria-selected="false">{{ i18n('common.print') }}</a>
        </div>
        <div class="p-5">
          <div v-if="tab == 'share'">
            <div v-for="(user, index) in readAccessUsers" :key="index" class="flex items-center py-1">
              <input class="form-check-input border border-gray-500" type="checkbox" v-model="user.checkbox" />
              <span style="padding: 0 6px;">{{ user.name }}</span>
            </div>
            <div class="flex justify-center items-center pt-5" style="color: #ababab;" v-if="!readAccessUsers.length">{{
            i18n('common.noPermissions') }}</div>
          </div>
          <div v-if="tab == 'download'">
            <div v-for="(user, index) in downloadAccessUsers" :key="index" class="flex items-center py-1">
              <input class="form-check-input border border-gray-500" type="checkbox" v-model="user.checkbox" />
              <span style="padding: 0 6px;">{{ user.name }}</span>
            </div>
            <div class="flex justify-center items-center pt-5" style="color: #ababab;"
              v-if="!downloadAccessUsers.length">{{ i18n('common.noPermissions') }}</div>
          </div>
          <div v-if="tab == 'print'">
            <div v-for="(user, index) in printAccessUsers" :key="index" class="flex items-center py-1">
              <input class="form-check-input border border-gray-500" type="checkbox" v-model="user.checkbox" />
              <span style="padding: 0 6px;">{{ user.name }}</span>
            </div>
            <div class="flex justify-center items-center pt-5" style="color: #ababab;" v-if="!printAccessUsers.length">
              {{ i18n('common.noPermissions') }}</div>
          </div>
        </div>
        <!-- END: Modal Body -->
        <!-- BEGIN: Modal Footer -->
        <div class="modal-footer">
          <button id="submit-btn" type="button" class="btn text-white bg-theme-39 w-20"
            style="background-color: #d5aa0f;" @click="doSave()">
            {{i18n('common.save')}}
          </button>
          <button type="button" data-dismiss="modal" class="btn btn-outline-secondary w-20 ml-1 mr-1">
            {{i18n('common.cancel')}}
          </button>
        </div>
        <!-- END: Modal Footer -->
      </div>
    </div>
    <ErrorNotification :message="i18n(`share.errors.${errorMessage}`)" />
    <SuccessNotification :message="i18n('share.fileSuccess')" />
  </div>
  <!-- END: Modal Content -->
</template>

<script>
import ErrorNotification from '@/components/notifications/error-notification.vue'
import SuccessNotification from '@/components/notifications/success-notification.vue'
import { defineComponent, ref } from 'vue'
import Toastify from 'toastify-js'
import { mapActions, mapGetters } from 'vuex'
// import Treeselect from 'vue3-treeselect'
import 'vue3-treeselect/dist/vue3-treeselect.css'

export default defineComponent({
  components: { ErrorNotification, SuccessNotification },
  props: ['showModal', 'file'],
  // props: {
  //   showModal: {
  //     type: Boolean
  //   },
  //   file: {
  //     type: Object
  //   }
  // },
  data() {
    return {
      modalShow: !!this.showModal,
      tab: 'share',
      checkbox: false,
      errorMessage: '',
      isCloseModal: false,
      readAccessUsers: [],
      downloadAccessUsers: [],
      printAccessUsers: []
    }
  },
  setup(props) {
    const name = ref('')
    const hideModal = () => {
      cash('#remove-access-modal').modal('hide')
      // this.isCloseModal = true
    }
    const errorNotificationToggle = () => {
      Toastify({
        node: cash('#error-notification-content').clone().removeClass('hidden')[0],
        duration: 3000,
        newWindow: true,
        close: true,
        gravity: 'top',
        position: 'right',
        stopOnFocus: true
      }).showToast()
    }
    const successNotificationToggle = () => {
      Toastify({
        node: cash('#success-notification-content').clone().removeClass('hidden')[0],
        duration: 3000,
        newWindow: true,
        close: true,
        gravity: 'top',
        position: 'right',
        stopOnFocus: true
      }).showToast()
    }
    return {
      name,
      hideModal,
      errorNotificationToggle,
      successNotificationToggle
    }
  },
  computed: {
    ...mapGetters({
      users: 'usersStore/rows',
      loading: 'usersStore/loading',
      currentUser: 'authStore/currentUser',
      filesInFirestore: 'shareStore/filesInFirestore'
    }),
    fileData() {
      const FILE = this.file && this.filesInFirestore && this.filesInFirestore.length
        ? this.filesInFirestore.filter(fileVal => fileVal.id == this.file.id)
        : []
      return FILE.length ? FILE[0] : null
    }
  },
  methods: {
    ...mapActions({
      removeAccess: 'shareStore/removeAccess'
    }),
    i18n(key, args) {
      return this.$t(key, args)
    },
    // doReset() {
    //   this.file = null
    // },
    doSave() {
      const removeReadAccess = this.readAccessUsers.filter(user => !user.checkbox)
      const removeDownloadAccess = this.downloadAccessUsers.filter(user => !user.checkbox)
      const removePrintAccess = this.printAccessUsers.filter(user => !user.checkbox)
      this.removeAccess({
        fileId: this.file.id,
        readAccess: removeReadAccess.map(user => user.id),
        downloadAccess: removeDownloadAccess.map(user => user.id),
        printAccess: removePrintAccess.map(user => user.id)
      })
      this.successNotificationToggle()
      this.hideModal()
    }
  },
  watch: {
    fileData(newVal) {
      if (newVal) {
        const readAccessUsersID = newVal.readAccess
        const downloadAccessUsersID = newVal.downloadAccess
        const printAccessUsersID = newVal.printAccess
        this.readAccessUsers = []
        this.printAccessUsers = []
        this.downloadAccessUsers = []
        readAccessUsersID.forEach(userId => {
          const user = this.users.filter(user => user.id == userId)
          if (user && user.length) {
            if (this.currentUser.id != user[0].id) this.readAccessUsers.push({ checkbox: true, ...user[0] })
          }
        })

        printAccessUsersID.forEach(userId => {
          const user = this.users.filter(user => user.id == userId)
          if (user && user.length) {
            if (this.currentUser.id != user[0].id) this.printAccessUsers.push({ checkbox: true, ...user[0] })
          }
        })

        downloadAccessUsersID.forEach(userId => {
          const user = this.users.filter(user => user.id == userId)
          if (user && user.length) {
            if (this.currentUser.id != user[0].id) this.downloadAccessUsers.push({ checkbox: true, ...user[0] })
          }
        })
      }
    }
  }
})
</script>
<style>

</style>

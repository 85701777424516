import { FileService } from '../../src/services/fileService'

import { vuexfireMutations } from 'vuexfire'
import firebase from 'firebase/compat/app'
import 'firebase/compat/firestore'
// import 'firebase/compat/database'
import 'firebase/compat/storage'
import { doc, onSnapshot } from 'firebase/firestore'
// import { collection, query, orderBy, onSnapshot } from 'firebase/firestore'

import store from './index'
import FirebaseRepository from '../../src/shared/firebase/firebase-repository'
import axios from 'axios'
import config from '../config'
const backendUrl = config.backendUrl
const backendUrlFile = config.backendUrlFile

const state = () => {
  return {
    rows: [],
    filesInFirestore: [],
    loading: false,
    loadingStorage: false,
    record: null,
    lastPage: null,
    totalFiles: null,
    destroyLoading: false,
    uploadLoading: false,
    progressUploaderCount: 0,
    totalProgressCount: 0
  }
}
const getters = {
  rows: state => state.rows,
  loading: state => state.loading,
  loadingStorage: state => state.loadingStorage,
  record: state => state.record,
  filesInFirestore: state => state.filesInFirestore,
  lastPage: state => state.lastPage,
  totalFiles: state => state.totalFiles,
  destroyLoading: state => state.destroyLoading,
  uploadLoading: state => state.uploadLoading,
  progressUploaderCount: state => state.progressUploaderCount,
  totalProgressCount: state => state.totalProgressCount
}
const mutations = {
  ...vuexfireMutations,
  SET_LAST_PAGE(state, payload) {
    state.lastPage = payload
  },
  SET_TOTAL_FILES(state, payload) {
    state.totalFiles = payload
  },
  FETCH_STORAGE_STARTED(state) {
    state.loadingStorage = true
  },
  FETCH_STORAGE_SUCCESS(state) {
    state.loadingStorage = false
  },
  FETCH_STORAGE_ERROR(state) {
    state.loadingStorage = false
  },
  FETCH_START(state) {
    state.loading = true
  },
  FETCH_SUCCESS(state) {
    state.loading = false
  },
  FETCH_ERROR(state) {
    state.loading = false
  },
  ADD_ROWS(state, payload) {
    state.rows = payload
  },
  ADD_TO_ROWS(state, payload) {
    state.rows.push(payload)
  },
  DELETE_FROM_ROWS(state, payload) {
    const index = state.rows.findIndex(row => row.id == payload)
    state.rows.splice(index, 1)
    // const index2 = state.filesInFirestore.findIndex(row => row.id == payload)
    // state.filesInFirestore.splice(index2, 1)
  },
  REMOVE_FIRST_ROW(state) {
    state.rows.shift()
    state.filesInFirestore.shift()
  },
  ADD_TO_FIRESTORE(state, payload) {
    state.filesInFirestore.push(payload)
  },
  RESET_ROWS(state) {
    state.rows = []
  },
  RESET_FIRESTORE(state) {
    state.filesInFirestore = []
  },
  ADD_RECORD(state, payload) {
    state.record = payload
  },
  DESTROY_STARTED(state) {
    state.destroyLoading = true
  },
  DESTROY_SUCCESS(state) {
    state.destroyLoading = false
  },
  DESTROY_ERROR(state) {
    state.destroyLoading = false
  },
  UPLOAD_STARTED(state) {
    state.uploadLoading = true
  },
  UPLOAD_SUCCESS(state) {
    state.uploadLoading = false
  },
  UPLOAD_ERROR(state) {
    state.uploadLoading = false
  },
  UPDATE_PROGRESS_UPLOADER_COUNT(state, payload) {
    state.progressUploaderCount = payload.count
    state.totalProgressCount = payload.total
  }
}
const actions = {
  async doFetchSharedFiless({ commit }, currentUser) {
    // debugger
    commit('RESET_ROWS')
    commit('FETCH_START')
    let ref
    if (currentUser.roles == 'Admin') {
      ref = firebase
        .firestore()
        .collection('files')
        .orderBy('createdBy')

        .where('location', '==', 'Share')
        .where('createdBy', '!=', currentUser.id)
    } else {
    }
  },
  async doSearch({ commit }, text) {
    commit('RESET_ROWS')
    commit('FETCH_START')
    let strSearch = text
    let strlength = strSearch.length
    let strFrontCode = strSearch.slice(0, strlength - 1)
    let strEndCode = strSearch.slice(strlength - 1, strSearch.length)
    let startcode = strSearch
    let endcode =
      strFrontCode + String.fromCharCode(strEndCode.charCodeAt(0) + 1)
    try {
      const nameSearchPromise = firebase
        .firestore()
        .collection('files')
        .orderBy('name')
        .where('name', '>=', startcode)
        .where('name', '<', endcode)

      const idSearchPromise = firebase
        .firestore()
        .collection('files')
        .orderBy('barcode')
        .where('barcode', '==', strSearch)

      const [nameSearchResults, idSearchResults] = await Promise.all([
        nameSearchPromise.get(),
        idSearchPromise.get()
      ])
      const nameResults = FirebaseRepository.mapCollection(nameSearchResults)
      const idResults = FirebaseRepository.mapCollection(idSearchResults)
      const combinedResults = [...nameResults, ...idResults]
      const uniqueResults = Array.from(
        new Set(combinedResults.map(result => result.id))
      ).map(id => combinedResults.find(result => result.id === id))
      commit('FETCH_SUCCESS')
      commit('ADD_ROWS', uniqueResults)
    } catch {
      commit('FETCH_SUCCESS')
    }
  },

  async doFetchWithPagination(
    { commit, dispatch },
    { path, pageSize, lastPage }
  ) {
    try {
      commit('RESET_ROWS')
      commit('FETCH_STORAGE_STARTED')
      let result = null
      const PAGE_SIZE = pageSize || 10
      const listRef = firebase.storage().ref(path) // Create a reference under which you want to list
      if (!lastPage) {
        // Fetch the first page of PAGE_SIZE.
        result = await listRef.list({ maxResults: PAGE_SIZE })
      } else if (lastPage.nextPageToken) {
        // Fetch the second page if there are more elements.
        result = await listRef.list({
          maxResults: PAGE_SIZE,
          pageToken: lastPage.nextPageToken
        })
      }

      // if (!result) {
      //   commit('FETCH_STORAGE_SUCCESS')
      //   return
      // }
      commit('SET_LAST_PAGE', result)

      for (let index = 0; index < result.items.length; index++) {
        if (result.items[index].name != 'starterFile') {
          await dispatch('getDownloadUrl', result.items[index])
        }
      }
      for (let index = 0; index < result.prefixes.length; index++) {
        const folder = result.prefixes[index]
        if (folder.name != 'trash') {
          let companyName = null
          if (path.split('/').length <= 2) {
            const refId = folder.name.substr(0, folder.name.indexOf('?:'))
            if (refId) {
              if (path.includes('Data Center/')) {
                const companyId = path
                  .substr(0, path.lastIndexOf('?:'))
                  .replace('Data Center/', '')
                if (companyId) {
                  const department = await FirebaseRepository.findDocument(
                    `company/${companyId}/department`,
                    refId
                  )
                  companyName = department ? department.name : null
                }
              } else {
                const company = await FirebaseRepository.findDocument(
                  'company',
                  refId
                )
                companyName = company ? company.name : null
              }
            }
          }
          commit('ADD_TO_ROWS', {
            name:
              companyName || folder.name.substr(0, folder.name.indexOf('?:')),
            url: folder.fullPath,
            type: 'folder',
            id: folder.name.substr(folder.name.indexOf('?:') + 2)
          })
        }
      }
    } catch (error) {
      console.error(error)
      commit('FETCH_STORAGE_ERROR')
    }
  },
  async doFetch({ commit, dispatch }, { path, lastPage }) {
    // if (!lastPage) {
    commit('RESET_ROWS')
    commit('FETCH_START')
    // }
    var storageRef = firebase.storage().ref(path)
    const result = await storageRef.listAll()
    // let result = null
    // if (!lastPage) {
    //   result = await storageRef.list({ maxResults: 18 })
    // } else if (lastPage.nextPageToken) {
    //   result = await storageRef.list({
    //     maxResults: 18,
    //     pageToken: lastPage.nextPageToken
    //   })
    // } else {
    //   commit('FETCH_SUCCESS')
    //   return
    // }
    // commit('SET_LAST_PAGE', result)

    for (let index = 0; index < result.items.length; index++) {
      if (result.items[index].name != 'starterFile') {
        await dispatch('getDownloadUrl', result.items[index])
      }
    }
    for (let index = 0; index < result.prefixes.length; index++) {
      const folder = result.prefixes[index]
      if (folder.name != 'trash') {
        let folderNaming = null
        const pathLength = path.split('/').length
        if (pathLength <= 3 && !folder.name.startsWith('foldername')) {
          const refId = folder.name.substr(0, folder.name.indexOf('?:'))
          if (refId) {
            if (path.includes('Data Center/')) {
              let partitionId = path
                .substr(0, path.lastIndexOf('?:'))
                .replace('Data Center/', '')
              if (partitionId) {
                if (pathLength == 2) {
                  const company = await FirebaseRepository.findDocument(
                    `partition/${partitionId}/company`,
                    refId
                  )
                  folderNaming = company ? company.name : null
                } else {
                  partitionId = path.split('/')[1].split('?:')[0]
                  const companyId = path.split('/')[2].split('?:')[0]
                  const department = await FirebaseRepository.findDocument(
                    `partition/${partitionId}/company/${companyId}/department`,
                    refId
                  )
                  folderNaming = department ? department.name : null
                }
              }
            } else {
              const partition = await FirebaseRepository.findDocument(
                'partition',
                refId
              )
              folderNaming = partition ? partition.name : null
            }
          }
        }
        const ID = folder.name.substr(folder.name.indexOf('?:') + 2)
        const folderInFirestore = await FirebaseRepository.findDocument(
          'files',
          ID
        )
        const folderName =
          folderNaming ||
          (folderInFirestore && folderInFirestore.name
            ? folderInFirestore.name
            : folder.name.substr(0, folder.name.indexOf('?:')))
        commit('ADD_TO_ROWS', {
          id: ID,
          name: folderName,
          url: folder.fullPath,
          type: 'folder'
        })
      }
    }
    commit('FETCH_SUCCESS')
  },
  async doFetchFirestore({ commit }) {
    commit('RESET_FIRESTORE')
    commit('FETCH_START')
    const snapshot = await firebase
      .firestore()
      .collection('files')
      .orderBy('createdAt', 'asc')
      .where('location', '==', 'DC')
      .get()
    if (snapshot.empty) {
      commit('FETCH_SUCCESS')
      return
    }

    snapshot.forEach(doc => {
      commit('ADD_TO_FIRESTORE', doc.data())
    })
    commit('FETCH_SUCCESS')
  },
  async doFetchFileLength({ commit }, path) {
    var storageRef = firebase.storage().ref(path)
    const result = await storageRef.listAll().length
    commit('SET_TOTAL_FILES', result)
    commit('FETCH_SUCCESS')
  },
  async doFetchFile({ commit }, id) {
    const usersRef = firebase
      .firestore()
      .collection('files')
      .doc(id)
    const snapshot = await usersRef.get()
    if (snapshot.empty) {
      commit('FETCH_SUCCESS')
      return
    } else {
      commit('ADD_RECORD', snapshot.data())
    }
    commit('FETCH_SUCCESS')
  },

  async doAppendNewFileToRows({ commit, dispatch }, file) {
    dispatch('doFetchFirestore')
    const FileType = file.type
    if (FileType.toLowerCase() == 'folder') {
      commit('ADD_TO_ROWS', {
        url: `${file.parentID}/${file.name}`,
        name: file.name.substr(0, file.name.indexOf('?:')),
        type: FileType.toLowerCase(),
        id: file.id
      })
    } else {
      const type = FileService.getFileType(file.name)
      commit('ADD_TO_ROWS', {
        name: file.name,
        url: file.publicUrl,
        type: type,
        id: file.id
      })
    }
  },
  async getDownloadUrl({ commit, dispatch }, imageRef) {
    const type = FileService.getFileType(
      imageRef.name.substr(0, imageRef.name.indexOf('?:'))
    )
    const url = await imageRef.getDownloadURL()
    const ID = imageRef.name.substr(imageRef.name.indexOf('?:') + 2)
    const fileInFirestore = await FirebaseRepository.findDocument('files', ID)
    const fileName =
      fileInFirestore && fileInFirestore.name
        ? fileInFirestore.name
        : imageRef.name.substr(0, imageRef.name.indexOf('?:'))
    commit('ADD_TO_ROWS', {
      id: ID,
      name: fileName,
      url,
      type: type.toLowerCase()
    })
  },
  async createFolder(context, { path, serverPath, destination }) {
    const companyNumber = FileService.getCompanyNumber(path)
    const departmentNumber = FileService.getDepartmentNumber(path)
    const companyId = FileService.getCompanyID(path)
    const departmentId = FileService.getDepartmentID(path)
    const folderId = FileService.getFolderID(path)
    const id = firebase
      .firestore()
      .collection('ids')
      .doc().id
    const storage = firebase
      .storage()
      .ref(
        `${destination}?:${companyNumber}${departmentNumber}${id}/starterFile`
      )

    const file = 'starterFile'
    await storage.put(file)

    context.dispatch('createFile', {
      id: `${companyNumber}${departmentNumber}${id}`,
      companyId: companyId,
      departmentId: departmentId,
      folderId: folderId,
      name: `${destination.substr(
        destination.lastIndexOf('/') + 1
      )}?:${companyNumber}${departmentNumber}${id}`,
      createdAt: firebase.firestore.Timestamp.now(),
      createdBy: store.getters['authStore/currentUser'].id,
      location: 'DC',
      type: 'Folder',
      parentID: path,
      readAccess: [store.getters['authStore/currentUser'].id],
      downloadAccess: [store.getters['authStore/currentUser'].id],
      printAccess: [store.getters['authStore/currentUser'].id],
      shareAccess: [store.getters['authStore/currentUser'].id],
      deleteAccess: [store.getters['authStore/currentUser'].id],
      departmentNumber,
      state: 'published'
    })
  },
  async createFolderWithId(context, { path, serverPath, destination, id }) {
    const companyNumber = FileService.getCompanyNumber(path)
    const departmentNumber = FileService.getDepartmentNumber(path)
    const companyId = FileService.getCompanyID(path)
    const departmentId = FileService.getDepartmentID(path)
    const folderPath = `${destination}?:${companyNumber}${departmentNumber}${id}/starterFile`
    const storage = firebase.storage().ref(folderPath)
    const file = 'starterFile'
    await storage.put(file)

    var parent = ''
    if (path != serverPath) {
      parent = path.substr(path.lastIndexOf('/'))
    }
    // debugger
    const folder = {
      id: `${companyNumber}${departmentNumber}${id}`,
      companyId: companyId,
      departmentId: departmentId,
      name: `${destination.substr(
        destination.lastIndexOf('/') + 1
      )}?:${companyNumber}${departmentNumber}${id}`,
      createdAt: firebase.firestore.Timestamp.now(),
      createdBy: store.getters['authStore/currentUser'].id,
      location: 'DC',
      type: 'Folder',
      parentID: parent,
      readAccess: [store.getters['authStore/currentUser'].id],
      downloadAccess: [store.getters['authStore/currentUser'].id],
      printAccess: [store.getters['authStore/currentUser'].id],
      shareAccess: [store.getters['authStore/currentUser'].id],
      deleteAccess: [store.getters['authStore/currentUser'].id]
    }
    await context.dispatch('createFile', folder)
    return folder
  },
  async doEmptyTrash({ commit }, { files, path }) {
    // commit('FETCH_START')
    const count = files.length
    const data = [...files]
    for (let index = 0; index < count; index++) {
      const file = data[index]
      commit('REMOVE_FIRST_ROW')
      await firebase
        .storage()
        .ref(`${path}/trash/${file.name}?:${file.id}`)
        .delete()
      await firebase
        .firestore()
        .collection('files')
        .doc(file.id)
        .delete()
      await store.dispatch('auditLogStore/log', {
        ref: `files/${file.id}`,
        entityName: 'files',
        entityId: file.id,
        action: 'delete',
        values: file
      })
      // await dispatch('doDeleteFile', { file, path })
    }
    // files.forEach(async (file) => {
    //   await dispatch('doDeleteFile', { file, path })
    // })
    // commit('FETCH_SUCCESS')
  },
  async doDeleteFile({ commit }, { file, path }) {
    try {
      // commit('FETCH_START')
      commit('DELETE_FROM_ROWS', file.id)
      await firebase
        .storage()
        .ref(`${path}/trash/${file.name}?:${file.id}`)
        .delete()
      await firebase
        .firestore()
        .collection('files')
        .doc(file.id)
        .delete()
      await store.dispatch('auditLogStore/log', {
        ref: `files/${file.id}`,
        entityName: 'files',
        entityId: file.id,
        action: 'delete',
        values: file
      })
      // commit('FETCH_SUCCESS')
    } catch (error) {
      console.error(error)
    }
  },
  async doMoveFile({ commit }, { file, path }) {
    // commit('FETCH_START')
    commit('DELETE_FROM_ROWS', file.id)
    if (file.type == 'folder') {
      // const ref = firebase.storage().ref(`${path}/${file.name}?:${file.id}`)
      // ref
      //   .listAll()
      //   .then(dir => {
      //     dir.items.forEach(async fileRef => {
      //       const type = FileService.getFileType(
      //         fileRef.name.substr(0, fileRef.name.indexOf('?:'))
      //       )
      //       const url = await fileRef.getDownloadURL()
      //       const fileToMove = {
      //         name: fileRef.name.substr(0, fileRef.name.indexOf('?:')),
      //         url,
      //         type: type,
      //         id: fileRef.name.substr(fileRef.name.indexOf('?:') + 2)
      //       }
      //       dispatch('doMoveFile', {
      //         file: fileToMove,
      //         path: `${path}/${file.name}?:${file.id}`
      //       })
      //     })
      //     dir.prefixes.forEach(folderRef => {
      //       const fileToMove = {
      //         name: folderRef.name.substr(0, folderRef.name.indexOf('?:')),
      //         type: 'folder',
      //         id: folderRef.name.substr(folderRef.name.indexOf('?:') + 2)
      //       }
      //       dispatch('doMoveFile', { file: fileToMove, name: fileToMove.name })
      //     })
      //   })
      //   .catch(error => console.log(error))
    } else {
      const data = await fetch(file.url)
      const blob = await data.blob()
      const fileRef = firebase
        .storage()
        .ref(`${path}/trash/${file.name}?:${file.id}`)
      await fileRef.put(blob)
      const fileToDelete = firebase
        .storage()
        .ref(`${path}/${file.name}?:${file.id}`)
      await fileToDelete.delete()
      await firebase
        .firestore()
        .collection('files')
        .doc(file.id)
        .update({
          state: 'deleted',
          updatedAt: firebase.firestore.Timestamp.now()
        })
      await store.dispatch('auditLogStore/log', {
        ref: `files/${file.id}`,
        entityName: 'files',
        entityId: file.id,
        action: 'move-to-trash',
        values: file
      })
    }
    // commit('FETCH_SUCCESS')
  },
  async doRestore({ commit }, { file, fileInFirestore }) {
    try {
      if (file && fileInFirestore) {
        // debugger
        commit('FETCH_START')
        const data = await fetch(file.url)
        const blob = await data.blob()
        await firebase
          .storage()
          .ref(`${fileInFirestore.parentID}/${file.name}?:${file.id}`)
          .put(blob)
        const fileToDelete = firebase
          .storage()
          .ref(`${fileInFirestore.parentID}/trash/${file.name}?:${file.id}`)
        await fileToDelete.delete()
        await firebase
          .firestore()
          .collection('files')
          .doc(file.id)
          .update({ state: 'published' })
        await store.dispatch('auditLogStore/log', {
          ref: `files/${file.id}`,
          entityName: 'files',
          entityId: file.id,
          action: 'restore-file',
          values: file
        })
        commit('FETCH_SUCCESS')
      }
    } catch (error) {
      console.error(error)
    }
  },
  createFile(context, payload) {
    context.dispatch('doAppendNewFileToRows', payload)
    firebase
      .firestore()
      .collection('files')
      .doc(payload.id)
      .set(payload)
    store.dispatch('auditLogStore/log', {
      ref: `files/${payload.id}`,
      entityName: 'files',
      entityId: payload.id,
      action: `create-${payload.type}`.toLowerCase(),
      values: payload
    })
  },
  async shareFile({ dispatch }, payload) {
    if (payload.usersIDs) {
      try {
        await firebase
          .firestore()
          .collection('files')
          .doc(payload.fileId)
          .update({
            readAccess: firebase.firestore.FieldValue.arrayUnion(
              ...payload.usersIDs
            )
          })
      } catch (error) {}
      store.dispatch('auditLogStore/log', {
        ref: `files/${payload.fileId}`,
        entityName: 'files',
        entityId: payload.fileId,
        action: 'internal-share-file',
        values: payload
      })
    }
    await dispatch('shareInEmail', payload)
  },
  async shareInEmail(context, payload) {
    const Email = {
      to: payload.users,
      template: {
        name: 'shareFile',
        data: {
          username: store.getters['authStore/currentUser'].name || null,
          emailAddress:
            store.getters['authStore/currentUser'].emailAddress || null,
          fileName: payload.fileName || null
        }
      },
      message: {
        attachments: [
          {
            path: payload.fileUrl || null,
            filename: payload.fileName || null
          }
        ]
      }
    }
    await firebase
      .firestore()
      .collection('email')
      .add(Email)
    store.dispatch('auditLogStore/log', {
      ref: `files/${payload.fileId}`,
      entityName: 'files',
      entityId: payload.fileId || null,
      action: 'external-share-file',
      values: { name: payload.fileName || '', ...payload } || null
    })
  },
  async shareDownloadAccess(context, payload) {
    firebase
      .firestore()
      .collection('files')
      .doc(payload.fileId)
      .update({
        downloadAccess: firebase.firestore.FieldValue.arrayUnion(
          ...payload.usersIDs
        )
      })
    store.dispatch('auditLogStore/log', {
      ref: `files/${payload.fileId}`,
      entityName: 'files',
      entityId: payload.fileId,
      action: 'share-download-access-file',
      values: payload
    })
  },
  async sharePrintAccess(context, payload) {
    firebase
      .firestore()
      .collection('files')
      .doc(payload.fileId)
      .update({
        printAccess: firebase.firestore.FieldValue.arrayUnion(
          ...payload.usersIDs
        )
      })
    store.dispatch('auditLogStore/log', {
      ref: `files/${payload.fileId}`,
      entityName: 'files',
      entityId: payload.fileId,
      action: 'share-print-access-file',
      values: payload
    })
  },
  async doDestroyFolder({ commit }, { id, url }) {
    try {
      commit('DESTROY_STARTED')
      commit('DELETE_FROM_ROWS', id)
      const data = {
        folderId: id,
        folderUrl: url
      }
      const response = await axios.post(`${backendUrl}/delete-folder`, {
        data: JSON.stringify(data)
      })
      commit('DESTROY_SUCCESS')
    } catch (error) {
      commit('DESTROY_ERROR')
    }
  },
  async doDestroyAll({ commit }, { files, baseUrl }) {
    try {
      commit('DESTROY_STARTED')
      // const response = await FileService.deleteAll(files, baseUrl)
      const data = {
        baseUrl: baseUrl,
        files: files
      }
      const response = await axios.post(`${backendUrl}/delete-all`, {
        data: JSON.stringify(data)
      })
      files.forEach(file => {
        commit('DELETE_FROM_ROWS', file.id)
        store.dispatch('auditLogStore/log', {
          ref: `files/${file.id}`,
          entityName: 'files',
          entityId: file.id,
          action: 'delete',
          values: file
        })
      })
      commit('DESTROY_SUCCESS')
    } catch (error) {
      console.log(error)
      commit('DESTROY_ERROR')
    }
  },
  async doRenameFile({ commit }, { id, newName }) {
    firebase
      .firestore()
      .collection('files')
      .doc(id)
      .update({
        name: newName
      })
  },
  async doUploadFiles({ commit, dispatch }, { files, path, location }) {
    try {
      commit('UPLOAD_STARTED')
      const LISTENER_ID = FirebaseRepository.newId()
      await firebase
        .firestore()
        .collection('--sharedInfo--')
        .doc(LISTENER_ID)
        .set({
          id: LISTENER_ID,
          uploadFilesCount: files.length,
          total: files.length
        })

      dispatch('doTurnOnProgressUploader', {
        listenerId: LISTENER_ID,
        path: path
      })
      files.forEach(file => {
        FileService.uploadFile(file, path, location, LISTENER_ID)
      })
      commit('UPLOAD_SUCCESS')
    } catch (error) {
      commit('UPLOAD_ERROR')
    }
  },
  async doCreateFolder({ commit, dispatch }, { folderName, path, location }) {
    try {
      commit('UPLOAD_STARTED')
      await FileService.createFolder(folderName, path, location)
      await dispatch('doFetchFirestore')
      await dispatch('doFetch', { path })
      commit('UPLOAD_SUCCESS')
    } catch (error) {
      commit('UPLOAD_ERROR')
    }
  },
  doTurnOnProgressUploader({ commit, dispatch }, { listenerId, path }) {
    const db = firebase.firestore()
    const unsubscribe = onSnapshot(
      doc(db, '--sharedInfo--', listenerId),
      doc => {
        const progress = doc.data()
        const total = progress && progress.total ? progress.total : 0
        const count =
          progress && progress.uploadFilesCount ? progress.uploadFilesCount : 0
        commit('UPDATE_PROGRESS_UPLOADER_COUNT', { count, total })
        if (count === 0 && total > 0) {
          firebase
            .firestore()
            .collection('--sharedInfo--')
            .doc(listenerId)
            .update({
              total: 0
            })
        }
        if (count == 0 && total == 0) {
          setTimeout(async () => {
            unsubscribe() // Stop listening to changes
            firebase
              .firestore()
              .collection('--sharedInfo--')
              .doc(listenerId)
              .delete()
            await dispatch('doFetchFirestore')
            await dispatch('doFetch', { path })
          }, 500)
        }
      }
    )
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}

// import VueI18n from 'vue-i18n'
// import { createApp } from 'vue'
// import App from './App.vue'
import { createI18n } from 'vue-i18n/index'
// const { t } = useI18n({})
// createApp(App).use(VueI18n)

function loadLocaleMessages() {
  const locales = require.context(
    './locales',
    true,
    /[A-Za-z0-9-_,\s]+\.json$/i
  )
  const messages = {}
  locales.keys().forEach(key => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i)
    if (matched && matched.length > 1) {
      const locale = matched[1]
      messages[locale] = locales(key)
    }
  })
  return messages
}

export default createI18n({
  locale: localStorage.getItem('language') || process.env.VUE_APP_I18N_LOCALE || 'ar',
  fallbackLocale: localStorage.getItem('language') || process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'ar',
  messages: loadLocaleMessages()
})

// export function getLanguageCode() {
//   return t(key, args)
// }

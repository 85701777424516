<template>
  <div>
    <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
      <h2 class="text-lg font-medium ">{{ i18n('departments.title') }}</h2>
    </div>
    <!-- BEGIN: HTML Table Data -->
    <div class="intro-y box p-5 mt-5">
      <div class="flex flex-col sm:flex-row sm:items-end xl:items-start">
        <form
          id="tabulator-html-filter-form"
          class="xl:flex"
          :class="isRTL ? 'sm:ml-auto' : 'sm:mr-auto'"
        >
          <div class="sm:flex items-center sm:mr-4">
            <label class="w-12 flex-none xl:w-auto xl:flex-initial mr-2 ml-2">{{ i18n('departments.filter.field') }}</label>
            <select
              id="tabulator-html-filter-field"
              v-model="filter.field"
              class="form-select w-full sm:w-32 2xl:w-full mt-2 sm:mt-0 sm:w-auto dark:bg-dark-9"
            >
              <option value="name">{{ i18n('departments.filter.name') }}</option>
              <option value="emailAddress">{{ i18n('departments.filter.emailAddress') }}</option>
              <option value="phoneNumber">{{ i18n('departments.filter.phoneNumber') }}</option>
            </select>
          </div>
          <div class="sm:flex items-center sm:mr-4 mt-2 xl:mt-0">
            <label class="w-12 flex-none xl:w-auto xl:flex-initial mr-2 ml-2">{{ i18n('departments.filter.value') }}</label>
            <input
              id="tabulator-html-filter-value"
              v-model="filter.value"
              type="text"
              class="form-control sm:w-40 2xl:w-full mt-2 sm:mt-0 dark:bg-dark-9"
            />
          </div>
          <div class="mt-2 xl:mt-0">
            <button
              id="tabulator-html-filter-go"
              type="button"
              class="btn text-white bg-theme-39 w-full sm:w-16 sm:mr-3"
              style="background-color: #d5aa0f;"
              @click="filterDepartments()"
            >
              {{ i18n('departments.filter.go') }}
            </button>
            <button
              id="tabulator-html-filter-reset"
              type="button"
              class="btn btn-secondary dark:text-black w-full sm:w-24 mt-2 sm:mt-0 sm:ml-1 sm:mr-1"
              @click="fetchDepartments()"
            >
              {{ i18n('departments.filter.reset') }}
            </button>
          </div>
        </form>
        <div class="flex mt-5 sm:mt-0">
          <button
            id="tabulator-print"
            class="btn btn-outline-secondary w-1/2 sm:w-auto"
            @click="goToCreateDepartment()"
          >
            <PlusIcon class="w-4 h-4" />
            {{ i18n('departments.add') }}
          </button>
        </div>
      </div>
      <div class="overflow-x-auto" style="position: relative; min-height: 200px;">
        <loading-spinner type="half-circle" :duration="1000" :size="40" color="#4469A8" :loading="loading || destroyLoading" />
        <table class="table table-report sm:mt-2" v-if="departments.length > 0">
          <thead>
            <tr>
              <th class="border-b-2 dark:border-dark-5 whitespace-nowrap">
                #
              </th>
              <th class="text-center whitespace-nowrap">
                {{ i18n('departments.name') }}
              </th>
              <th class="text-center whitespace-nowrap">
                {{ i18n('departments.emailAddress') }}
              </th>
              <th class="text-center whitespace-nowrap">
                {{ i18n('departments.phoneNumber') }}
              </th>
              <th class="text-center whitespace-nowrap">
                {{ i18n('departments.actions') }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(row, index) in departments" :key="index">
              <td class="">
                <div class="text-gray-600 text-xs whitespace-nowrap mt-0.5">
                  #{{ index + 1 }}
                </div>
              </td>
              <td class="text-center">
                <div class="font-medium whitespace-nowrap">
                  {{ presenter(row, 'name') }}
                </div>
              </td>
              <td class="text-center">
                <div href="" class="font-medium whitespace-nowrap ">
                  {{ presenter(row, 'emailAddress') }}
                </div>
              </td>
              <td class="text-center">
                <div href="" class="font-medium whitespace-nowrap ">
                  {{ presenter(row, 'phoneNumber') }}
                </div>
              </td>
              <td class="table-report__action w-56">
                <div class="flex justify-center items-center  cursor-pointer">
                  <a
                    href="javascript:;"
                    class="text-theme-37 mr-2"
                    @click="editDepartment(row)"
                    ><EditIcon class="w-6 h-6 mr-1"
                  /></a>

                  <a v-if="currentUser.isOwner"
                    id="destroyModal"
                    href="javascript:;"
                    class="text-theme-38 cursor-pointer"
                    data-toggle="modal"
                    data-target="#delete-modal-preview"
                    type="button"
                    @click="selectedDepartment = row"
                  >
                    <TrashIcon class="w-6 h-6 mr-2" />
                  </a>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <div class="col-span-12 items-center flex justify-center" style="height: 50vh;" v-if="departments.length == 0 && !loading">
          <div>
            <div class="flex justify-center">
              <FileTextIcon style="height:125px; width: 125px;" />
            </div>
            <h3 class="font-medium text-3xl mt-5">
              {{ i18n('departments.noDepartments') }}
            </h3>
          </div>
        </div>
      </div>
    </div>
    <!-- <div v-else style="width:100%; height:80vh;" class="flex justify-center">
      <LoadingIcon icon="grid" class="" style="width:100px" />
    </div> -->
    <!-- END: HTML Table Data -->

    <!-- START: Delete Modal -->
    <DeleteModal :showModal="!!selectedDepartment" item="department" @delete="destroyDepartment(selectedDepartment)" />
    <!-- END: Delete Modal -->
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { defineComponent, ref, reactive } from 'vue'
import xlsx from 'xlsx'
// import feather from 'feather-icons'
import moment from 'moment'
import DeleteModal from '@/components/modals/delete-modal.vue'

export default defineComponent({
  components: {
    DeleteModal
  },
  data() {
    return {
      selectedDepartment: null
    }
  },
  computed: {
    ...mapGetters({
      currentUser: 'authStore/currentUser',
      rows: 'departmentStore/rows',
      loading: 'departmentStore/loading',
      destroyLoading: 'departmentStore/destroyLoading'
    }),
    isRTL() {
      return this.$i18n.locale == 'ar'
    },
    language() {
      return this.$i18n.locale
    },
    companyId() {
      return this.$route.params.id
    }
  },
  setup() {
    // const rows = ref([])
    const tableLimit = ref(10)
    const tableRef = ref()
    const tabulator = ref()
    const departments = ref([])
    const filter = reactive({
      field: 'name',
      value: ''
    })
    // Filter function
    const onFilter = () => {
      tabulator.value.setFilter(filter.field, filter.type, filter.value)
    }

    // On reset filter
    const onResetFilter = () => {
      filter.field = 'name'
      filter.value = ''
      onFilter()
    }

    // Export
    const onExportCsv = () => {
      tabulator.value.download('csv', 'data.csv')
    }

    const onExportJson = () => {
      tabulator.value.download('json', 'data.json')
    }

    const onExportXlsx = () => {
      const win = window
      win.XLSX = xlsx
      tabulator.value.download('xlsx', 'data.xlsx', {
        sheetName: 'Products'
      })
    }

    const onExportHtml = () => {
      tabulator.value.download('html', 'data.html', {
        style: true
      })
    }

    // Print
    const onPrint = () => {
      tabulator.value.print()
    }

    return {
      tableLimit,
      tableRef,
      filter,
      departments,
      onFilter,
      onResetFilter,
      onExportCsv,
      onExportJson,
      onExportXlsx,
      onExportHtml,
      onPrint
    }
  },
  methods: {
    ...mapActions({
      getCurrentUser: 'authStore/getCurrentUser',
      saveCurrentUser: 'authStore/saveCurrentUser',
      doFetch: 'departmentStore/doFetch',
      doDestroy: 'departmentStore/doDestroy'
    }),
    i18n(key, args) {
      return this.$t(key, args)
    },
    presenter(row, fieldName) {
      const val = row ? row[fieldName] : null
      // return val ? val.substring(0, val.lastIndexOf('?')) : 'ـــ'
      return val || 'ـــ'
    },
    editDepartment(row) {
      const { id } = row
      const { partitionId, id: companyId } = this.$route.params
      this.$router.push({
        name: 'department/edit/:id',
        params: { partitionId, companyId, id }
      })
    },
    formatDate(date) {
      return moment.unix(date.seconds).format('DD/MM/YYYY')
    },
    filterDepartments() {
      this.departments = this.rows.filter(el => {
        return el[this.filter.field].includes(this.filter.value)
      })
    },
    fetchDepartments() {
      const { partitionId, id: companyId } = this.$route.params
      this.doFetch({ partitionId, companyId })
    },
    goToCreateDepartment() {
      const { partitionId, id } = this.$route.params
      this.$router.push({ name: 'department/add', params: { partitionId, id } })
    },
    async destroyDepartment(department) {
      const { id, prefix } = department
      await this.doDestroy({
        partitionId: this.$route.params.partitionId,
        companyId: this.companyId,
        // companyPrefix: '',
        departmentId: id,
        departmentPrefix: prefix
      })
      this.selectedDepartment = null
      this.fetchDepartments()
    }
  },
  mounted() {
    this.fetchDepartments()
    this.getCurrentUser()
  },
  watch: {
    rows(val) {
      this.departments = val
    }
  }
})
</script>
<style scoped>
.table-report:not(.table-report--bordered):not(.table-report--tabulator) tr {
  border-radius: 0px !important;
  box-shadow: 15px 0px 15px #0000000b;
}

.table-report:not(.table-report--bordered):not(.table-report--tabulator) td {
  border-radius: 0px !important;
  box-shadow: unset !important;
}

.pagination-ar {
  margin-right: unset !important;
  margin-left: auto !important;
}
</style>

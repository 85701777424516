// import Vue from 'vue'
import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import globalComponents from './global-components'
import i18n from './vueI18n'
import utils from './utils'
import './libs'
import 'material-icons/iconfont/material-icons.css'
// SASS Theme
import './assets/sass/app.scss'
import { DocumentEditorContainerPlugin } from '@syncfusion/ej2-vue-documenteditor'
// import { PdfViewerPlugin } from '@syncfusion/ej2-vue-pdfviewer'
import { ButtonPlugin } from '@syncfusion/ej2-vue-buttons'
import { DropDownButtonPlugin } from '@syncfusion/ej2-vue-splitbuttons'
import { registerLicense } from '@syncfusion/ej2-base'
// import VueTippy, { TippyComponent } from 'vue-tippy'
// Add additional themes.
// import 'tippy.js/themes/light.css'
// import 'tippy.js/themes/light-border.css'
// import 'tippy.js/themes/google.css'
// import 'tippy.js/themes/translucent.css'
registerLicense(
  'Mgo+DSMBaFt/QHFqVVhkW1pFdEBBXHxAd1p/VWJYdVt5flBPcDwsT3RfQF9jTH5ad0dgXnpYdHZTQg==;Mgo+DSMBPh8sVXJ0S0d+XE9AcVRDX3xKf0x/TGpQb19xflBPallYVBYiSV9jS3xTc0VqWH1dd3FTQmRbVQ==;ORg4AjUWIQA/Gnt2VVhhQlFaclhJXGFWfVJpTGpQdk5xdV9DaVZUTWY/P1ZhSXxRdkBjUH5cc3NQQGRaVUA=;NjMzNDIxQDMyMzAyZTMxMmUzMFRxeTdTQ1hZVkt5NjJGUFFBSHFmVVQzU2dPU0h0V0NhSG9Sa3hxbC96Y0U9;NjMzNDIyQDMyMzAyZTMxMmUzMFJtZEVYQk9rZnExTlhEeWhDK0lBVjIxVEk5d0pGU2hOemNVR3B4aFhOeVU9;NRAiBiAaIQQuGjN/V0Z+XU9EaFtFVmJLYVB3WmpQdldgdVRMZVVbQX9PIiBoS35RdEVmWXZfcndSQ2ZZVExy;NjMzNDI0QDMyMzAyZTMxMmUzMFlxUUl5WDBlK2dtbHljbWM0Y0RpRHBlc2dmaXZCNWRzeEJqVVVaazB0bEk9;NjMzNDI1QDMyMzAyZTMxMmUzMGhSeWs5UDF2eXF0MHdoVVVIQUNtczBXaHI4Wk5BZFpjTSs5WDF4eWhNTVE9;Mgo+DSMBMAY9C3t2VVhhQlFaclhJXGFWfVJpTGpQdk5xdV9DaVZUTWY/P1ZhSXxRdkBjUH5cc3NQQGZaUEA=;NjMzNDI3QDMyMzAyZTMxMmUzME96RHNxTk5Va21pSE0xTDA4akUzWlVGS0dQYVozTkJjeDB6dzhnNHczK0k9;NjMzNDI4QDMyMzAyZTMxMmUzMGdOVENCNzVVc2VIdFd3R2dlOFdoZmQ3UnB6TVp4cGUyZTdCeUZWc1lXTEU9='
)

const app = createApp(App)
  .use(store)
  .use(router)
  .use(i18n)
app.use(DocumentEditorContainerPlugin)
app.use(ButtonPlugin)
app.use(DropDownButtonPlugin)
// app.use(PdfViewerPlugin)
// app.use(VueTippy)
// app.component('tippy', TippyComponent)

globalComponents(app)
utils(app)

app.mount('#app')

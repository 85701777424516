/* eslint-disable */
import firebase from 'firebase/compat/app'
import 'firebase/compat/storage'
import * as uuid from 'uuid/v4'
import filesize from 'filesize'
import store from '../store/index'
import axios from 'axios'
import config from '@/config'
const backendUrl = config.backendUrlFile

// const webp = require('webp-converter');
// this will grant 755 permission to webp executables
// webp.grant_permission();

function extractExtensionFrom(filename) {
  if (!filename) {
    return null
  }
  const regex = /(?:\.([^.]+))?$/
  return regex.exec(filename)[1]
}
function getCompanyID(path) {
  const company = path.split('/')[1]
  return company.substr(0, company.lastIndexOf('?:'))
}
function getDepartmentID(path) {
  const pathArray = path.split('/')
  if (pathArray.length > 2) {
    const department = pathArray[2]
    return department.substr(0, department.lastIndexOf('?:'))
  } else {
    return null
  }
}

function getCompanyNumber(path) {
  const company = path.split('/')[1]
  return company.substr(company.indexOf('?:') + 2, 2)
}
function getDepartmentNumber(path) {
  const pathArray = path.split('/')
  if (pathArray.length > 2) {
    const department = pathArray[2]
    return department.substr(department.indexOf('?:') + 2, 2)
  } else {
    return '00'
  }
}

function getFolderID(path) {
  const pathArray = path.split('/')
  if (pathArray.length > 1) {
    const lastElement = pathArray[pathArray.length - 1]
    const folderId = lastElement.substr(lastElement.indexOf('?:') + 2, lastElement.length)
    return folderId
  }
  return null
}


export class FileUploader {
  static validate(file, schema) {
    if (!schema) {
      return
    }
    if (schema.image) {
      if (!file.type.startsWith('image')) {
        throw new Error('fileUploader.image')
      }
    }

    if (schema.size && file.size > schema.size) {
      throw new Error('fileUploader.size', filesize(schema.size))
    }

    const extension = extractExtensionFrom(file.name)

    if (schema.formats && !schema.formats.includes(extension)) {
      throw new Error('fileUploader.formats', schema.formats.join('/'))
    }
  }

  static uploadFromRequest(path, request, schema) {
    try {
      FileUploader.validate(request.file, schema)
    } catch (error) {
      request.onError(error)
      return
    }

    const ref = firebase.storage().ref()
    const extension = extractExtensionFrom(request.name)
    const id = uuid()
    const fullPath = `${path}/${id}.${extension}`
    const task = ref.child(fullPath).put(request)

    task.on(
      firebase.storage.TaskEvent.STATE_CHANGED,
      () => { },
      error => {
        console.log(error)
      },
      async () => {
        await task.snapshot.ref
          .getDownloadURL()
          .then(url => {
            return url
          })
          .catch(error => {
            console.log(error)
          })
      }
    )
  }

  static async uploadFilesFromServer(files, path) {
    try {
      files.forEach(file => {
        const formData = new FormData()

        formData.append('destination', path)
        formData.append('file', file)
        axios.post(`${backendUrl}/upload-file`, formData, {
          headers: {
            charset: 'ISO-8859-1',
            'Content-Type': 'multipart/form-data',
            'Access-Control-Allow-Origin': '*'
          }
        })
      });
    } catch (error) {
      console.log(error);
    }
  }

  // eslint-disable-next-line
  static async uploadImage(path, request, printBarcode = false) {
    const promises = []
    const ref = firebase.storage().ref()
    const file = request
    const id = firebase.firestore().collection('ids').doc().id
    // debugger
    const fullPath = `${path}/${file.name}?:${getCompanyNumber(path)}${getDepartmentNumber(path)}${id}`
    const task = ref.child(fullPath)
    const uploadPromise = await task.put(file)
    const publicUrl = await task.getDownloadURL()
    const companyNumber = getCompanyNumber(path)
    const departmentNumber = getDepartmentNumber(path)
    const companyId = getCompanyID(path)
    const departmentId = getDepartmentID(path)
    const folderId = getFolderID(path)

    await store.dispatch('dataCenterStore/createFile', {
      id: `${companyNumber}${departmentNumber}${id}`,
      companyId: companyId,
      departmentId: departmentId,
      folderId: folderId,
      name: file.name,
      location: 'DC',
      type: 'File',
      parentID: path,
      readAccess: [store.getters['authStore/currentUser'].id],
      downloadAccess: [store.getters['authStore/currentUser'].id],
      printAccess: [store.getters['authStore/currentUser'].id],
      shareAccess: [store.getters['authStore/currentUser'].id],
      deleteAccess: [store.getters['authStore/currentUser'].id],
      departmentNumber: getDepartmentNumber(path),
      publicUrl,
      fullPath: fullPath,
      state: 'published',
      barcode: printBarcode ? new Date().getTime().toString() : null,
      createdAt: firebase.firestore.FieldValue.serverTimestamp(),
      createdBy: store.getters['authStore/currentUser'].id
    })
    promises.push(uploadPromise)
    await Promise.all(promises)
  }

  // eslint-disable-next-line
  static async uploadImageToShare(path, request) {
    const promises = []
    const ref = firebase.storage().ref()
    const file = request
    var id = firebase
      .firestore()
      .collection('ids')
      .doc().id
    const fullPath = `${path}/${file.name}?:${id}`
    const task = ref.child(fullPath)
    const uploadPromise = await task.put(file)
    const publicUrl = await task.getDownloadURL()

    await store.dispatch('shareStore/createFile', {
      id: `${id}`,
      name: file.name,
      location: 'Share',
      type: 'File',
      parentID: path,
      readAccess: [store.getters['authStore/currentUser'].id],
      downloadAccess: [store.getters['authStore/currentUser'].id],
      printAccess: [store.getters['authStore/currentUser'].id],
      shareAccess: [store.getters['authStore/currentUser'].id],
      deleteAccess: [store.getters['authStore/currentUser'].id],
      publicUrl,
      fullPath: fullPath,
      state: 'published',
      createdAt: firebase.firestore.Timestamp.now(),
      createdBy: store.getters['authStore/currentUser'].id
    })
    promises.push(uploadPromise)
    await Promise.all(promises)
  }

  // eslint-disable-next-line
  static async uploadProfilePicture(request) {
    const promises = []
    const ref = firebase.storage().ref()
    const file = request
    const fullPath = `user/${store.getters['authStore/currentUser'].id}/${file.name}`
    const task = ref.child(fullPath)
    const uploadPromise = await task.put(file)
    const publicUrl = await task.getDownloadURL()

    promises.push(uploadPromise)
    await Promise.all(promises)
    return publicUrl
  }
}

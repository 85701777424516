const state = () => {
  return {
    menu: [
      {
        icon: 'HardDriveIcon',
        title: 'Data Center',
        pageName: 'data-center',
        permissions: ['Admin', 'Data Center']
      },
      {
        icon: 'HardDriveIcon',
        title: 'Data Center',
        pageName: 'data-center-employee',
        permissions: ['Employee']
      },
      {
        icon: 'ShareIcon',
        title: 'Share',
        pageName: 'share',
        permissions: ['Admin', 'Employee', 'Data Center']
      },
      {
        icon: 'UsersIcon',
        title: 'Users',
        pageName: 'users',
        permissions: ['Admin']
      },
      {
        icon: 'CompanyIcon',
        title: 'Partitions',
        pageName: 'partition',
        permissions: ['Admin', 'Data Center']
      },
      {
        icon: 'ActivityIcon',
        title: 'auditLogs',
        pageName: 'auditLogs',
        permissions: ['Admin']
      },
      {
        icon: 'SettingIcon',
        title: 'settings',
        pageName: 'settings',
        permissions: ['Admin', 'Data Center']
      }
    ]
  }
}
const getters = {
  menu: state => state.menu
}
const mutations = {}
const actions = {}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}

<template v-if="modalShow">
  <!-- BEGIN: Modal Content -->
  <div
    id="share-file-modal-preview"
    class="modal"
    tabindex="-1"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <!-- BEGIN: Modal Header -->
        <div class="modal-header">
          <h2 class="font-medium text-base">
            {{ i18n('share.selectRecipients') }}
            {{ (value, 'value') }}
          </h2>
        </div>
        <!-- END: Modal Header -->
        <!-- BEGIN: Modal Body -->
        <div
          class="nav nav-tabs flex-col sm:flex-row justify-center"
          role="tablist"
        >
          <a
            id="internal-tab"
            data-toggle="tab"
            data-target="#internal"
            href="javascript:;"
            class="py-4 pl-4 sm:pl-0 sm:mr-8 active"
            role="tab"
            aria-controls="internal"
            @click="tab = 'internal'"
            aria-selected="true"
            >{{ i18n('share.tabs.internal') }}</a
          >
          <a
            id="external-tab"
            data-toggle="tab"
            data-target="#external"
            href="javascript:;"
            class="py-4 pl-4 sm:pl-0 sm:mr-8"
            @click="tab = 'external'"
            role="tab"
            aria-selected="false"
            >{{ i18n('share.tabs.external') }}</a
          >
        </div>
        <div class="p-5">
          <div
            id="internal"
            class="tab-pane active"
            role="tabpanel"
            aria-labelledby="internal-tab"
            v-if="tab == 'internal'"
          >
            <!-- @input="updatec" -->
            <treeselect
              ref="treeselect"
              placeholder="Select Users"
              v-model="value"
              :multiple="true"
              :show-count="true"
              :options="formattedUsers"
            />
          </div>
          <div
            id="external"
            v-if="tab == 'external'"
            class="tab-pane active"
            role="tabpanel"
            aria-labelledby="external-tab"
          >
            <label for="regular-form-1" class="form-label">{{
              i18n('share.emailAddress')
            }}</label>
            <input
              id="regular-form-1"
              type="text"
              v-model="externalEmail"
              class="form-control"
            />
          </div>
        </div>
        <!-- END: Modal Body -->
        <!-- BEGIN: Modal Footer -->
        <div class="modal-footer">
          <button
            type="button"
            id="submit-btn"
            class="btn text-white bg-theme-39 w-20"
            style="background-color: #d5aa0f;"
            @click="doSave()"
          >
            {{ i18n('common.share') }}
          </button>
          <button
            type="button"
            data-dismiss="modal"
            class="btn btn-outline-secondary w-20 ml-1 mr-1"
          >
            {{ i18n('common.cancel') }}
          </button>
        </div>
        <!-- END: Modal Footer -->
      </div>
    </div>
    <ErrorNotification :message="i18n(`share.errors.${errorMessage}`)" />
    <SuccessNotification :message="i18n('share.fileSuccess')" />
  </div>
  <!-- END: Modal Content -->
</template>

<script>
import ErrorNotification from '@/components/notifications/error-notification.vue'
import SuccessNotification from '@/components/notifications/success-notification.vue'
import { defineComponent, ref } from 'vue'
import Toastify from 'toastify-js'
import { mapActions, mapGetters } from 'vuex'
import Treeselect from 'vue3-treeselect'
import 'vue3-treeselect/dist/vue3-treeselect.css'

export default defineComponent({
  components: { Treeselect, ErrorNotification, SuccessNotification },
  props: ['showModal', 'file', 'selectedValues'],
  data() {
    return {
      modalShow: this.showModal,
      tab: 'internal',
      value: [],
      emails: [],
      externalEmail: '',
      errorMessage: ''
    }
  },
  setup(props) {
    const name = ref('')
    const hideModal = () => {
      cash('#share-file-modal-preview').modal('hide')
    }
    const errorNotificationToggle = () => {
      Toastify({
        node: cash('#error-notification-content')
          .clone()
          .removeClass('hidden')[0],
        duration: 3000,
        newWindow: true,
        close: true,
        gravity: 'top',
        position: 'right',
        stopOnFocus: true
      }).showToast()
    }
    const successNotificationToggle = () => {
      Toastify({
        node: cash('#success-notification-content')
          .clone()
          .removeClass('hidden')[0],
        duration: 3000,
        newWindow: true,
        close: true,
        gravity: 'top',
        position: 'right',
        stopOnFocus: true
      }).showToast()
    }
    return {
      name,
      hideModal,
      errorNotificationToggle,
      successNotificationToggle
    }
  },
  computed: {
    ...mapGetters({
      users: 'usersStore/rows',
      loading: 'usersStore/loading',
      currentUser: 'authStore/currentUser',
      filesInFirestore: 'shareStore/filesInFirestore'
    }),
    fileData() {
      const FILE =
        this.file && this.filesInFirestore && this.filesInFirestore.length
          ? this.filesInFirestore.filter(fileVal => fileVal.id == this.file.id)
          : []
      return FILE.length ? FILE[0] : null
    },
    formattedUsers() {
      const arr = []
      if (!this.loading && this.fileData) {
        const usersAccess = this.fileData.readAccess || []
        for (let index = 0; index < this.users.length; index++) {
          const user = this.users[index]
          if (user.company && !usersAccess.includes(user.id)) {
            if (arr.filter(e => e.label == user.company.name).length == 0) {
              arr.push({
                id: 'company:' + user.company.name,
                label: user.company.name,
                children: [
                  {
                    id: 'department:' + user.department.name,
                    label: user.department.name,
                    children: [
                      {
                        label: user.name,
                        id: user.emailAddress
                      }
                    ]
                  }
                ]
              })
            } else {
              var arrayElement = arr.filter(
                e => e.label == user.company.name
              )[0]
              if (
                arrayElement.children.filter(
                  e => e.label == user.department.name
                ).length == 0
              ) {
                arrayElement.children.push({
                  id: 'department:' + user.department.name,
                  label: user.department.name,
                  children: [
                    {
                      label: user.name,
                      id: user.emailAddress
                    }
                  ]
                })
              } else {
                arrayElement.children
                  .filter(el => el.label == user.department.name)[0]
                  .children.push({
                    id: user.emailAddress,
                    label: user.name
                  })
              }
            }
          }
        }
      }

      return arr
    }
  },
  methods: {
    ...mapActions({
      shareFile: 'shareStore/shareFile',
      shareInEmail: 'shareStore/shareFile'
    }),
    i18n(key, args) {
      return this.$t(key, args)
    },
    doSave() {
      if (this.tab == 'internal') {
        if (this.value.length > 0) {
          const formattedEmails = this.formatEmails(this.value)
          const usersIDs = this.formatIDs(formattedEmails)
          this.shareFile({
            users: formattedEmails,
            name: this.file.name,
            fileUrl: this.file.url,
            fileId: this.file.id,
            usersIDs
          })
          this.successNotificationToggle()
          this.hideModal()
        } else {
          this.errorMessage = 'selectRecipients'
          setTimeout(() => {
            this.errorNotificationToggle()
          }, 100)
        }
      } else {
        if (this.externalEmail != '') {
          this.shareFile({
            users: [this.externalEmail],
            name: this.file.name,
            fileUrl: this.file.url
          })
          this.successNotificationToggle()
          this.hideModal()
        } else {
          this.errorMessage = 'enterEmail'
          setTimeout(() => {
            this.errorNotificationToggle()
          }, 100)
        }
      }
    },
    formatEmails(value) {
      const emails = []
      value.forEach(valueElement => {
        if (valueElement.startsWith('company:')) {
          const companyName = valueElement.substr(valueElement.indexOf(':') + 1)

          const users = this.users.filter(el => {
            return el.company?.filter(comp => comp.name == companyName).length
          })
          users.forEach(element => {
            emails.push(element.emailAddress)
          })
        } else if (valueElement.startsWith('department:')) {
          const departmentName = valueElement.substr(
            valueElement.indexOf(':') + 1
          )
          const users = this.users.filter(el => {
            return el.department?.filter(dep => dep.name == departmentName)
              .length
          })
          users.forEach(element => {
            emails.push(element.emailAddress)
          })
        } else {
          emails.push(valueElement)
        }
      })
      return emails
    },
    formatIDs(value) {
      const ids = []
      value.forEach(valueElement => {
        this.users.filter(el => {
          if (el.emailAddress == valueElement) {
            if (!ids.includes(el.id)) {
              ids.push(el.id)
            }
          }
        })
      })
      return ids
    }
  }
})
</script>

<template v-if="showModal">
  <!-- BEGIN: Modal Content -->
  <div
    id="print-barcode-loading-modal"
    class="modal"
    tabindex="-1"
    aria-hidden="true"
    data-backdrop="static"
  >
    <div class="modal-dialog modal-xl">
      <div class="modal-content" style="padding:50px">
        <div class="flex justify-center">
          <div>
            <lottie-player
              src="https://assets5.lottiefiles.com/packages/lf20_etd7cnw5.json"
              speed="1"
              loop
              autoplay
              background="transparent"
              class="lottie-2"
            ></lottie-player>
            <p style="font-size: 1.125rem; color: #a5a5a5;">{{ i18n('common.pleaseWaitPrint') }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- END: Modal Content -->
</template>

<script>
/* eslint-disable */
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    showModal: {
      type: Boolean
    }
  },

  setup(props) {
    const hideModal = () => {
      cash('#print-barcode-loading-modal').modal('hide')
    }

    return {
      hideModal
    }
  },
  methods: {
    i18n(key, args) {
      return this.$t(key, args)
    }
  }
})
</script>

<style>
.lottie-2 {
  width: 100% !important;
  height: 200px !important;
  margin-bottom: 1.5rem !important;
}
</style>

<template>
  <div>
    <router-view :key="$route.path"/>
    <ErrorNotify message="--text--"></ErrorNotify>
    <SuccessNotify message="--text--"></SuccessNotify>
  </div>
</template>
<script></script>
<script>
import { defineComponent, onMounted, ref } from 'vue'
import { mapActions } from 'vuex'
import { useStore } from '@/store'

export default defineComponent({
  async created() {
    document.addEventListener('keyup', e => {
      if (e.key == 'F13') {
        navigator.clipboard.writeText('')
        alert('Screenshots disabled!')
      }
    })
    const promises = [
      new Promise(() => this.doInit())
    ]
    await Promise.all(promises)
  },
  computed: {
    language() {
      return this.$i18n.locale
    }
  },
  methods: {
    ...mapActions({
      doInitFirebase: 'authStore/doInitFirebase',
      doInit: 'authStore/doInit',
    })
  },
  setup() {
    const store = useStore()
    const anonymousToken = ref('')
    onMounted(() => {
      if (localStorage.getItem('language') != 'en') {
        document.body.setAttribute('dir', 'rtl')
      }
    })
    return {
      store,
      anonymousToken
    }
  }
})
</script>

<style>
/* @import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&family=Tajawal:wght@200;300;400;500;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Tajawal:wght@200;300;400;500;700;800;900&display=swap'); */

html body[dir="ltr"] {
  font-family: 'Tajawal', sans-serif !important;
}
html body[dir="rtl"] {
  font-family: 'Tajawal', sans-serif !important;
}
</style>

<style>
/* eslint-disable */
@import '../node_modules/@syncfusion/ej2-base/styles/material.css';
@import '../node_modules/@syncfusion/ej2-buttons/styles/material.css';
@import '../node_modules/@syncfusion/ej2-inputs/styles/material.css';
@import '../node_modules/@syncfusion/ej2-popups/styles/material.css';
@import '../node_modules/@syncfusion/ej2-lists/styles/material.css';
@import '../node_modules/@syncfusion/ej2-navigations/styles/material.css';
@import '../node_modules/@syncfusion/ej2-splitbuttons/styles/material.css';
@import '../node_modules/@syncfusion/ej2-dropdowns/styles/material.css';
@import '../node_modules/@syncfusion/ej2-vue-documenteditor/styles/material.css';

@import '../node_modules/@syncfusion/ej2-grids/styles/material.css';
@import '../node_modules/@syncfusion/ej2-vue-spreadsheet/styles/material.css';
@import '../node_modules/@syncfusion/ej2-vue-pdfviewer/styles/material.css';
.cursor-pointer {
  cursor: pointer !important;
}
</style>
